/* eslint-disable no-shadow */
import React from 'react';
import {
  TableRow,
  TableCell,
  IconButton,
} from '@material-ui/core';
import { KeyboardArrowUp, KeyboardArrowDown, Check, Clear, AccountBoxRounded } from '@material-ui/icons';
import useCustomSnackbar from 'hooks/useCustomSnackbar';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import Chip from 'components/UI/Chip';
import getTimeElapsed from 'utils/getTimeElapsed';
import DevConsole from 'utils/DevConsole';
import calcPercent from 'utils/calcPercent';
import PropTypes from 'prop-types';
import CollapsibleTable from '../CollapsibleTable';
import useStyles from './styles';

const dev = new DevConsole('Row');
dev.mute();
/**
 * Row component. This is a child of the GuestTable component for handling all guest related data.
 *
 * @param {object} props
 * @param {object} props.data - any row data this component needs to render
 * @returns {React.Component}
 */
const Row = React.forwardRef((props, ref) => {
  dev.log('Row Rendered');
  const { data } = props;
  const [showSnackbar] = useCustomSnackbar({
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
  });
  const { answers, playThroughs, score, progress, dateUpdated, dateCreated } = data;
  const [open, setOpen] = React.useState(false);
  const [guestId, setGuestId] = React.useState('');
  const classes = useStyles();

  // TODO Have this make a request to the API and re-render the dashboard with this Users Stats.
  const onClick = () => {
    setGuestId(data.guestId);
    return showSnackbar(`Request made with: ${data.guestId}`);
  };

  const renderStatus = (status) => {
    if (status) {
      return (
        <TableCell className={classes.status}>
          <Chip className={classes.active} text="Completed" icon={<Check />} size="medium" />
        </TableCell>
      );
    }
    if (!status) {
      return (
        <TableCell className={classes.status}>
          <Chip className={classes.draft} text="Incomplete" icon={<Clear style={{ border: 'none' }} />} size="medium" />
        </TableCell>
      );
    }
    return status;
  };

  dev.log(guestId);
  return (
    <>
      <TableRow ref={ref} key={data.guestId}>
        <TableCell>
          <div>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </div>
        </TableCell>
        <TableCell>
          <div>{data.accessKey === 'N/A' ? 'gsi.learnbyloci.com' : `gsi.learnbyloci.com/${data.accessKey}`}</div>
        </TableCell>
        <TableCell>
          <div>{data.grade}</div>
        </TableCell>
        <TableCell>
          <div>
            {data.score}
            /18
          </div>
        </TableCell>
        <TableCell>
          {data.locationKey}
        </TableCell>
        <TableCell>
          <div>{data.course}</div>
        </TableCell>
        <TableCell>
          <div>{moment(data.dateCreated).format('MMMM Do YYYY, h:mma')}</div>
        </TableCell>
        <TableCell>
          <div>{calcPercent(score, progress)}</div>
        </TableCell>
        <TableCell>
          <div>{data.guestId}</div>
        </TableCell>
        <TableCell>
          <div>
            {getTimeElapsed(dateUpdated, dateCreated, 'minutes')}
            m
            {' '}
            {getTimeElapsed(dateUpdated, dateCreated, 'seconds')}
            s
          </div>
        </TableCell>
        <TableCell>
          <div>
            <IconButton
              onClick={onClick}
            >
              <AccountBoxRounded color="primary" />
            </IconButton>
          </div>
        </TableCell>
        <TableCell>
          <div>
            {data.playThroughs !== undefined ? data.playThroughs.length : 0}
          </div>
        </TableCell>
        {renderStatus(data.completed)}
      </TableRow>
      <CollapsibleTable
        key={uuid()}
        answers={answers}
        playThroughs={playThroughs}
        open={open}
        date={dateCreated}
        score={score}
        progress={progress}
      />
    </>
  );
});

Row.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Row;
