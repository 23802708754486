import React, { useEffect, useMemo } from 'react';
import Recoil from 'recoil';
import {
  Grid,
  MenuItem,
} from '@material-ui/core';
import {
  Text as T,
  SelectField,
  Switch,
  Dialog,
} from 'components/UI';
import useAuthentication from 'hooks/useAuthentication';
import useCustomSnackbar from 'hooks/useCustomSnackbar';
import { localization } from 'API/services';
import DevConsole from 'utils/DevConsole';
import products from 'config/products';
import {
  dialogVisibilityState,
  dialogContentState,
  productIdState,
  twoLineEditState,
  filtersState,
  groupsListState,
  translationsListState,
  languageState,
} from 'store/atoms';
import TranslationContent from './TranslationContent';
import useStyles from './styles';


const dev = new DevConsole('Translations');


/**
 * Translations Page
 *
 * This page is where we edit all translations in the LOCI database.
 * Because we deal with all product translations, not just My LOCI's, we hit the DB directly.
 *
 * @returns {React.Component}
 */
function Translations() {
  const classes = useStyles();
  const [showSnackbar, closeSnackbar] = useCustomSnackbar();
  const languages = Recoil.useRecoilValue(languageState);
  const [dialogVisibility, setDialogVisibility] = Recoil.useRecoilState(dialogVisibilityState);
  const dialogContent = Recoil.useRecoilValue(dialogContentState);
  const [productId, setProductId] = Recoil.useRecoilState(productIdState);
  const setGroupsList = Recoil.useSetRecoilState(groupsListState);
  const setTranslationsList = Recoil.useSetRecoilState(translationsListState);
  const setTwoLineEdit = Recoil.useSetRecoilState(twoLineEditState);
  const [filters, setFilters] = Recoil.useRecoilState(filtersState);
  const authentication = useAuthentication({
    unauthenticatedRedirect: 'SignIn',
    egressRedirect: 'Home',
    group: 'Translators',
  });


  // Render vars:
  const productItems = products.map((p, key) => {
    return <MenuItem value={key} key={key}>{p.name}</MenuItem>;
  });

  const selectProduct = (value) => {
    setProductId(products[value] ? products[value].id : null);
  };

  const filterReducer = (key, value) => {
    if (key === 'lang_en') return;
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  // set default Filters on load:
  useEffect(() => {
    const defaultON = ['en'];
    const newFilters = {};
    languages.forEach(l => {
      newFilters[`lang_${l.code}`] = defaultON.includes(l.code);
    });
    setFilters(newFilters);
  }, []);

  // Fetch data from DB when selected product changes.
  useEffect(() => {
    if (productId === null) return;

    showSnackbar('Fetching translations...', { variant: 'info' });

    (async () => {
      const [fetchedGroups, fetchedTranslations] = await Promise.all([
        localization.group.list({ productId }),
        localization.translation.list({ productId }),
      ]);

      if (fetchedGroups.success) {
        setGroupsList(fetchedGroups.items);
      } else {
        showSnackbar('Error loading translation groups.', { variant: 'error' });
        setProductId(null);
      }

      if (fetchedTranslations.success) {
        setTranslationsList(fetchedTranslations.items);
      } else {
        showSnackbar('Error loading translation.', { variant: 'error' });
      }

      if (fetchedGroups.success && fetchedTranslations.success) {
        closeSnackbar();
        showSnackbar(`Loaded ${fetchedGroups.items.length} translations for  ${fetchedTranslations.items.length} scenes.`,
          { key: 'loaded', variant: 'success' });
      }
    })();
  }, [productId]);


  const filterFields = useMemo(() =>
    languages.map((l) => (
      <Grid item xs={2} key={l.code}>
        <Switch
          label={l.keyword}
          checked={filters[`lang_${l.code}`]}
          onChange={e => filterReducer(`lang_${l.code}`, e.target.checked)}
        />
      </Grid>
    )), [filters]);

  dev.log('render');

  return authentication.restrictAccess(
    <div className={classes.root}>
      <T v="h3" g>Translations Editor</T>

      <Grid container spacing={2} alignItems="center" className={classes.productSelect}>
        <Grid item xs={4}>
          <SelectField
            outlined
            fullWidth
            name="productId"
            label="Product"
            handler={selectProduct}
          >
            {productItems}
          </SelectField>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={7}>
          <T>
            Two languages per line
            <Switch
              onChange={e => setTwoLineEdit(e.target.checked)}
            />
          </T>
        </Grid>
        <Grid container item xs={12}>
          {filterFields}
        </Grid>
        <Grid item xs={12}>
          { productId === null
            ? <T p>Pick a product to display its translations.</T>
            : <TranslationContent />}
        </Grid>
      </Grid>
      <Dialog
        fullWidth
        open={dialogVisibility}
        title={dialogContent.title}
        extrabuttons={dialogContent.buttons}
        handler={setDialogVisibility}
      >
        {dialogContent.body}
      </Dialog>
    </div>,
  );
}

export default Translations;
