import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import logo2x from 'assets/images/logo-notag-black-2x.png';
import { Text, TextField, Button, Show, Hide } from 'components/UI';
import { MissingInformation, EmailEmpty, CodeMismatchException } from 'utils/errors';
import { LocalizationContext } from 'contexts/LocalizationProvider';
import DevConsole from 'utils/DevConsole';
import useCustomSnackbar from 'hooks/useCustomSnackbar';
import useAuthentication from 'hooks/useAuthentication';
import useStyles from './styles';

const dev = new DevConsole('Recovery');

/**
 * Account Recovery page
 *
 * @returns {React.component}
 */
const Recovery = () => {
  const classes = useStyles();
  const history = useHistory();
  const authentication = useAuthentication();
  const [email, setEmail] = React.useState('');
  const [code, setCode] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showPassword, togglePassword] = React.useReducer((state) => !state, false);
  const [codeSent, setCodeSent] = React.useState(false);
  const [showSnackbar, closeSnackbar] = useCustomSnackbar({
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
  });

  const {
    languages,
    translations,
  } = React.useContext(LocalizationContext);
  // TODO: Use Cookies for selected language.
  const language = languages[0].code;

  const onHandleRecover = async (event) => {
    event.preventDefault();
    const result = await authentication.sendRecoveryEmail(email);
    dev.log(result);
    if (result.error === MissingInformation || result.error === EmailEmpty) {
      showSnackbar(translations.signup.emailempty[language], { variant: 'warning' });
      return;
    }
    if (result.success) {
      showSnackbar('Recovery email sent!', { variant: 'success' });
      setCodeSent(true);
    }
  };

  const onHandleReset = async (event) => {
    event.preventDefault();
    const result = await authentication.resetPassword(code, password);
    dev.log(result);

    if (result.error === CodeMismatchException) {
      showSnackbar('Invalid code.', { variant: 'error' });
    }

    if (result.success) {
      closeSnackbar();
      history.push('/signin');
    }
  };

  const helperText = (text) => (
    <span className={classes.formLabel}>
      {translations.signin[text][language]}
    </span>
  );

  const inputAdornment = useMemo(() => { // Hide and show icons for password field
    return (
      <InputAdornment position="end">
        <IconButton color="primary" onClick={togglePassword}>
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    );
  }, [showPassword]);

  return (
    <div>
      <Card className={classes.card}>
        <CardContent className={classes.flexContent}>

          <Grid container alignContent="flex-start" spacing={2}>
            <div className={classes.flexLogo}>
              <Grid item xs={12} md={3}>
                <img className={classes.logo} src={logo2x} alt="LOCI" />
              </Grid>
              <Grid item xs={false} md={1} />
              <Grid item xs={12} md={8}>
                <Text>{translations.signin.tagline[language]}</Text>
              </Grid>
            </div>

            <Hide if={codeSent}>
              <form id="Recover" onSubmit={onHandleRecover}>
                <Grid item xs={12}>
                  <Text>Enter account email to recover.</Text>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    type="text"
                    helperText={helperText('email')}
                    handler={setEmail}
                  />
                </Grid>
              </form>
            </Hide>

            <Show if={codeSent}>
              <form id="Reset" onSubmit={onHandleReset}>
                <Grid item xs={12}>
                  <Text>Enter the code you received, along with your new password.</Text>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="code"
                    helperText={helperText('code')}
                    handler={setCode}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="password"
                    helperText={helperText('password')}
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="new-password"
                    handler={setPassword}
                    InputProps={{ endAdornment: inputAdornment }}
                  />
                </Grid>
              </form>
            </Show>
          </Grid>
        </CardContent>

        <CardActions className={classes.flexActions}>
          <Hide if={codeSent}>
            {/* TODO: Use routes */}
            <Button variant="text" onClick={() => history.push('/signin')}>
              Return
            </Button>
            <Button type="submit" variant="contained" onClick={onHandleRecover}>
              SEND ME MY RECOVERY CODE
            </Button>
          </Hide>
          <Show if={codeSent}>
            <Button variant="text" onClick={() => setCodeSent(false)}>
              RESEND ANOTHER CODE
            </Button>
            <Button type="submit" variant="contained" onClick={onHandleReset}>
              RESET PASSWORD
            </Button>
          </Show>
        </CardActions>

      </Card>
    </div>
  );
};

export default Recovery;
