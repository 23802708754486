/* eslint-disable react/prop-types */
/* eslint-disable no-plusplus */
import React, { useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import API from 'API';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import Recoil from 'recoil';
import { organizationListState } from 'store/atoms';
import {
  Text,
  TextField,
} from 'components/UI';
import { Grid } from '@material-ui/core/';
import { organization } from 'API/services';
import useDebounce from 'hooks/useDebounce';
import useCustomSnackbar from 'hooks/useCustomSnackbar';
import DevConsole from 'utils/DevConsole';

import locationPin from 'assets/images/icons/icon-metro-location.png';
import uploadIcon from 'assets/images/icons/icon-metro-file-upload.png';

import useStyles from './styles';

const dev = new DevConsole('OrganizationForm').mute();
const api = new API();

const OrganizationForm = ({ setAddOrganization }) => {
  const classes = useStyles();
  const [showSnackbar] = useCustomSnackbar();
  const history = useHistory();

  const uploadLogoRef = useRef();
  const formFieldRef = useRef([]);
  const [isCreated, setIsCreated] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [uploadedLogo, setUploadedLogo] = useState({});
  const setOrganizationList = Recoil.useSetRecoilState(organizationListState);

  /**
   * createOrganization
   * Creates a new organization on the database
   */
  const [createOrganization] = useDebounce(async (inputs, addrObj) => {
    try {
      const result = await organization.create({
        body: {
          organizationName: inputs.organizationName,
          address: addrObj,
          lociManager: inputs.lociManager,
          safetyManager: inputs.safetyManager,
          logo: uploadedLogo,
        },
      });
      dev.log(`create result: ${JSON.stringify(result)}`);
      if (result.success) {
        setIsCreated(true);
        showSnackbar('Organization is created successfully!', { preventDuplicate: true, variant: 'success' });

        // append the newly created Organization to the Organization List
        setOrganizationList(prevState => [...prevState, result.item]);

        // redirect to the Organizations List page once created after 0.3ms
        setTimeout(() => {
          history.replace('/organization-list');
        }, 300);
      }
    } catch (e) {
      dev.error(e.response);
    }
  }, 500);

  const handleClick = () => {
    uploadLogoRef.current.click();
  };

  const handleUpload = async (e) => {
    dev.log('handleUpload e', e);
    dev.log('uploadLogoRef: ', uploadLogoRef);
    const file = uploadLogoRef.current.files[0];
    const originalFileName = file.name;
    const fileType = file.type;
    const uniqueFileName = uuidv4(); // we use this to avoid issues with same originalFileName
    const folder = 'logo';
    try {
      const image = await api.storage.put(`${folder}/${uniqueFileName}`, file, {
        level: 'public',
        contentType: fileType,
      });
      dev.log('image', image);

      if (!_.isEmpty(image)) {
        setIsUploaded(true);
        setUploadedLogo({
          folder,
          originalFileName,
          fileType,
          uniqueFileName,
        });
      }
    } catch (error) {
      dev.log('Error uploading file: ', error);
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    dev.log('creating...');
    const inputs = [];
    const addrObj = {};
    for (let i = 0; i < formFieldRef.current.length; i++) {
      dev.log('current', formFieldRef.current[i]);
      if (formFieldRef.current[i] && formFieldRef.current[i].id) {
        if (formFieldRef.current[i].id.includes('addr_')) {
          const addrKey = formFieldRef.current[i].id.split('addr_');
          addrObj[addrKey[1]] = formFieldRef.current[i].value;
        } else {
          inputs[formFieldRef.current[i].id] = formFieldRef.current[i].value;
        }
      }
    }
    dev.log('inputs', inputs);
    dev.log('addrObj', addrObj);
    createOrganization(inputs, addrObj);
  };

  return (
    <>
      {!isCreated && (
        <Grid
          key="addOrg"
          item
          sm={3}
        >
          <div className={classes.organizationBorderForm}>
            <div className={classes.organizationWrapper}>
              <div className={classes.organizationContainer}>
                <div className={classes.organizationHeaderForm}>
                  {!isUploaded && (
                    <div>
                      <input
                        ref={uploadLogoRef}
                        accept="image/*"
                        type="file"
                        onChange={handleUpload}
                        className={classes.hiddenClass}
                      />
                      <div
                        className={classes.uploadButton}
                        aria-hidden="true"
                        onClick={handleClick}
                      >
                        <img src={uploadIcon} alt="Upload" />
                        <Text>UPLOAD LOGO</Text>
                      </div>
                    </div>
                  )}
                  {isUploaded && (
                    <div className={classes.uploadFileName}>
                      <Text>{uploadedLogo.originalFileName}</Text>
                    </div>
                  )}
                  <TextField
                    inputRef={ref => formFieldRef.current.push(ref)}
                    name="organizationName"
                    placeholder="Name of Organization"
                    variant="outlined"
                  />
                </div>
                <div className={classes.locationsForm}>
                  <div className={classes.locationHeaderForm}>
                    <img src={locationPin} alt="Location Pin" />
                    <Text>0 Locations</Text>
                  </div>
                  <div className={classes.locationAddressForm}>
                    <TextField
                      inputRef={ref => formFieldRef.current.push(ref)}
                      name="addr_streetAddress"
                      placeholder="Enter Street Address"
                      variant="outlined"
                      className="streetAddress"
                    />
                    <TextField
                      inputRef={ref => formFieldRef.current.push(ref)}
                      name="addr_city"
                      placeholder="Enter City"
                      variant="outlined"
                      className="city"
                    />
                    <TextField
                      inputRef={ref => formFieldRef.current.push(ref)}
                      name="addr_state"
                      placeholder="Enter State"
                      variant="outlined"
                      className="state"
                    />
                    <TextField
                      inputRef={ref => formFieldRef.current.push(ref)}
                      name="addr_zip"
                      placeholder="Enter Zip Code"
                      variant="outlined"
                      className="zip"
                    />
                    <TextField
                      inputRef={ref => formFieldRef.current.push(ref)}
                      name="addr_country"
                      placeholder="Enter Country"
                      variant="outlined"
                      className="country"
                    />
                  </div>
                </div>
                <div className={classes.lociManagersForm}>
                  <Text className={classes.bold}>LOCI Manager</Text>
                  <TextField
                    inputRef={ref => formFieldRef.current.push(ref)}
                    name="lociManager"
                    placeholder="Enter full name"
                    variant="outlined"
                  />
                </div>
                <div className={classes.safetyManagersForm}>
                  <Text className={classes.bold}>Safety Manager</Text>
                  <TextField
                    inputRef={ref => formFieldRef.current.push(ref)}
                    name="safetyManager"
                    placeholder="Enter full name"
                    variant="outlined"
                  />
                </div>
                <div className={classes.buttonContainerForm}>
                  <div
                    className={classes.formButton}
                    onClick={() => setAddOrganization(false)}
                    aria-hidden="true"
                  >
                    <Text>CANCEL</Text>
                  </div>
                  <div
                    className={classes.formButton}
                    onClick={handleSave}
                    aria-hidden="true"
                  >
                    <Text>SAVE</Text>
                  </div>
                  <div className={classes.clearFloat} />
                </div>
              </div>
            </div>
          </div>
        </Grid>
      )}
    </>
  );
};

export default OrganizationForm;
