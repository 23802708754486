import { makeStyles } from '@material-ui/core/styles';

/**
 * Hook for injecting custom styles into mui components
 *
 * @param {object} theme - theme object from mui default and/or custom
 * @returns {object} - css styles computed with JS
 */
const useStyles = makeStyles((theme) => ({ // Responsive table styles with MUI
  root: {
    display: 'block',
    padding: 0,
    border: '2px solid lightgray',
    borderRadius: '5px',
    [theme.breakpoints.up('sm')]: {
      display: 'table',
      alignSelf: 'center',
    },
    '& th': {
      display: 'none',
      textAlign: 'center',
      fontWeight: 600,
      color: '#004D26',
      padding: '5px',
      [theme.breakpoints.up('sm')]: {
        display: 'table-cell',
        textAlign: 'left',
      },
    },
    '& td': {
      display: 'block',
      textAlign: 'center',
      fontWeight: 600,
      padding: '5px',
      [theme.breakpoints.up('sm')]: {
        display: 'table-cell',
        textAlign: 'left',
      },
    },
  },
}));

export default useStyles;
