/* eslint-disable react/prop-types */
/* eslint-disable no-plusplus */
import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import API from 'API';
import Recoil from 'recoil';
import bucketName from 'config/bucketName';
import {
  organizationState,
  menuState,
  manageOrganizationState,
} from 'store/atoms';
import _ from 'lodash';
import { Text, TextField } from 'components/UI';
import { organization } from 'API/services';
import useDebounce from 'hooks/useDebounce';
import useCustomSnackbar from 'hooks/useCustomSnackbar';
import DevConsole from 'utils/DevConsole';
import getLocationByOrganization from 'utils/getLocationByOrganization';

import editIcon from 'assets/images/icons/icon-open-pencil.png';
import manageIcon from 'assets/images/icons/icon-arrow-circle-solid-orange.png';
import locationPin from 'assets/images/icons/icon-metro-location.png';
import closeIcon from 'assets/images/icons/icon-close-circle.png';
import uploadIcon from 'assets/images/icons/icon-metro-file-upload.png';

import useStyles from './styles';

const dev = new DevConsole('OrganizationDetail').mute();
const api = new API();

const OrganizationDetail = ({ value }) => {
  const classes = useStyles();
  const [showSnackbar] = useCustomSnackbar();

  const uploadLogoRef = useRef();
  const formFieldRef = useRef([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isReUpload, setIsReUpload] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [currentOrg, setCurrentOrg] = useState({});
  const [uploadedLogo, setUploadedLogo] = useState({});
  const [locationByOrganization, setLocationByOrganization] = useState({});
  const setOrg = Recoil.useSetRecoilState(organizationState);
  const setOpen = Recoil.useSetRecoilState(menuState);
  const setManageOrganization = Recoil.useSetRecoilState(manageOrganizationState);

  /**
   * updateOrganization
   * Updates the organization data based on the organizationId provided to the function
   *
   * @param {string} name - the name to replace the DB copy
   * @param {boolean} address - the address to replace the DB copy
   * @param {boolean} lociManager - the lociManager to replace the DB copy
   * @param {string} safetyManager - the safetyManager to replace the DB copy
   * @param {string} organizationId - the organizationId for the organization to be updated
   */
  const [updateOrganization] = useDebounce(async (inputs, addrObj) => {
    try {
      const values = {
        payload: {
          active: 'true',
          organizationId: currentOrg.organizationId,
        },
        body: {
          organizationName: inputs.organizationName,
          address: addrObj,
          lociManager: inputs.lociManager,
          safetyManager: inputs.safetyManager,
          logo: !_.isEmpty(uploadedLogo) ? uploadedLogo : null,
        },
      };
      dev.log(`values: ${JSON.stringify(values)}`);
      const result = await organization.update(values);
      dev.log(`update result: ${JSON.stringify(result)}`);
      if (result.success) {
        showSnackbar('Organization is updated successfully!', { preventDuplicate: true, variant: 'success' });
        setIsEdit(false);
        setCurrentOrg(result.item);
      }
    } catch (e) {
      dev.error(e.response);
    }
  }, 500);

  const handleUpdate = (e) => {
    e.preventDefault();
    dev.log('updating...');
    const inputs = [];
    const addrObj = {};
    for (let i = 0; i < formFieldRef.current.length; i++) {
      dev.log('current', formFieldRef.current[i]);
      if (formFieldRef.current[i] && formFieldRef.current[i].id) {
        if (formFieldRef.current[i].id.includes('addr_')) {
          const addrKey = formFieldRef.current[i].id.split('addr_');
          addrObj[addrKey[1]] = formFieldRef.current[i].value;
        } else {
          inputs[formFieldRef.current[i].id] = formFieldRef.current[i].value;
        }
      }
    }
    dev.log('inputs', inputs);
    dev.log('addrObj', addrObj);
    updateOrganization(inputs, addrObj);
  };

  const setOrganization = (isManage) => {
    setOrg(currentOrg);
    localStorage.setItem('organizationId', currentOrg.organizationId);
    localStorage.setItem('organizationName', currentOrg.organizationName);
    setOpen(false);

    // only call the setManageOrg if its true otherwise skip
    if (isManage) {
      setManageOrganization(isManage);
    }
  };

  const handleClick = () => {
    uploadLogoRef.current.click();
  };

  const handleUpload = async (e) => {
    dev.log('handleUpload e', e);
    dev.log('uploadLogoRef: ', uploadLogoRef);
    const file = uploadLogoRef.current.files[0];
    const originalFileName = file.name;
    const fileType = file.type;
    const uniqueFileName = uuidv4(); // we use this to avoid issues with same originalFileName
    const folder = 'logo';
    try {
      const image = await api.storage.put(`${folder}/${uniqueFileName}`, file, {
        level: 'public',
        contentType: fileType,
      });
      dev.log('image', image);

      if (!_.isEmpty(image)) {
        setIsUploaded(true);
        setUploadedLogo({
          folder,
          originalFileName,
          fileType,
          uniqueFileName,
        });
      }
    } catch (error) {
      dev.log('Error uploading file: ', error);
    }
  };

  useEffect(() => {
    (async () => {
      dev.log(`value: ${JSON.stringify(value)}`);
      setCurrentOrg(value);

      const locationCount = await getLocationByOrganization(value.organizationId);
      dev.log(`locationCount: ${locationCount}`);
      setLocationByOrganization(locationCount);
    })();

    // cleanup
    return () => {
      setCurrentOrg({});
      setLocationByOrganization({});
    };
  }, []);

  return (
    <>
      {!isEdit && (
        <div
          className={classes.organizationBorder}
          aria-hidden="true"
          onClick={() => { setOrganization(); }}
        >
          <div className={classes.organizationWrapper}>
            <div className={classes.organizationContainer}>
              <div className={classes.organizationHeader}>
                <div className={classes.orgLogoContainer}>
                  {!_.isEmpty(currentOrg.logo) && (
                    <img
                      src={`https://${bucketName.bucket}/public/${currentOrg.logo.folder}/${currentOrg.logo.uniqueFileName}`}
                      key={`https://${bucketName.bucket}/public/${currentOrg.logo.folder}/${currentOrg.logo.uniqueFileName}`}
                      alt="Logo"
                    />
                  )}
                </div>
                <Text>{currentOrg.organizationName}</Text>
              </div>
              <div className={classes.organizationLocation}>
                <div className={classes.locationHeader}>
                  <img src={locationPin} alt="Location Pin" />
                  <Text>{`${locationByOrganization > 0 ? locationByOrganization : 0} Locations`}</Text>
                </div>
                <div className={classes.locationAddress}>
                  <Text>
                    {
                      currentOrg.address && !_.isEmpty(currentOrg.address.streetAddress)
                        ? `${currentOrg.address.streetAddress},
                        ${currentOrg.address.city}, ${currentOrg.address.state} ${currentOrg.address.zip}
                        ${currentOrg.address.country}` : ''
                    }
                  </Text>
                </div>
              </div>
              <div className={classes.mgrContainer}>
                <div className={classes.managers}>
                  {currentOrg.lociManager && (
                    <>
                      <Text className={classes.bold}>LOCI Manager</Text>
                      <Text>{currentOrg.lociManager}</Text>
                    </>
                  )}
                </div>
                <div className={classes.managers}>
                  {currentOrg.safetyManager && (
                    <>
                      <Text className={classes.bold}>Safety Manager</Text>
                      <Text>{currentOrg.safetyManager}</Text>
                    </>
                  )}
                </div>
              </div>
              <div className={classes.buttonContainer}>
                <img
                  src={editIcon}
                  alt="Edit"
                  onClick={() => {
                    setIsEdit(!isEdit);
                    setIsReUpload(false);
                    setIsUploaded(false);
                    setUploadedLogo({});
                  }}
                  aria-hidden="true"
                />
                <div
                  className={classes.manageButton}
                  aria-hidden="true"
                  onClick={() => { setOrganization(true); }}
                >
                  <Text>MANAGE</Text>
                  <img src={manageIcon} alt="Manage" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isEdit && (
        <div className={classes.organizationBorderForm}>
          <div className={classes.organizationWrapper}>
            <div className={classes.organizationContainer}>
              <div className={classes.organizationHeaderForm}>
                {isReUpload && !isUploaded && (
                  <div>
                    <input
                      ref={uploadLogoRef}
                      accept="image/*"
                      type="file"
                      onChange={handleUpload}
                      className={classes.hiddenClass}
                    />
                    <div
                      className={classes.uploadButton}
                      aria-hidden="true"
                      onClick={handleClick}
                    >
                      <img src={uploadIcon} alt="Upload" />
                      <Text>UPLOAD LOGO</Text>
                    </div>
                  </div>
                )}
                {isUploaded && (
                  <div className={classes.uploadFileName}>
                    <Text>{uploadedLogo.originalFileName}</Text>
                  </div>
                )}
                {!isReUpload && !_.isEmpty(currentOrg.logo) && (
                  <div
                    className={classes.reUploadButton}
                    aria-hidden="true"
                    onClick={() => { setIsReUpload(true); }}
                  >
                    <img src={closeIcon} alt="Delete" />
                    <Text>{currentOrg.logo.originalFileName}</Text>
                  </div>
                )}
                <TextField
                  inputRef={ref => formFieldRef.current.push(ref)}
                  name="organizationName"
                  placeholder="Name of Organization"
                  defaultValue={currentOrg.organizationName}
                  variant="outlined"
                />
              </div>
              <div className={classes.locationsForm}>
                <div className={classes.locationHeaderForm}>
                  <img src={locationPin} alt="Location Pin" />
                  <Text>{`${locationByOrganization > 0 ? locationByOrganization : 0} Locations`}</Text>
                </div>
                <div className={classes.locationAddressForm}>
                  <TextField
                    inputRef={ref => formFieldRef.current.push(ref)}
                    name="addr_streetAddress"
                    placeholder="Enter Street Address"
                    variant="outlined"
                    className="streetAddress"
                    defaultValue={currentOrg.address.streetAddress}
                  />
                  <TextField
                    inputRef={ref => formFieldRef.current.push(ref)}
                    name="addr_city"
                    placeholder="Enter City"
                    variant="outlined"
                    className="city"
                    defaultValue={currentOrg.address.city}
                  />
                  <TextField
                    inputRef={ref => formFieldRef.current.push(ref)}
                    name="addr_state"
                    placeholder="Enter State"
                    variant="outlined"
                    className="state"
                    defaultValue={currentOrg.address.state}
                  />
                  <TextField
                    inputRef={ref => formFieldRef.current.push(ref)}
                    name="addr_zip"
                    placeholder="Enter Zip Code"
                    variant="outlined"
                    className="zip"
                    defaultValue={currentOrg.address.zip}
                  />
                  <TextField
                    inputRef={ref => formFieldRef.current.push(ref)}
                    name="addr_country"
                    placeholder="Enter Country"
                    variant="outlined"
                    className="country"
                    defaultValue={currentOrg.address.country}
                  />
                </div>
              </div>
              <div className={classes.mgrContainer}>
                <div className={classes.lociManagersForm}>
                  <Text className={classes.bold}>LOCI Manager</Text>
                  <TextField
                    inputRef={ref => formFieldRef.current.push(ref)}
                    name="lociManager"
                    placeholder="Enter full name"
                    defaultValue={currentOrg.lociManager}
                    variant="outlined"
                  />
                </div>
                <div className={classes.safetyManagersForm}>
                  <Text className={classes.bold}>Safety Manager</Text>
                  <TextField
                    inputRef={ref => formFieldRef.current.push(ref)}
                    name="safetyManager"
                    placeholder="Enter full name"
                    defaultValue={currentOrg.safetyManager}
                    variant="outlined"
                  />
                </div>
              </div>
              <div className={classes.buttonContainerForm}>
                <div
                  className={classes.formButton}
                  onClick={() => setIsEdit(false)}
                  aria-hidden="true"
                >
                  <Text>CANCEL</Text>
                </div>
                <div
                  className={classes.formButton}
                  onClick={handleUpdate}
                  aria-hidden="true"
                >
                  <Text>SAVE</Text>
                </div>
                <div className={classes.clearFloat} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrganizationDetail;
