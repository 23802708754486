/**
 * Configures AWS & AWS Amplify.
 *
 * For info on storage, check https://aws-amplify.github.io/docs/js/storage
 */
import getEnv from 'utils/getEnv';

const config = {
  localhost: {
    max_file_size: 5242880,
    s3: {
      bucket: 'assets.loci.dev',
      region: 'us-east-1',
    },
    apiGateway: {
      endpoints: [
        {
          name: 'feature',
          endpoint: 'https://nzmzhhvfmh.execute-api.us-east-1.amazonaws.com/dev',
          region: 'us-east-1',
        },
        {
          name: 'guest',
          endpoint: 'https://1b38zeycjk.execute-api.us-east-1.amazonaws.com/dev',
          region: 'us-east-1',
        },
        {
          name: 'localization',
          endpoint: 'https://mlxr87csod.execute-api.us-east-1.amazonaws.com/dev',
          region: 'us-east-1',
        },
        {
          name: 'accessKey',
          endpoint: 'https://23ynmfh549.execute-api.us-east-1.amazonaws.com/dev',
          region: 'us-east-1',
        },
        {
          name: 'organization',
          endpoint: 'https://l4d5f3jtmi.execute-api.us-east-1.amazonaws.com/dev',
          region: 'us-east-1',
        },
        {
          name: 'tooltip',
          endpoint: 'https://e5fwu5m9vk.execute-api.us-east-1.amazonaws.com/dev',
          region: 'us-east-1',
        },
        {
          name: 'location',
          endpoint: 'https://s8d85del0f.execute-api.us-east-1.amazonaws.com/dev',
          region: 'us-east-1',
        },
      ],
    },
    cognito: {
      region: 'us-east-1',
      user_pool_id: 'us-east-1_Iz9YQFp4h',
      app_client_id: '6su5h10mlnom5s4kbh2k0uii6g',
      identity_pool_id: 'us-east-1:84e1738c-957a-4ed2-a7d3-df356f3812d8',
    },
    cookieStorage: {
      domain: 'localhost',
      path: '/',
      expires: 90,
      secure: true,
    },
  },

  dev: {
    max_file_size: 5242880,
    s3: {
      bucket: 'assets.loci.dev',
      region: 'us-east-1',
    },
    apiGateway: {
      endpoints: [
        {
          name: 'feature',
          endpoint: 'https://api.loci.dev/feature',
          region: 'us-east-1',
        },
        {
          name: 'guest',
          endpoint: 'https://api.loci.dev/guest',
          region: 'us-east-1',
        },
        {
          name: 'localization',
          endpoint: 'https://api.loci.dev/localization',
          region: 'us-east-1',
        },
        {
          name: 'accessKey',
          endpoint: 'https://api.loci.dev/accessKey',
          region: 'us-east-1',
        },
        {
          name: 'tooltip',
          endpoint: 'https://api.loci.dev/tooltip',
          region: 'us-east-1',
        },
        {
          name: 'organization',
          endpoint: 'https://api.loci.dev/organization',
          region: 'us-east-1',
        },
        {
          name: 'location',
          endpoint: 'https://api.loci.dev/location',
          region: 'us-east-1',
        },
      ],
    },
    cognito: {
      region: 'us-east-1',
      user_pool_id: 'us-east-1_Iz9YQFp4h',
      app_client_id: '6su5h10mlnom5s4kbh2k0uii6g',
      identity_pool_id: 'us-east-1:84e1738c-957a-4ed2-a7d3-df356f3812d8',
    },
    cookieStorage: {
      domain: '.loci.dev',
      path: '/',
      expires: 30,
      secure: true,
    },
  },

  prod: {
    max_file_size: 5242880,
    s3: {
      bucket: 'assets.loci.ca',
      region: 'us-east-1',
    },
    apiGateway: {
      endpoints: [
        {
          name: 'feature',
          endpoint: 'https://api.loci.ca/feature',
          region: 'us-east-1',
        },
        {
          name: 'guest',
          endpoint: 'https://api.loci.ca/guest',
          region: 'us-east-1',
        },
        {
          name: 'localization',
          endpoint: 'https://api.loci.ca/localization',
          region: 'us-east-1',
        },
        {
          name: 'accessKey',
          endpoint: 'https://api.loci.ca/accessKey',
          region: 'us-east-1',
        },
        {
          name: 'organization',
          endpoint: 'https://api.loci.ca/organization',
          region: 'us-east-1',
        },
        {
          name: 'tooltip',
          endpoint: 'https://api.loci.ca/tooltip',
          region: 'us-east-1',
        },
        {
          name: 'location',
          endpoint: 'https://api.loci.ca/location',
          region: 'us-east-1',
        },
      ],
    },
    cognito: {
      region: 'us-east-1',
      user_pool_id: 'us-east-1_fx0KaI2Gl',
      app_client_id: '5qu18fav401oft9mbsbkcftrl7',
      identity_pool_id: 'us-east-1:dc4c7c30-cf59-4151-8030-e43b1979b0a2',
    },
    cookieStorage: {
      domain: '.loci.ca',
      path: '/',
      expires: 30,
      secure: true,
    },
  },
};

export default config[getEnv()];
