import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  dashboard: {
  },
  dashboardInfo: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  badge: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
