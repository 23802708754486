/**
 * Takes in the keys from a JSON object and formats them properly for table headers.
 *
 * @param {string} string - any string passed in
 * @param {any} args - any arguments that correspond to a substring that need to be replaced
 *
 * @returns {string} Formatted string
 *
 */
export const formatText = (string, ...args) => {
  if (!string) return 'Column';
  const findId = string.includes(args[0]);
  return findId
    ? string.replace(args[0], '').replace(/\b\w/g, l => l.toUpperCase())
    : string.replace(args[1], '').replace(/\b\w/g, l => l.toUpperCase());
};
