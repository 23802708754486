import React, { useState, useRef } from 'react';
import JoditEditor from 'jodit-react';
import PropTypes from 'prop-types';

/**
 * Wrapper for Mui Chip component
 *
 * @param {any} props
 * @param {string} props.contentProp
 * @returns {React.component}
 */
function TextEditor({ contentProp }) {
  const editor = useRef(null);
  const [content, setContent] = useState(contentProp);
  const config = {
    readonly: false,
  };

  return (
    <JoditEditor
      ref={editor}
      value={content}
      config={config}
      tabIndex={0}
      onBlur={newContent => setContent(newContent)}
      onChange={() => {}}
    />
  );
}

TextEditor.propTypes = {
  contentProp: PropTypes.string,
};

TextEditor.defaultProps = {
  contentProp: '',
};

export default TextEditor;
