import React from 'react';
import { Text } from 'components/UI';

import lociLogo from 'assets/images/reports/LOCI_logo_no_tag_black.png';
import seaspanLogo from 'assets/images/reports/seaspan_logo_black.png';

import useStyles from './styles';

const ReportsNextPageHeader = () => {
  const classes = useStyles();

  return (
    <div className={classes.nheader}>
      <div className={classes.headerTop}>
        <img src={lociLogo} alt="LOCI Logo" />
        <Text>GSI REPORT</Text>
        <img src={seaspanLogo} alt="Seaspan Logo" />
      </div>
      <hr />
    </div>
  );
};

export default ReportsNextPageHeader;
