import Recoil from 'recoil';

// Selected specific location to Manage
const locationState = Recoil.atom({
  key: 'locationState',
  default: {},
});

// List of locations
const locationListState = Recoil.atom({
  key: 'locationListState',
  default: {},
});

// If Manage button is clicked
const manageLocationState = Recoil.atom({
  key: 'manageLocationState',
  default: false,
});

export {
  locationState,
  locationListState,
  manageLocationState,
};
