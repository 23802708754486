import React from 'react';
import PropTypes from 'prop-types';
import Recoil from 'recoil';
import { Link as RouterLink } from 'react-router-dom';
import { routingState } from 'store/atoms';

/**
 * Link component.
 * Encapsulates the React Router Link.
 * The keyword prop isn't the actual url, it's a key for lookup in the translations table.
 *
 * @param {object} object
 * @param {string} object.keyword
 * @param {string} object.title
 * @param {boolean} object.replace
 * @param {React.Component[]} object.children
 * @returns {React.Component}
 */
function Link({ keyword, title, replace, children, ...props }) {
  const routing = Recoil.useRecoilValue(routingState);

  if (routing.routePaths === undefined) {
    throw new Error('Missing Route Paths');
  }

  const to = routing.routePaths[keyword] || '/';

  return (
    <RouterLink
      to={to}
      replace={replace}
      {...props}
    >
      {title || children || to}
    </RouterLink>
  );
}

Link.propTypes = {
  keyword: PropTypes.string.isRequired,
  title: PropTypes.string,
  replace: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Link.defaultProps = {
  title: null,
  replace: false,
  children: null,
};

// TODO: NavLink variant

export default Link;
