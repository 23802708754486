/**
 * This calculates a percentage and either returns the result as a number or as a string with %
 *
 * @param {number} length1
 * @param {number} length2
 * @param {object} options - whether or not to return a number or string
 *
 * @returns {number|string}
 */
const getPercent = (length1, length2, options) => {
  // [TODO]: Review this and make adjustments to more accurately calculate the percentage
  const {
    abs,
    reverse,
    number,
  } = options;

  let calcPercent;
  if (abs && !reverse) {
    calcPercent = Math.abs((length2 / length1) - 1) * 100;
  }
  if (!abs && reverse) {
    calcPercent = (length1 / length2) * 100;
  }

  // We still need to render something to the UI and users don't know what NaN is
  const checkIsNan = Number.isNaN(calcPercent) ? 0 : calcPercent.toFixed();

  return number ? Number(calcPercent.toFixed()) : `${checkIsNan}%`;
};

export default getPercent;
