import React from 'react';
import DevConsole from 'utils/DevConsole';
import {
  searchFilterState,
} from 'store/selectors';
import {
  lastEvalKey,
  showUpButtonState,
  profileDataState,
} from 'store/atoms';
import { guest } from 'API/services';
// utils
import { decodeJSON64 } from 'utils/encoding';
import convertAPIDataToRows from 'utils/convertAPIData';
// hooks
import { useRecoilValue, useRecoilState } from 'recoil';
import useAuthentication from 'hooks/useAuthentication';
import useInfiniteLoading from 'hooks/useInfiniteLoading';
// Components
import { Loading } from 'components/Layout';
import {
  Search,
  Dashboard,
  Button,
} from 'components/UI';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import columns from './columns';
// import { Table } from './ProfileTable';
// styles
import useStyles from './styles';

const dev = new DevConsole('Profiles');

/**
 * Profiles page: here we display related data for ET, GSI, and SC all in one
 * A SuperAdmin/Admin can click through different badges for each game and view related stats
 *
 * @returns {React.Component}
 */
const Profiles = () => {
  dev.log('Profiles rendered');
  // Custom CSS
  const classes = useStyles();

  // Recoil State
  const [lastEval, setLastEval] = useRecoilState(lastEvalKey);
  const [data, setData] = useRecoilState(profileDataState);
  const gdata = useRecoilValue(searchFilterState);
  const evalKey = useRecoilValue(lastEvalKey);
  const [showUp, setShowup] = useRecoilState(showUpButtonState);

  // hooks
  const authentication = useAuthentication({
    unauthenticatedRedirect: 'SignIn',
    egressRedirect: 'Home',
    group: 'Admins',
  });

  const { pageNum, setLastEl } = useInfiniteLoading({
    service: guest,
    serviceName: 'guest',
    method: 'get',
    payload: {
      companyId: 'LOCI',
      limit: 100,
      start: decodeJSON64(lastEval),
    },
    state: {
      data,
      setData,
      setLastEval,
    },
  });

  // local functions
  const handleScrolltop = () => {
    setShowup(false);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  React.useEffect(() => {
    (async () => {
      try {
        const result = await guest.get({
          companyId: 'LOCI',
          limit: 100,
        });
        if (result.success) {
          convertAPIDataToRows({
            service: 'guest',
            stateUpdater: setData,
            rowsToClean: result.items,
          });
          setLastEval(result.start);
        }
      } catch (error) {
        dev.log({ errCode: error });
      }
    })();
  }, []);

  dev.log({ pageNum, data });

  return authentication.restrictAccess(
    <>
      <Box className={classes.root}>
        <Dashboard data={gdata} />
        <Search />
        <DataGrid
          rows={gdata}
          columns={columns}
          density="compact"
          autoHeight
          autoPageSize
          hideFooter
        />
        {showUp ? (
          <Button
            style={{
              position: 'fixed',
              bottom: '5%',
              right: '5%',
              backgroundColor: '#004D26',
              color: '#FFFFFF',
            }}
            onClick={handleScrolltop}
          >
            Back To Top
          </Button>
        ) : null}
        {evalKey ? (
          <section className={classes.infLoading}>
            <Loading ref={setLastEl} />
          </section>
        ) : null}
      </Box>
    </>,
  );
};

export default Profiles;
