/**
 * Products config
 *
 * Returns products supported.
 * Code is used in database as productId;
 * Name is the original product name in English;
 * Keyword is used when we have translations available, to potentially translate.
 */

export default [
  { id: 'MYLOCI', name: 'My LOCI', keyword: 'myloci' },
  { id: 'GSI', name: 'Germ Science Investigation', keyword: 'germscienceinvestigation' },
  { id: 'ET', name: 'Exit Test', keyword: 'exittest' },
  { id: 'SC', name: 'Safety Check', keyword: 'safetycheck' },
];
