/**
 * Routing helper functions
 *
 * These help convert route information found in config/routes.js into usable React Router routes.
 *
 */
import React from 'react';
import _ from 'lodash';
import { Route } from 'react-router-dom';
import Auth from 'utils/Auth';
import DevConsole from 'utils/DevConsole';

const dev = new DevConsole('routing').mute();
const auth = new Auth();

/**
 * Transforms a lazy-loaded object into a proper React Component.
 *
 * @param {React.Component} Component
 *
 * @returns {React.Component}
 */
const lazyComponent = Component => props => <Component {...props} />;

/**
 * parseRoute method
 *
 * Parses the Route Components from a given route collection.
 * Looks for route path in the collection. If it can't find it, assumes argument passed is route object.
 *
 * @param {object} routes
 * @param {string|string[]|object} id
 * @returns {?React.Component} Route Component if it applies
 */
const parseRoute = (routes, id) => {
  const route = _.get(routes, id, id);
  try {
    const {
      path,
      page,
      ...rest
    } = route;
    if (typeof page === 'string' && page === 'External') {
      return null; // We don't render external links to the Router
    } if (typeof page === 'object') {
      dev.log('Setting up route');
      return <Route {...rest} path={path} component={lazyComponent(page)} key={`route${path}`} />;
    }
    dev.log(`Setting up route for ${path}`);
    return <Route {...rest} path={path} component={page} key={`route${path}`} />;
  } catch (e) {
    return null;
  }
};

/**
 * expandAllRoutes()
 *
 * Walk routes collection and expands all routes found in it.
 *
 * @param {object} routes - Routes collection to walk
 * @param {string} routes.page
 *
 * @returns {React.Component[]} Expanded route objects
 */
const expandAllRoutes = routes => {
  return _.map(routes, obj => (obj.page ? parseRoute(routes, obj) : expandAllRoutes(obj)));
};

/**
 * convertRoutesToLinks
 *
 * This function grabs routes & returns an object containing only the path string.
 * These are then used for links throughout the website.
 *
 * @param {object} routes
 * @param {string} routes.path
 *
 * @returns {object}
 */
const convertRoutesToLinks = routes => {
  const routePaths = {};

  _.map(routes, (route, id) => {
    routePaths[id] = route.path ? route.path : convertRoutesToLinks(route);
  });
  return routePaths;
};

/**
 * convertRoutesToMenuLinks
 *
 * This function grabs routes & translates them into text links to be stored in the FeatureFlags.
 * These are then used for menu links on the website.
 *
 * @param {object} routes
 * @param {string} routes.path
 *
 * @returns {object}
 */
const convertRoutesToMenuLinks = async routes => {
  const newRoutes = {};
  const promises = [];

  // Filter Menu Links by menuOrder that are > 0 and check if the signed user has access to them
  Object.entries(routes)
    .forEach(([key, value]) => {
      if (value.menuOrder > 0) {
        if (value.accessGroups) {
          promises.push(new Promise((resolve) => {
            auth.isInGroup(['SuperAdmins', ...value.accessGroups]).then((result) => {
              if (result) {
                newRoutes[key] = value;
              }
              resolve(result);
            });
          }));
        } else {
          newRoutes[key] = value;
        }
      }
    });
  await Promise.all(promises);

  // Sort the filtered routes to properly display the Menu Links order
  const sortedRoutes = {};
  Object.entries(newRoutes)
    .sort((a, b) => { return b[1].menuOrder < a[1].menuOrder ? 1 : -1; })
    .forEach(([key, value]) => {
      sortedRoutes[key] = value;
    });

  const routePaths = {};
  _.map(sortedRoutes, (route, id) => {
    routePaths[id] = route.path ? route.path : convertRoutesToMenuLinks(route);
  });

  return routePaths;
};

export {
  parseRoute,
  expandAllRoutes,
  convertRoutesToLinks,
  convertRoutesToMenuLinks,
};
