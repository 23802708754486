/**
 * Locations API endpoint
 *
 * Wrap around utility for our locations microservice.
 */
import API from 'API';
import mockData from './mockData';

const api = new API();
const service = 'location';
const path = '/location';

const location = {
  mock: () => api.setMock(mockData),
  live: () => api.setMock(null),
  list: payload => api.exec({
    action: 'get',
    service,
    path,
    payload: {
      organizationId: payload.organizationId,
    },
  }),
  create: init => api.exec({
    action: 'post',
    service,
    path,
    init: {
      headers: init.headers,
      body: init.body,
    },
  }),
  update: mixedData => api.exec({
    action: 'put',
    service,
    path,
    payload: {
      organizationId: mixedData.payload.organizationId,
      locationId: mixedData.payload.locationId,
    },
    init: {
      headers: mixedData.headers,
      body: mixedData.body,
    },
  }),
  delete: payload => api.exec({
    action: 'del',
    service,
    path,
    payload: {
      organizationId: payload.organizationId,
      locationId: payload.locationId,
    },
  }),
};

export default location;
