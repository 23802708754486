import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Recoil from 'recoil';
import _ from 'lodash';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteForever from '@material-ui/icons/DeleteForever';
// import DragHandleIcon from '@material-ui/icons/DragHandle';
import { Text as T, TextField, Button } from 'components/UI';
import { localization } from 'API/services';
import useCustomSnackbar from 'hooks/useCustomSnackbar';
import { cleanTranslation } from 'utils/sanitize';
import { isolate } from 'utils/isolate';
import DevConsole from 'utils/DevConsole';
import {
  productIdState,
  groupsListState,
  translationsListState,
} from 'store/atoms';
import TranslationItem from './TranslationItem';
import useStyles from './styles';


const dev = new DevConsole('TranslationGroup').mute();


/**
 * Translation Group
 *
 * @param {object} props
 * @param {number} props.index
 * @param {number} [props.listOrder]
 *
 * @returns {React.Component}
 */
function TranslationGroup(props) {
  const {
    index,
    // listOrder,
  } = props;

  const classes = useStyles();
  const [showSnackbar, closeSnackbar] = useCustomSnackbar();

  const productId = Recoil.useRecoilValue(productIdState);
  const [groupsList, setGroupsList] = Recoil.useRecoilState(groupsListState);
  const [translationsList, setTranslationsList] = Recoil.useRecoilState(translationsListState);

  const { uuid, groupName } = groupsList[index];
  const otherGroups = _.filter(groupsList, g => g.uuid !== uuid);

  const [name, setName] = useState(groupName);
  const [error, setError] = useState(false);
  // const [order, setOrder] = useState(listOrder);
  const [expanded, setExpanded] = useState(false);

  const translationsInGroup = _.filter(translationsList, t => t.groupId === uuid);

  const APIUpdate = async (newName) => {
    // Updating global state is slow, but required for keeping track of duplicate group names
    setGroupsList(groupsList.map(g => {
      if (g.uuid === uuid) {
        return {
          ...g,
          groupName: newName,
        };
      }
      return g;
    }));

    try {
      await localization.group.update({
        payload: {
          productId,
          uuid,
        },
        body: {
          groupName: newName,
          listOrder: 1,
        },
      });
      dev.info(`Saved group "${newName}"`);
    } catch (e) {
      dev.error(e.response);
    }
  };

  const debouncedUpdate = useMemo(() => _.debounce((...params) => {
    APIUpdate(...params);
  }, 500, { trailing: true }), [groupsList]);

  const onDelete = async () => {
    if (translationsInGroup.length === 0) {
      try {
        await localization.group.delete({ productId, uuid });
        setGroupsList(_.filter(groupsList, g => g.uuid !== uuid));
      } catch (e) {
        dev.error(e.response);
      }
    } else {
      closeSnackbar();
      showSnackbar('Cannot delete non-empty scene.', { preventDuplicate: true, variant: 'error' });
    }
  };

  const onChangeName = (e) => {
    const { value } = e.currentTarget;

    setName(value);

    if (_.findIndex(otherGroups, g => cleanTranslation(g.groupName) === cleanTranslation(value)) !== -1) {
      debouncedUpdate.cancel();
      setError('Duplicate group name');
    } else {
      debouncedUpdate(value);
      setError(false);
    }
  };

  const createTranslation = async () => {
    try {
      const result = await localization.translation.create({
        body: {
          productId,
          groupId: uuid,
        },
      });
      setTranslationsList(_.concat(translationsList, result.item));
    } catch (e) {
      dev.error(e.response);
    }
  };

  // const onDrag = () => {
  //   dev.warn('Drag not implemented yet');
  //   setOrder(order);
  // };

  dev.log('render');

  return (
    <Accordion
      className={classes.accordion}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-label="Expand"
        aria-controls="accordion-content"
        id="accordion-header"
      >
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <T>Scene</T>
          </Grid>
          <Grid item xs={3}>
            <TextField
              outlined
              value={name}
              className={classes.textField}
              onChange={onChangeName}
              onClick={isolate()}
              error={!!error}
              helperText={error}
            />
          </Grid>
          <Grid item xs={6}>
            <T>{`${translationsInGroup.length} translations`}</T>
          </Grid>
          <Grid item xs={2} className={classes.groupControls}>
            <DeleteForever onClick={isolate(onDelete)} className={classes.icon} />
            {/* <DragHandleIcon onClick={isolate()} onMouseDown={isolate(onDrag)} /> */}
          </Grid>
        </Grid>
      </AccordionSummary>

      { expanded
        ? (
          <AccordionDetails className={classes.details}>
            <Grid container direction="row">
              <Grid item xs={12}>
                { translationsInGroup.length
                  ? (
                    <Grid container alignItems="center">
                      {/* {_.sortBy(translations, t => t.listOrder).map((item, index) => ( */}
                      {translationsInGroup.map((item, idx) => (
                        <TranslationItem
                          uuid={item.uuid}
                          groupId={item.groupId}
                          listOrder={idx}
                          key={item.uuid}
                        />
                      ))}
                      <Grid item xs={1} />
                    </Grid>
                  )
                  : <T p a="c">No translations yet. Please create one.</T>}
              </Grid>
              <Grid item xs={12} className={classes.controls}>
                <Button variant="contained" onClick={createTranslation}>Add translation</Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        )
        : <AccordionDetails className={classes.details} /> }
    </Accordion>

  );
}

TranslationGroup.propTypes = {
  index: PropTypes.number.isRequired,
  // listOrder: PropTypes.number,
};

TranslationGroup.defaultProps = {
  // listOrder: 0,
};

export default TranslationGroup;
