import { makeStyles } from '@material-ui/core/styles';

const borderColor = '#4449C9';
const dropShadowColor = '#00000029';
const totalPlaysTitleColor = '#4449C9';
const averageScoreTitleColor = '#002EE2';
const grey = '#7C828A';

const useStyles = makeStyles(() => ({
  root: {
    padding: '0 2rem',
    fontFamily: 'Open Sans',
  },
  settingsContainer: {
    margin: '2% 0',
    padding: '1%',

    '& button': {
      float: 'right',
    },
  },
  reportsContainer: {
    width: '95%',
    margin: 'auto',
  },
  reportContainer: {
    margin: '1vw',
  },
  header: {
    color: '#000',

    '& hr': {
      height: '1.28vh',
      backgroundColor: '#000',
    },
  },
  headerTop: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 2vw',

    '& p': {
      fontSize: '1.25vw',
      fontWeight: 900,
    },
  },
  headerBottom: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    margin: '1vw 2vw 0 2vw',
  },
  urlContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    margin: '0.75vw 0 0 2vw',

    '& .title': {
      fontSize: '1.25vw',
      fontWeight: 'bold',
    },
    '& .url': {
      fontSize: '0.85vw',
    },
  },
  qrContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: '0.45vw',
    marginLeft: 'auto',
    order: 2,
    border: '0.05vw solid #000',
    padding: '0.25vw',
  },
  managersContainer: {
    display: 'flex',
    flexDirection: 'row',

    '& div': {
      marginTop: '0.75vw',

      '& .mtitle': {
        fontSize: '0.85vw',
        fontWeight: 'bold',
      },
      '& .name': {
        fontSize: '0.85vw',
      },
    },
    '& div:nth-child(2)': {
      marginLeft: '3vw',
    },
    '& div:nth-child(3)': {
      marginLeft: '3vw',
    },
  },
  staContainer: {
    margin: '2vw 2vw 0',

    '& .sheader': {
      fontSize: '1vw',
      fontWeight: 'bold',
      letterSpacing: '0.05vw',
      margin: '0 0 1vw 2vw',
    },
  },
  badgeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '2vw 1vw',
    border: `1px ${borderColor} solid`,
    borderRadius: '0.625vw',

    '& div': {
      position: 'relative',

      '& img': {
        width: '10.9375vw',
        height: '25.61vh',
        dropShadow: `0  0.156vw 0.3125vw ${dropShadowColor}`,
      },
      '& .label': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',

        '& .atitle': {
          fontSize: ' 0.85vw',
          fontWeight: 'bold',
          width: '6.25vw',
        },
        '& .value': {
          width: ' 6.5vw',
          fontSize: '1.875vw',
          fontWeight: 'bold',
        },
      },
    },
  },
  rankingsContainer: {
    margin: '2vw 2vw 0',

    '& .rheader': {
      fontSize: '1vw',
      fontWeight: 'bold',
      letterSpacing: '0.05vw',
      margin: '0 0 1vw 2vw',
    },
  },
  rankChartContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rankings: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
    height: '23.4375vw',
  },
  ranks: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '2vw 1vw',
    border: `0.05vw ${borderColor} solid`,
    borderRadius: '0.625vw',
    width: '26vw',
    height: '14.4vh',

    '& .label': {
      marginLeft: '2vw',
      width: '15vw',

      '& .rtitle': {
        fontFamily: 'spyitalic',
        fontSize: ' 1.5vw',
        color: totalPlaysTitleColor,
        letterSpacing: '0.15vw',
      },
      '& .rvalue': {
        fontSize: ' 1.927vw',
        fontWeight: 'bold',
      },
    },
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  totalPieChart: {
    padding: '2vw 1vw',
    border: `0.05vw ${borderColor} solid`,
    borderRadius: '0.625vw',
    width: '45.3125vw',
    height: '48vh',

    '& .ptitle': {
      fontSize: '1.25vw',
      fontWeight: 'bold',
    },
    '& .pdate': {
      marginTop: '0.25vw',
      fontSize: '0.83vw',
      color: grey,
    },
  },
  pieChart: {
    display: 'flex',
    justifyContent: 'center',
  },
  overContainer: {
    margin: '2vw 2vw 0',

    '& .oheader': {
      fontSize: '1vw',
      fontWeight: 'bold',
      letterSpacing: '0.05vw',
      margin: '0 0 1vw 2vw',
    },
  },
  overContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '2vw 3vw 2vw 2vw',
    border: `1px ${borderColor} solid`,
    borderRadius: '0.625vw',
    height: '49vh',
  },
  questionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
    height: '41.62vh',

    '& div': {
      border: `1px ${borderColor} solid`,
      borderRadius: '0.625vw',
      width: '35vw',
      height: '11.5vh',
      padding: '1vw',

      '& .level': {
        fontSize: '0.85vw',
        fontWeight: 'bold',
      },
      '& .question': {
        fontSize: '1vw',
      },
    },
    '& .qheader': {
      fontSize: '1.25vw',
      fontWeight: 'bold',
    },
  },
  averageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
    height: '41.62vh',
    width: '30vw',

    '& .aheader': {
      fontSize: '1.25vw',
      fontWeight: 'bold',
    },
  },
  levelContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',
    marginTop: '0.75vw',
  },
  levelSplit: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',
  },
  levels: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
    width: '14.5vw',
    height: '16vh',
    border: `1px ${borderColor} solid`,
    borderRadius: '0.625vw',
    padding: '1vw',

    '& .title': {
      fontFamily: 'spyitalic',
      fontSize: ' 1.5vw',
      color: averageScoreTitleColor,
      letterSpacing: '0.1vw',
      marginTop: '-1.75vw',
    },
    '& .score': {
      fontSize: ' 1.82vw',
      fontWeight: 'bold',
    },
  },
  levelBadge: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'space-between',
    width: '100%',

    '& img': {
      marginTop: '-0.5vw',
      width: '100%',
      height: '100%',
    },
    '& p': {
      fontSize: ' 0.75vw',
      fontWeight: 600,
    },
  },
  nheader: {
    color: '#000',
    marginTop: '3vw',

    '& hr': {
      height: '1.28vh',
      backgroundColor: '#000',
    },
  },
  nextPageContainer: {
    margin: '2vw 2vw 0',
  },
  langAveContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1vw 0 0',
  },
  languagesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
    padding: '2vw',
    border: `0.05vw ${borderColor} solid`,
    borderRadius: '0.625vw',
    width: '30vw',
    height: '104vh',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'space-between',
    width: '100%',

    '& .title': {
      fontSize: '1.25vw',
      fontWeight: 'bold',
    },
    '& .value': {
      fontSize: '1vw',
      color: grey,
    },
  },
  languageBar: {
    width: '100%',

    '& p': {
      fontSize: '0.8vw',
      fontWeight: 'bold',
    },
  },
  aveLegendContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
    width: '42vw',
    height: '104vh',
  },
  barChartContainer: {
    padding: '2vw',
    border: `0.05vw ${borderColor} solid`,
    borderRadius: '0.625vw',
    width: '100%',
    height: '60vh',

    '& .ptitle': {
      fontSize: '1.4vw',
      fontWeight: 'bold',
    },
    '& .pdate': {
      marginTop: '0.25vw',
      fontSize: '0.83vw',
      color: grey,
    },
  },
  barChart: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '40vh',
  },
  legendContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
    padding: '2vw',
    border: `0.05vw ${borderColor} solid`,
    borderRadius: '0.625vw',
    width: '100%',
    height: '42vh',

    '& .ltitle': {
      fontSize: '1.4vw',
      fontWeight: 'bold',
    },

    '& div': {
      '& .title': {
        fontSize: '1vw',
        fontWeight: 'bold',
      },
      '& .score': {
        fontSize: '0.9vw',
      },
    },
  },
  smallNBadgeContainer: {
    width: '5.5vw',
    height: '5.5vw',
  },
  smallRBadgeContainer: {
    width: '4.5vw',
    height: '5.5vw',
  },
  smallSBadgeContainer: {
    width: '5.75vw',
    height: '5.5vw',
  },
  smallerNbadgeContainer: {
    width: '3.5vw',
    height: '3.5vw',
  },
  smallerRbadgeContainer: {
    width: '3.25vw',
    height: '3.5vw',
  },
  smallerSbadgeContainer: {
    width: '3.75vw',
    height: '3.5vw',
  },
  clearFloat: {
    clear: 'both',
  },
}));

export default useStyles;
