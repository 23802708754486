import React from 'react';
import { Chip } from '@mui/material';

const columns = [
  {
    field: 'accessKey',
    headerName: 'URL',
    type: 'string',
    width: 50,
    minWidth: 75,
    flex: 2,
  },
  {
    field: 'grade',
    headerName: 'Grade',
    type: 'string',
    minWidth: 50,
    flex: 2,
  },
  {
    field: 'score',
    headerName: 'Score',
    type: 'string',
    minWidth: 75,
    flex: 2,
  },
  {
    field: 'location',
    headerName: 'Location',
    type: 'string',
    minWidth: 50,
    flex: 2,
  },
  {
    field: 'course',
    headerName: 'Course',
    type: 'string',
    minWidth: 75,
    flex: 2,
  },
  {
    field: 'dateCreated',
    headerName: 'First Play',
    type: 'date',
    minWidth: 75,
    flex: 2,
  },
  {
    field: 'dateUpdated',
    headerName: 'Last Play',
    type: 'string',
    minWidth: 75,
    flex: 2,
  },
  {
    field: 'progress',
    headerName: 'Progress',
    type: 'string',
    minWidth: 50,
    flex: 2,
  },
  {
    field: 'profile',
    headerName: 'Profile',
    type: 'string',
    minWidth: 50,
    flex: 2,
    renderCell: (params) => {
      const onClick = () => {
        return params;
      };
      return <Chip label="View Profile" onClick={onClick} />;
    },
  },
  {
    field: 'completed',
    headerName: 'Status',
    type: 'boolean',
    minWidth: 50,
    flex: 2,
  },
];

export default columns;
