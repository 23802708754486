import React from 'react';
import useAuthentication from 'hooks/useAuthentication';
import useStyles from './styles';

/**
 * Home Page
 *
 * @returns {React.Component}
 */
function Organization() {
  const classes = useStyles();
  const authentication = useAuthentication({
    unauthenticatedRedirect: 'SignIn',
    egressRedirect: 'Home',
    group: 'Admins',
  });

  return authentication.restrictAccess(
    <div className={classes.root}>
      Organization page
    </div>,
  );
}

export default Organization;
