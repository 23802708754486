import React from 'react';
import useAuthentication from 'hooks/useAuthentication';

import './styles.scss';

/**
 * Layout.Footer Component
 *
 * @returns {React.Component}
 */
function Footer() {
  const authentication = useAuthentication();

  return authentication.restrictAccess(
    <footer className="footer">
      <span>LOCI</span>
    </footer>,
  );
}

export default Footer;
