import React from 'react';
import { Input as MUIInput } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';

/**
 * Wrapper for mui input component
 *
 * @param {any} props
 * @param {string} props.placeholder - the placeholder value for the input
 * @param {string} props.color - the input background color
 * @param {string} props.type - the input type e.g. text, textarea, etc
 * @param {React.component} props.icon - the material icon passed to input
 * @returns {React.component}
 */
function Input({ placeholder, color, type, icon, ...props }) {
  return (
    <MUIInput
      placeholder={placeholder}
      color={color}
      type={type}
      startAdornment={(
        <InputAdornment position="start">
          {icon}
        </InputAdornment>
      )}
      {...props}
    />
  );
}

Input.propTypes = {
  placeholder: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.object,
};

Input.defaultProps = {
  placeholder: 'Text',
  color: 'primary',
  type: 'text',
  icon: {},
};

export default Input;
