import Recoil from 'recoil';

const menuState = Recoil.atom({
  key: 'menuState',
  default: false,
});

export default menuState;

export {
  menuState,
};
