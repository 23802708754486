import { v4 as uuidv4 } from 'uuid';
import safetyImg from '../../../assets/images/course-icons/safety.png';
import buildingImg from '../../../assets/images/course-icons/building.png';
import workplaceImg from '../../../assets/images/course-icons/workplace.png';
import course1 from '../../../assets/images/course-icons/1.png';
import course2 from '../../../assets/images/course-icons/2.png';
import course3 from '../../../assets/images/course-icons/3.png';
import course4 from '../../../assets/images/course-icons/4.png';
import course5 from '../../../assets/images/course-icons/5.png';
import course6 from '../../../assets/images/course-icons/6.png';
import course7 from '../../../assets/images/course-icons/7.png';
import course8 from '../../../assets/images/course-icons/8.png';
import course9 from '../../../assets/images/course-icons/9.png';
import course10 from '../../../assets/images/course-icons/10.png';
import course11 from '../../../assets/images/course-icons/11.png';

const data = {
  courses: [
    {
      id: uuidv4(),
      title: 'PANDEMIC SAFETY',
      subtitle: 'Test users on their germ safety knowledge.',
      icon: safetyImg,
      description1: 'The Germ Safe System uses micro-learning and other gamified elements to capture people’s',
      description2: 'attention and deliver important information in a way that is engaging and digestible.',
      description3: 'A global pandemic is a very serious matter. Gamifying germ safety doesn’t undermine this reality,',
      description4: 'it enhances our ability to understand and apply the',
      description5: 'knowledge we need to stay safe.',
    },
    {
      id: uuidv4(),
      title: 'BUILDING SAFETY',
      subtitle: 'Test users on their building safety knowledge.',
      icon: buildingImg,
      description1: 'Exit Test translates hard-to-understand floor plans and maps into highly accessible visual ',
      description2: 'experiences. The visual evacuation orientation can be shared with anyone who enters your ',
      description3: 'premises, helping them imprint where the safe exits are, instead of where the entrance was. ',
      description4: 'Exit Test gives everyone the opportunity to experience a fire drill in seconds and acquire ',
      description5: 'the life-saving knowledge of where to go in an emergency.',
    },
    {
      id: uuidv4(),
      title: 'WORKPLACE SAFETY',
      subtitle: 'Test users on their workplace safety awareness knowledge.',
      icon: workplaceImg,
      description1: 'Safety Check turns a building’s fire zones into customized, scenario-based ',
      description2: 'safety training courses that build situational awareness and competence with an ',
      description3: 'array of common emergency scenarios. The self-guided, on-demand format ',
      description4: 'takes people out of the conference room and into the space where training ',
      description5: 'is relevant to ensure better learning outcomes overall.',
    },
  ],
  customCourses: [
    {
      id: uuidv4(),
      title: 'Route finder',
      type: 'custom',
      icon: course1,
      subtitle1: 'Point A to Point B pre-visualization of any route',
      subtitle2: '(e.g., delivery location, guest orientation, or evacuation).',
    },
    {
      id: uuidv4(),
      title: 'Check points',
      type: 'custom',
      icon: course2,
      subtitle1: 'Make physical routes interactive with scannable smart ',
      subtitle2: 'stickers.',
    },
    {
      id: uuidv4(),
      title: 'Tap quiz',
      type: 'custom',
      icon: course3,
      subtitle1: 'Create a CAPTCHA style visual quiz that uses images or ',
      subtitle2: 'maps.',
    },
    {
      id: uuidv4(),
      title: 'Highlighter',
      type: 'custom',
      icon: course4,
      subtitle1: 'This is a feature within each of the components (editing tool) not a standalone ',
      subtitle2: 'component.',
    },
    {
      id: uuidv4(),
      title: 'Smart stickers',
      type: 'custom',
      icon: course5,
      subtitle1: 'Create scannable stickers that provide information or instructions for any safety fixture,',
      subtitle2: 'object or location.',
    },
    {
      id: uuidv4(),
      title: 'Scenarios',
      type: 'custom',
      icon: course6,
      subtitle1: 'Build problem-solving skills with scenario chains that users complete by finding ',
      subtitle2: 'correct resolutions.',
    },
    {
      id: uuidv4(),
      title: 'This or that',
      type: 'custom',
      icon: course7,
      subtitle1: 'Create a tap quiz with two answer ',
      subtitle2: 'options.',
    },
    {
      id: uuidv4(),
      title: 'Multiple choice',
      type: 'custom',
      icon: course8,
      subtitle1: 'Create a tap quiz with multiple answer ',
      subtitle2: 'options.',
    },
    {
      id: uuidv4(),
      title: 'True / false',
      type: 'custom',
      icon: course9,
      subtitle1: 'Create a tap quiz with preset true-false answer ',
      subtitle2: 'options.',
    },
    {
      id: uuidv4(),
      title: 'Character',
      type: 'custom',
      icon: course10,
      subtitle1: 'Upload your own branded character images to host your ',
      subtitle2: 'courses.',
    },
    {
      id: uuidv4(),
      title: 'Theme',
      type: 'custom',
      icon: course11,
      subtitle1: 'Customize your course with branded colors, logos and ',
      subtitle2: 'images.',
    },
  ],
};

export default data;
