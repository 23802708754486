import Recoil from 'recoil';

// Used to toggle Translations page
export const twoLineEditState = Recoil.atom({
  key: 'twoLineEditState',
  default: false,
});

// Used to toggle Translations page
export const filtersState = Recoil.atom({
  key: 'filtersState',
  default: {
    lang_en: true,
  },
});

// Contains translation groups
export const groupsListState = Recoil.atom({
  key: 'groupsListState',
  default: [],
});

// Contains translations items
export const translationsListState = Recoil.atom({
  key: 'translationsListState',
  default: [],
});
