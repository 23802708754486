import React from 'react';
import { Text } from 'components/UI';
import QRCode from 'react-qr-code';

import lociLogo from 'assets/images/reports/LOCI_logo_no_tag_black.png';
import seaspanLogo from 'assets/images/reports/seaspan_logo_black.png';
import gsiLogo from 'assets/images/reports/GermSafe_logo_color_72dpi.png';

import useStyles from './styles';

const ReportsHeader = () => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <div className={classes.headerTop}>
        <img src={lociLogo} alt="LOCI Logo" />
        <Text>GSI REPORT</Text>
        <img src={seaspanLogo} alt="Seaspan Logo" />
      </div>
      <hr />
      <div className={classes.headerBottom}>
        <img src={gsiLogo} alt="GSI Logo" />
        <div className={classes.urlContent}>
          <div>
            <Text className="title">SEASPAN URL</Text>
            <Text className="url">https://gsi.learnbyloci.com/seaspan</Text>
          </div>
          <div className={classes.managersContainer}>
            {/* <div>
              <Text className="mtitle">Safety Warden</Text>
              <Text className="name">Tiffany Holland</Text>
            </div>
            <div>
              <Text className="mtitle">Zone Captain</Text>
              <Text className="name">Samantha Chase</Text>
            </div>
            <div>
              <Text className="mtitle">Backup Zone Captain</Text>
              <Text className="name">Vanessa Dayton</Text>
            </div> */}
          </div>
        </div>
        <div className={classes.qrContainer}>
          <QRCode value="https://gsi.learnbyloci.com/seaspan" title="GSI" level="H" size={110} />
        </div>
      </div>
    </div>
  );
};

export default ReportsHeader;
