import React from 'react';
import { Text } from 'components/UI';

import useStyles from './styles';

const Legend = () => {
  const classes = useStyles();

  return (
    <div className={classes.legendContainer}>
      <Text className="ltitle">LEGEND</Text>

      <div>
        <Text className="title">AVERAGE SCORE</Text>
        <Text className="score">Based on total plays including multiple attempts from the same device.</Text>
      </div>
      <div>
        <Text className="title">FAIL</Text>
        <Text className="score">
          Scores of less than 50% (0-9/18) are considered a fail. Player does not receive a fail message, they are given “Novice” rank.
        </Text>
      </div>
      <div>
        <Text className="title">PLAYS</Text>
        <Text className="score">1st, 2nd and 3+ attempts on the same device are reported.</Text>
      </div>
      <div>
        <Text className="title">RANKS</Text>
        <Text className="score">NOVICE = Below 50% (0-9/18)</Text>
        <Text className="score">ROOKIE = 50 - 85% (9-15/18)</Text>
        <Text className="score">SCIENTIST = 86% and above (15-18/18)</Text>
      </div>
    </div>
  );
};

export default Legend;
