import React from 'react';
import { Text } from 'components/UI';

import noviceSbadge from 'assets/images/reports/germ_novice_badge_smaller@3x.png';
import rookieSbadge from 'assets/images/reports/germ_rookie_badge_smaller@3x.png';
import scientistSbadge from 'assets/images/reports/germ_scientist_badge_smaller@3x.png';

import useStyles from './styles';

const OverallResults = () => {
  const classes = useStyles();

  return (
    <div className={classes.overContainer}>
      <Text className="oheader">OVERALL RESULTS</Text>
      <div className={classes.overContentContainer}>
        <div className={classes.questionsContainer}>
          <Text className="qheader">TOP 3 INCORRECTLY ANSWERED QUESTIONS</Text>
          <div>
            <Text className="level">Level 1</Text>
            <Text className="question">How many hours can COVID-19 survive on steel?</Text>
          </div>
          <div>
            <Text className="level">Level 2</Text>
            <Text className="question">Sneezing close to people.</Text>
          </div>
          <div>
            <Text className="level">Level 3</Text>
            <Text className="question">How many seconds does it take to kill COVID-19 when you wash your hands?</Text>
          </div>
        </div>
        <div className={classes.averageContainer}>
          <Text className="aheader">AVERAGE SCORE BY LEVEL</Text>
          <div className={classes.levelContainer}>
            <div className={classes.levelSplit}>
              <div className={classes.levels}>
                <div className={classes.levelBadge}>
                  <Text>Level 1</Text>
                  <div className={classes.smallerNbadgeContainer}>
                    <img src={noviceSbadge} alt="Badge" />
                  </div>
                </div>
                <Text className="title">NOVICE</Text>
                <Text className="score">14/35</Text>
              </div>
              <div className={classes.levels}>
                <div className={classes.levelBadge}>
                  <Text>Level 2</Text>
                  <div className={classes.smallerSbadgeContainer}>
                    <img src={scientistSbadge} alt="Badge" />
                  </div>
                </div>
                <Text className="title">SCIENTIST</Text>
                <Text className="score">30/35</Text>
              </div>
            </div>
            <div className={classes.levelSplit}>
              <div className={classes.levels}>
                <div className={classes.levelBadge}>
                  <Text>Level 3</Text>
                  <div className={classes.smallerNbadgeContainer}>
                    <img src={noviceSbadge} alt="Badge" />
                  </div>
                </div>
                <Text className="title">NOVICE</Text>
                <Text className="score">14/35</Text>
              </div>
              <div className={classes.levels}>
                <div className={classes.levelBadge}>
                  <Text>Level 4</Text>
                  <div className={classes.smallerRbadgeContainer}>
                    <img src={rookieSbadge} alt="Badge" />
                  </div>
                </div>
                <Text className="title">ROOKIE</Text>
                <Text className="score">25/35</Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverallResults;
