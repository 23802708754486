import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '95%',
    margin: '0 auto',
  },
  infLoading: {
    width: 150,
    height: 150,
    margin: '5% auto',
  },
});

export default useStyles;
