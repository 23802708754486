import React, { useState, useMemo, useEffect, useReducer } from 'react';
import Recoil from 'recoil';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import DeleteForever from '@material-ui/icons/DeleteForever';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { Text as T, TextField, Button } from 'components/UI';

import { localization } from 'API/services';
import useDebounce from 'hooks/useDebounce';
import { cleanTranslation } from 'utils/sanitize';
import { isolate } from 'utils/isolate';
import DevConsole from 'utils/DevConsole';
import {
  dialogVisibilityState,
  dialogContentState,
  productIdState,
  translationsListState,
  twoLineEditState,
  languageState,
  filtersState,
} from 'store/atoms';
import useStyles from './styles';


const dev = new DevConsole('TranslationItem');

/**
 * Translation Item
 *
 * @param {object} props
 * @param {string} props.uuid
 *
 * @returns {React.Component}
 */
function TranslationItem(props) {
  dev.log('render');
  const { uuid, groupId } = props;
  const classes = useStyles();
  const languages = Recoil.useRecoilValue(languageState);
  const filters = Recoil.useRecoilValue(filtersState);
  const productId = Recoil.useRecoilValue(productIdState);
  const twoLineEdit = Recoil.useRecoilValue(twoLineEditState);
  const [translationsList, setTranslationsList] = Recoil.useRecoilState(translationsListState);
  const setDialogVisibility = Recoil.useSetRecoilState(dialogVisibilityState);
  const setDialogContent = Recoil.useSetRecoilState(dialogContentState);
  const [error, setError] = useState(false);
  const [savePending, setSavePending] = useState(false);
  const [conflictsInState, setConflictsInState] = useState([]);

  const selectedLanguages = useMemo(() => _.filter(languages, l => filters[`lang_${l.code}`]), [filters]);
  const deselectedLanguages = useMemo(() => _.filter(languages, l => !filters[`lang_${l.code}`]), [filters]);
  const otherTranslations = useMemo(() => _.filter(translationsList, g => g.uuid !== uuid && g.groupId === groupId), [translationsList]);
  const payload = { productId, uuid };

  const onDelete = async () => {
    try {
      await localization.translation.delete({ productId, uuid });
      setTranslationsList(_.filter(translationsList, t => t.uuid !== uuid));
    } catch (e) {
      dev.error(e.response);
    }
  };

  const [localTranslation, dispatch] = useReducer((currentState, action) => {
    const state = JSON.parse(JSON.stringify(currentState)); // DON'T use spread, it'll inherit read-only attributes
    delete state.resolved;
    switch (action.type) {
      case 'keyword':
        if (_.findIndex(otherTranslations, t => cleanTranslation(t.keyword) === cleanTranslation(action.value)) !== -1) {
          setError('Duplicate keyword');
          setSavePending(false);
        } else {
          setError(false);
          setSavePending(true);
        }
        state.keyword = action.value;
        return state;

      case 'note':
        setSavePending(true);
        state.note = action.value;
        return state;

      case 'translation':
        setSavePending(true);
        state.translations[action.lang] = action.value;
        return state;

      case 'silentTranslations':
        state.translations = {
          ...state.translations,
          ...action.value,
        };
        return state;

      case 'resolutions':
        setSavePending(true);
        state.translations = {
          ...state.translations,
          ...action.value,
        };
        state.resolved = true;
        return state;

      case 'dateUpdated':
        state.dateUpdated = action.value;
        return state;

      default:
        return currentState;
    }
  }, {
    keyword: '',
    note: '',
    translations: [],
    ..._.find(translationsList, t => t.uuid === uuid),
  });

  const [callAPI] = useDebounce(async () => {
    if (!savePending || error) {
      dev.log('Skipping API Update');
      return;
    }

    dev.log('API Update ', localTranslation.keyword);
    setSavePending(false);

    try {
      const result = await localization.translation.update({
        payload,
        body: {
          groupId: localTranslation.groupId,
          keyword: _.trim(localTranslation.keyword),
          translations: _.pickBy(localTranslation.translations, t => t !== ''),
          note: localTranslation.note,
          dateUpdated: localTranslation.dateUpdated,
          resolved: localTranslation.resolved,
        },
      });

      if (result.success) {
        dispatch({ type: 'dateUpdated', value: result.item.dateUpdated });
        setConflictsInState([]);
      } else if (!result.success && result.error) {
        // Check if API returned an ItemOudated conflict error
        if (result.error.response.data.errorCode === 'ItemOutdated') {
          const newerDBItem = result.error.response.data.item;
          const conflicts = [];

          // Auto-update deselected languages:
          const silentUpdates = {};
          deselectedLanguages.forEach(l => {
            if (newerDBItem.translations[l.code] === undefined) return; // Ignore new entries
            silentUpdates[l.code] = newerDBItem.translations[l.code];
          });
          dispatch({
            type: 'silentTranslations',
            value: silentUpdates,
          });

          // For selected languages, build an array of conflicts to be resolved:
          selectedLanguages.forEach(l => {
            if (newerDBItem.translations[l.code] === undefined) return; // Ignore new entries
            if (localTranslation.translations[l.code] === '') return; // Ignore empty lines
            if (localTranslation.translations[l.code] === newerDBItem.translations[l.code]) return; // Only conflict changed values

            conflicts.push({
              language: l.code,
              left: localTranslation.translations[l.code],
              right: newerDBItem.translations[l.code],
              resolution: 'tbd',
            });
          });

          if (conflicts.length) {
            setConflictsInState(conflicts);
          } else {
            // Even though the item was outdated, if we're just changing the keyword or note, we may have 0 conflicts.
            // In such case, we just resolve automatically.
            dispatch({
              type: 'resolutions',
              value: {},
            });
          }
        }
      }
    } catch (e) {
      dev.error(e.response);
    }
  }, 800);

  // Shorthand to update conflicts in State
  const onPickResolution = (key, resolution) => () => {
    const conflicts = [...conflictsInState];
    conflicts[key].resolution = resolution;
    setConflictsInState(conflicts);
  };

  // Function displays left/right arrow depending on conflict resolution choice
  const showConflictArrow = (resolution) => {
    switch (resolution) {
      case 'left':
        return <ArrowLeftIcon className={classes.icon} />;
      case 'right':
        return <ArrowRightIcon className={classes.icon} />;
      default:
        return <SwapHorizIcon className={classes.icon} />;
    }
  };

  const onProcessResolutions = async () => {
    const resolutions = {};
    conflictsInState.forEach(c => {
      resolutions[c.language] = c[c.resolution];
    });

    dispatch({
      type: 'resolutions',
      value: resolutions,
    });
    setDialogVisibility(false);
  };

  // If localTranslation changes, update global state.
  // We could work with the global state directly, but decoupling is nice and gives more granular control over caching.
  useEffect(() => {
    setTranslationsList(translationsList.map(t => {
      if (t.uuid === uuid) {
        return {
          ...t,
          ...localTranslation,
        };
      }
      return t;
    }));
  }, [localTranslation]);

  // API is called when localTranslation changes as well, but the updates are debounced.
  useEffect(() => {
    callAPI();
  }, [localTranslation]);

  // Set conflict dialog if we have any
  useEffect(() => {
    if (!conflictsInState.length) return;

    setDialogContent({
      title: 'Conflict detected!',
      body: (
        <>
          <T p>
            The translation you&apos;re editing has been updated since it was loaded in the editor.
            Please select which copy that you would like to keep for each conflict.
          </T>
          <TableContainer>
            <Table className={classes.table} size="small" aria-label="Conflicts table">
              <TableHead>
                <TableRow>
                  <TableCell>Language</TableCell>
                  <TableCell>Your copy</TableCell>
                  <TableCell />
                  <TableCell>Database/newer copy</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {conflictsInState.map((conflict, key) => {
                  return (
                    <TableRow key={conflict.language} className={classes[conflict.resolution]}>
                      <TableCell>
                        {conflict.language}
                        {' '}
                        {key}
                      </TableCell>
                      <TableCell onClick={onPickResolution(key, 'left')}>{conflict.left}</TableCell>
                      <TableCell>{showConflictArrow(conflict.resolution)}</TableCell>
                      <TableCell onClick={onPickResolution(key, 'right')}>{conflict.right}</TableCell>
                    </TableRow>
                  );
                })}

              </TableBody>
            </Table>
          </TableContainer>
        </>
      ),
      buttons: (
        <Button
          variant="contained"
          onClick={onProcessResolutions}
          disabled={_.findIndex(conflictsInState, c => c.resolution === 'tbd') !== -1}
        >
          Save
        </Button>
      ),
    });
    setDialogVisibility(true);
  }, [conflictsInState]);


  // Memoize fields so that they don't uselessly update. Huge performance gains.
  const keywordField = useMemo(() => (
    <TextField
      outlined
      fullWidth
      margin="none"
      className={classes.textField}
      value={localTranslation.keyword}
      onChange={(e) => dispatch({
        type: 'keyword',
        value: e.currentTarget.value,
      })}
      error={!!error}
      helperText={error}
    />
  ), [localTranslation.keyword, error]);

  const noteField = useMemo(() => (
    <TextField
      outlined
      fullWidth
      margin="none"
      className={classes.textField}
      multiline
      value={localTranslation.note}
      onChange={(e) => dispatch({ type: 'note', value: e.currentTarget.value })}
    />

  ), [localTranslation.note]);

  const translationFields = useMemo(() => selectedLanguages.map(language => (
    <React.Fragment key={language.code}>
      <Grid item xs={1}>
        <T a="c">{language.keyword}</T>
      </Grid>
      <Grid item xs={twoLineEdit ? 5 : 11}>
        <TextField
          outlined
          fullWidth
          margin="none"
          className={classes.textField}
          value={localTranslation.translations[language.code] || ''}
          onChange={(e) => dispatch({ type: 'translation', lang: language.code, value: e.currentTarget.value })}
          key={language.code}
        />
      </Grid>
    </React.Fragment>
  )), [twoLineEdit, selectedLanguages, localTranslation.note, localTranslation.translations]);

  return (
    <Grid item xs={12} key={localTranslation.uuid} className={classes.translationItem}>
      <Grid container alignItems="flex-start" alignContent="center" spacing={2}>
        <Grid item xs={2}>
          <T>Keyword</T>
          {keywordField}
          <T>Notes</T>
          {noteField}
        </Grid>
        <Grid item xs={9}>
          <Grid container alignItems="center">
            { translationFields }
          </Grid>
        </Grid>
        <Grid item xs={1} className={classes.groupControls}>
          <DeleteForever onClick={isolate(onDelete)} className={classes.icon} />
        </Grid>
      </Grid>
    </Grid>
  );
}

TranslationItem.propTypes = {
  uuid: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
};

export default TranslationItem;
