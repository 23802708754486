import React, { useMemo } from 'react';
import Recoil from 'recoil';
import {
  languageState,
} from 'store/atoms';
import clsx from 'clsx';
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { TextField, Text, Button, Link } from 'components/UI';
import Confirmation from 'components/UI/Confirmation';
import logo2x from 'assets/images/logo-notag-black-2x.png';
import DevConsole from 'utils/DevConsole';
import { EmailEmpty, PasswordEmpty, NotAuthorized } from 'utils/errors';
import { LocalizationContext } from 'contexts/LocalizationProvider';
import useCustomSnackbar from 'hooks/useCustomSnackbar';
import useAuthentication from 'hooks/useAuthentication';
import useStyles from './styles';
import './styles.scss';

const dev = new DevConsole('SignUp');

/**
 * Sign Up Page
 *
 * @returns {React.component}
 */
const SignUp = () => {
  const classes = useStyles();
  const [showSnackbar, closeSnackbar] = useCustomSnackbar({
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
  });
  const authentication = useAuthentication();

  const [first, setFirst] = React.useState('');
  const [last, setLast] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showPassword, togglePassword] = React.useReducer((state) => !state, false);
  const [needConfirmation, setNeedConfirmation] = React.useState(false);
  const languages = Recoil.useRecoilValue(languageState);
  const { translations } = React.useContext(LocalizationContext);

  // TODO: Use Cookies for selected language.
  const language = languages[0].code;

  const onHandleSignup = async (event) => {
    event.preventDefault();

    authentication.setEmail(email);
    authentication.setPassword(password);

    const attributes = { name: first, family_name: last };
    dev.log(attributes);
    const signUp = await authentication.signUp(attributes);

    if (signUp.error === EmailEmpty) {
      showSnackbar(translations.signup.emailempty[language], { variant: 'warning' });
      return;
    }
    if (signUp.error === PasswordEmpty) {
      showSnackbar(translations.signup.passwordempty[language], { variant: 'warning' });
      return;
    }
    if (signUp.error === NotAuthorized) {
      showSnackbar(translations.signup.notauth[language], { preventDuplicate: false, variant: 'error' });
    }

    if (signUp.success) {
      setNeedConfirmation(true);
      showSnackbar(translations.signup.success[language], { variant: 'success' });
    }
  };

  const inputAdornment = useMemo(() => { // Hide and show icons for password field
    return (
      <InputAdornment position="end">
        <IconButton color="primary" onClick={togglePassword}>
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    );
  }, [showPassword]);

  const helperText = (text) => (
    <span className={classes.formLabel}>
      {translations.signup[text][language]}
    </span>
  );

  dev.log('render');

  if (needConfirmation) {
    return (
      <Confirmation
        email={email}
        setNeedConfirmation={setNeedConfirmation}
        snackbar={[showSnackbar, closeSnackbar]}
      />
    );
  }

  return (
    <div className={clsx(classes.root, 'poster')}>
      <div className={classes.gradient} />
      <Card className={classes.card}>
        <CardContent className={classes.flexContent}>
          <form id="SignUp" onSubmit={onHandleSignup}>
            <Grid container alignContent="flex-start" spacing={1}>
              <div className={classes.flexLogo}>
                <Grid item xs={12} lg={3}><img className={classes.logo} src={logo2x} alt="LOCI" /></Grid>
                <Grid item xs={12} lg={6}><Text className={classes.titleText}>{translations.signup.tagline[language]}</Text></Grid>
              </div>
              <Grid item xs={12} lg={6}>
                <TextField
                  name="firstname"
                  helperText={helperText('fname')}
                  handler={setFirst}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  name="lastname"
                  helperText={helperText('lname')}
                  handler={setLast}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  name="email"
                  autoComplete="new-email"
                  helperText={helperText('email')}
                  handler={setEmail}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  name="password"
                  helperText={helperText('password')}
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="new-password"
                  handler={setPassword}
                  InputProps={{ endAdornment: inputAdornment }}
                />
              </Grid>
            </Grid>
            <CardActions className={classes.flexActions}>
              <Link to="/signin" keyword="SignIn" title={translations.signup.cancel[language]} />
              <Button className={classes.signUp} type="submit" variant="contained">{translations.signup.signup[language]}</Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default SignUp;
