import { makeStyles } from '@material-ui/core/styles';
import bkg2x from 'assets/images/background-2x.png';

const useStyles = makeStyles(() => ({
  root: {
    backgroundImage: `url(${bkg2x})`,
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  gradient: {
    display: 'flex',
    width: 24,
    height: '100vh',
    backgroundImage: 'linear-gradient(0deg, #FF580F 0%, #D9FF31 50%, #004D26 100%)',
  },
  copyright: {
    position: 'absolute',
    right: 15,
    bottom: 10,
  },
  logo: {
    '& div': {
      display: 'inline-block',
      position: 'absolute',
      left: '0px',
    },
    '& div:nth-child(1)': {
      left: '0px',
    },
    '& div:nth-child(2)': {
      left: '40px',
    },
    '& div:nth-child(3)': {
      left: '105px',
    },
    '& div:nth-child(4)': {
      left: '165px',
    },
    display: 'inline-block',
    position: 'relative',
    width: 250,
    height: 100,
    margin: 'auto',
  },
  logoL: {
    opacity: 0,
    animation: '$logoL 10s infinite',
  },
  logoO: {
    opacity: 0,
    animation: '$logoO 10s infinite',
  },
  logoC: {
    opacity: 0,
    animation: '$logoC 10s infinite',
  },
  logoI: {
    opacity: 0,
    animation: '$logoI 10s infinite',
  },
  '@keyframes logoL': {
    '10%': {
      opacity: '0',
    },
    '15%': {
      opacity: '1',
    },
    '50%': {
      opacity: '1',
    },
    '65%': {
      opacity: '1',
    },
    '70%': {
      opacity: '0',
    },
  },
  '@keyframes logoO': {
    '20%': {
      opacity: '0',
    },
    '25%': {
      opacity: '1',
    },
    '50%': {
      opacity: '1',
    },
    '75%': {
      opacity: '1',
    },
    '80%': {
      opacity: '0',
    },
  },
  '@keyframes logoC': {
    '30%': {
      opacity: '0',
    },
    '35%': {
      opacity: '1',
    },
    '50%': {
      opacity: '1',
    },
    '85%': {
      opacity: '1',
    },
    '90%': {
      opacity: '0',
    },
  },
  '@keyframes logoI': {
    '40%': {
      opacity: '0',
    },
    '45%': {
      opacity: '1',
    },
    '50%': {
      opacity: '1',
    },
    '95%': {
      opacity: '1',
    },
    '100%': {
      opacity: '0',
    },
  },
}));

export default useStyles;
