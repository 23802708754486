import React from 'react';

/**
 * Show Component
 * If the "if" prop is true, or the "unless" prop is false, displays children.
 *
 * @param {object} props
 *
 * @returns {React.Component}
 */
const Show = (props) => {
  const condition = props.if || (props.unless !== undefined && !props.unless);
  const children = props.children || null;
  return condition ? children : null;
};

export default Show;
