import API from 'API';

const api = new API();
const service = 'tooltip';
const path = '/tooltip';

const tooltip = {
  get: payload => api.exec({
    action: 'get',
    service,
    path,
    payload,
  }),
  create: init => api.exec({
    action: 'post',
    service,
    path,
    init: {
      headers: init.headers,
      body: init.body,
    },
  }),
  update: mixedData => api.exec({
    action: 'put',
    service,
    path,
    payload: {
      productId: mixedData.payload.productId,
      elementId: mixedData.payload.elementId,
    },
    init: {
      headers: mixedData.headers,
      body: mixedData.body,
    },
  }),
};

export default tooltip;
