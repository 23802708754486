/* eslint-disable consistent-return */
import React from 'react';
import PropTypes from 'prop-types';
import getPercent from 'utils/getPercent';
import { LinearProgress, Grid, Box } from '@material-ui/core';
import { Text } from 'components/UI';
import DevConsole from 'utils/DevConsole';

const dev = new DevConsole('ProgressBar');

/**
 * Our progress bar subcomponent for the Dashboard
 *
 * @param {object} props
 * @param {Array} props.length - length of the guest data from API
 * @param {number} props.passed - number of passed game plays
 * @returns {React.Component}
 */
const ProgressBar = ({
  length,
  passed,
}) => {
  dev.log('Rendered');
  // hello
  const max = getPercent(passed, length, { abs: false, reverse: true, number: true });
  const [maxPercent, setMaxPercent] = React.useState(max);
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    dev.log({ maxPercent });
    const timer = setTimeout(() => {
      setProgress((oldProgress) => {
        dev.log({ oldProgress });
        if (oldProgress === maxPercent) {
          return maxPercent;
        }
        const diff = 1;
        return oldProgress > maxPercent ? oldProgress - diff : oldProgress + diff;
      });
    }, 25);
    setMaxPercent(getPercent(passed, length, { abs: false, reverse: true, number: true }));
    return () => {
      clearTimeout(timer);
    };
  }, [progress, passed, maxPercent]);

  return (
    <Grid container>
      <Grid item xs={10}>
        <Box
          style={{
            width: '100%',
            marginTop: '1.75rem',
            marginBottom: '0.75rem',
          }}
        >
          <LinearProgress
            variant="determinate"
            value={progress}
            style={{ height: '15px', borderRadius: '10px', backgroundColor: 'orange' }}
          />
          <section style={{ marginTop: '1.25rem' }}>
            <Text color="primary" variant="h6">Germ Safe Course Training Rank Based on Average Score</Text>
            <div style={{ width: '75%', display: 'flex', justifyContent: 'space-between' }}>
              <Text color="secondary" variant="h4" component="h4">ORANGE / FAIL</Text>
              <Text color="secondary" variant="h4" component="h4">
                {progress}
                %
              </Text>
            </div>
          </section>
        </Box>
      </Grid>
    </Grid>
  );
};

ProgressBar.propTypes = {
  length: PropTypes.number.isRequired,
  passed: PropTypes.number.isRequired,
};

export default ProgressBar;
