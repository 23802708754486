import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { TextField, Button, Text, IconButton } from 'components/UI';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardItem from './CardItem';
import HeaderCard from './Title';
import './styles.scss';

/**
 * New Card component
 *
 * @returns {React.Component}
 */
function NewCard() {
  const [location, setLocation] = useState('');
  const [type, setType] = useState('');
  const [size, setSize] = useState('');
  const [country, setCountry] = useState('');
  const [address, setAddress] = useState('');
  const [coordinates, setCoordinates] = useState('');

  const onHandleNew = async (event) => {
    event.preventDefault();
  };

  return (
    <Grid
      item
      sm={6}
      md={3}
    >
      <Card className="card-wrapper card-wrapper-new-location-form">
        <CardContent className="card-content">
          <Text className="new-card-title">New Location</Text>
          <form id="NewCardForm" onSubmit={onHandleNew}>
            <Grid item xs={12}>
              <TextField
                id="filled-name"
                label="Location"
                value={location}
                handler={setLocation}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="filled-name"
                label="Type"
                value={type}
                handler={setType}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="filled-name"
                label="Size"
                value={size}
                handler={setSize}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="filled-name"
                label="Country"
                value={country}
                handler={setCountry}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="filled-name"
                label="Address"
                value={address}
                handler={setAddress}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="filled-name"
                label="Coordinates"
                value={coordinates}
                handler={setCoordinates}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" onClick={onHandleNew}>SAVE</Button>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Grid>
  );
}

/**
 * Cards component
 *
 * @param {object} props
 * @returns {React.Component}
 */
function Cards(props) {
  const { title, addText, items } = props;
  const [open, setOpen] = useState(false);

  return (
    <div style={{ width: '100%' }}>
      <HeaderCard
        title={title}
        textAlign="right"
      />
      <Box
        pb={1}
        display="flex"
        flexDirection="row"
        className="add-btn-container"
      >
        <span className="locations-text">
          TOTAL LOCATIONS&nbsp;
          {items.length}
        </span>
        <IconButton
          icon="circle"
          iconPosition="left"
          text={addText}
          color="orange"
          size="medium"
          className="add-btn"
          onClick={() => setOpen(!open)}
        />
        <Box className="add-text">ADD ORGANIZATION</Box>
      </Box>
      {open}
      <Grid
        container
        spacing={3}
      >
        { open ? <NewCard /> : null }
        {items.map((value, index) => (
          <Grid
            key={index}
            sm={6}
            md={3}
            item
          >
            <CardItem
              item={value}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

Cards.propTypes = {
  title: PropTypes.string.isRequired,
  addText: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

export default Cards;
