import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    '& a': {
      textDecoration: 'none',
      fontWeight: 600,
      color: '#004D26',
      marginRight: '0.75rem',
    },
  },
  status: {
    padding: '0 5rem',
    width: '25vmin',
  },
  active: {
    '& svg': {
      backgroundColor: '#004D26',
    },
  },
  draft: {
    border: '1px solid #888276;',
    color: '#7C7568',
    backgroundColor: '#FBF9F5',
    '& svg': {
      fill: '#7C7568',
      backgroundColor: '#FBF9F5',
    },
  },
}));

export default useStyles;
