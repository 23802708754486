import DevConsole from 'utils/DevConsole';

const dev = new DevConsole('getLocation');

/**
 * Get Geolocation details
 *
 * @returns {object}
 */
function getLocation() {
  if (window.location.hostname === 'localhost') {
    // API denies calls from localhost
    dev.log('Skipping call from localhost');
    return false;
  }
  const apiKey = '28d4cb101aa478ec19c56321c877a6c6';
  const apiUrl = '//api.ipstack.com';
  return fetch('//api.ipgeolocation.io/getip')
    .then((response) => response.json())
    .then(data => {
      fetch(`${apiUrl}/${data.ip}?access_key=${apiKey}`)
        .then((response) => response.json())
        .then(res => {
          dev.log(res);
        });
    });
}

export {
  getLocation,
};
