import React from 'react';
import {
  Drawer,
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import TextEditor from 'components/UI/TextEditor';
import Text from 'components/UI/Text';

/**
 * Admin drawer component for editing content on any page that has editable content.
 *
 * @returns {React.component}
 */
const AdminDrawer = () => { // Possibly needs to be part of it's own Provider to render based on permissions
  const [show, setShow] = React.useState(true); // Local state for showing/hiding
  const classes = makeStyles(() => ({
    root: {
      width: 250,
    },
    drawerText: {
      cursor: 'pointer',
    },
  }))();
  const toggleDrawer = () => {
    setShow(!show);
  };
  const toggleEditor = (bool) => {
    if (bool) {
      return (
        <TextEditor />
      );
    }
    return bool;
  };
  const saveEdits = () => {
    return true;
  };
  return (
    <div
      className={classes.root}
    >
      <Drawer
        anchor="right"
        open={show}
        onClose={toggleDrawer}
      >
        <div>
          <List>
            <ListItem>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText>
                <Text
                  className={classes.drawerText}
                  color="primary"
                  variant="button"
                  onClick={toggleEditor}
                >
                  Edit
                </Text>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <SaveIcon />
              </ListItemIcon>
              <ListItemText>
                <Text
                  className={classes.drawerText}
                  color="primary"
                  variant="button"
                  onClick={saveEdits}
                >
                  Save
                </Text>
              </ListItemText>
            </ListItem>
          </List>
        </div>
      </Drawer>
    </div>
  );
};

export default AdminDrawer;
