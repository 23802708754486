import React, { useEffect, useState } from 'react';
import Recoil from 'recoil';
import useAuthentication from 'hooks/useAuthentication';
import { LocalizationContext } from 'contexts/LocalizationProvider';
import {
  organizationState,
  locationListState,
  manageLocationState,
} from 'store/atoms';
import { location } from 'API/services';
import _ from 'lodash';
import { Text } from 'components/UI';
import { Grid } from '@material-ui/core/';
import DevConsole from 'utils/DevConsole';

import addIcon from 'assets/images/icons/icon-add-circle-solid-orange.png';

import LocationDetail from './LocationDetail';
import LocationForm from './LocationForm';
import LocationDashboard from './LocationDashboard';
import LocationProfiles from './LocationProfiles';

import useStyles from './styles';

const dev = new DevConsole('Location').mute();

/**
 * Location Page
 *
 * @returns {React.Component}
 */
function Location() {
  const classes = useStyles();
  const authentication = useAuthentication({
    unauthenticatedRedirect: 'SignIn',
    egressRedirect: 'Home',
    group: 'Admins',
  });

  const [addLocation, setAddLocation] = useState(false);
  const currentOrganization = Recoil.useRecoilValue(organizationState);
  const [locationList, setLocationList] = Recoil.useRecoilState(locationListState);
  const [manageLocation, setManageLocation] = Recoil.useRecoilState(manageLocationState);

  const {
    languages,
    translations,
  } = React.useContext(LocalizationContext);

  // TODO: Use Cookies for selected language.
  const language = languages[0].code;

  useEffect(() => {
    setManageLocation(false); // use to to close the dashboard at start

    (async () => {
      dev.log('currentOrganization', currentOrganization);

      // ONLY set the Locations List if there is an organization set
      if (!_.isEmpty(currentOrganization)) {
        const result = await location.list({
          organizationId: currentOrganization.organizationId,
        });
        dev.log(`list result: ${JSON.stringify(result)}`);

        if (result.success) {
          setLocationList(result.items);
        }
      }
    })();

    // cleanup
    return () => {
      setLocationList({});
    };
  }, []);

  return authentication.restrictAccess(
    <div className={classes.root}>
      <div className={classes.header}>
        <Text className={classes.breadCrumb}>{`Organizations > ${currentOrganization.organizationName} > Locations`}</Text>
        <hr />
        {!manageLocation && (
          <div className={classes.titleContainer}>
            <div className={classes.title}>{translations.location.title[language]}</div>
            <div
              className={classes.addLocation}
              aria-hidden="true"
              onClick={() => setAddLocation(!addLocation)}
            >
              <img src={addIcon} alt="Add" />
              <Text>{translations.location.add[language]}</Text>
            </div>
          </div>
        )}
      </div>
      <div className={classes.clearFloat} />
      {!manageLocation && (
        <Grid container spacing={3}>
          {addLocation && <LocationForm setAddLocation={setAddLocation} />}
          {locationList.length > 0 && locationList.map((value, index) => {
            return (
              <Grid
                key={index}
                item
                sm={3}
              >
                <LocationDetail value={value} />
              </Grid>
            );
          })}
        </Grid>
      )}
      {manageLocation && (
        <>
          <LocationDashboard />
          <LocationProfiles />
        </>
      )}
    </div>,
  );
}

export default Location;
