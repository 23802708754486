import React from 'react';
import Recoil from 'recoil';
import PropTypes from 'prop-types';
import DevConsole from 'utils/DevConsole';
import {
  Table as MUITable,
  TableBody,
} from '@material-ui/core';
// recoil
import {
  profileDataState,
  lastEvalKey,
} from 'store/atoms';
import { decodeJSON64 } from 'utils/encoding';
// services
import { guest } from 'API/services';
// hooks
import useInfiniteLoading from 'hooks/useInfiniteLoading';
import useSort from 'hooks/useSort';
import Header from '../Header';
import Row from '../Row';
import useStyles from './styles';

const dev = new DevConsole('ProfilesTable');

/**
 * Guest Table
 *
 * @param {any} props
 * @param {Array} props.data - data from API
 * @returns {React.component}
 */
const Table = ({ profileData }) => {
  dev.log('Rendered');

  const [lastEval, setLastEval] = Recoil.useRecoilState(lastEvalKey);
  const [data, setData] = Recoil.useRecoilState(profileDataState);
  dev.log('LAST EVALUATED KEY', lastEval);

  const {
    sortedTable,
    handleSort,
    sortOrder,
    orderKey,
  } = useSort(profileData, 'completed');

  // Returning an altered data set with only the properties we need
  const noProps = profileData.map(({
    companyId,
    visible,
    answers,
    plays,
    selectedLang,
    ...rest
  }) => {
    return rest;
  });

  // css
  const classes = useStyles();

  // hooks
  const { setLastEl } = useInfiniteLoading(
    guest,
    'get',
    {
      companyId: 'LOCI',
      limit: 100,
      start: decodeJSON64(lastEval),
    },
    {
      data,
      setData,
      setLastEval,
    },
  );



  // Can't sort until all the items are loaded.
  return (
    <div>
      <MUITable className={classes.root}>
        <Header
          order={sortOrder}
          orderKey={orderKey}
          sort={handleSort}
          headerData={noProps[0]}
        />
        {lastEval ? (
          <TableBody>
            {data
              .map(item => {
                return (
                  <Row ref={setLastEl} data={item} key={item.guestId} actions={false} chips={false} />
                );
              })}
          </TableBody>
        ) : (
          <TableBody>
            {sortedTable
              .map(item => {
                return (
                  <Row ref={setLastEl} data={item} key={item.guestId} actions={false} chips={false} />
                );
              })}
          </TableBody>
        )}
      </MUITable>
    </div>
  );
};

Table.propTypes = {
  profileData: PropTypes.array.isRequired,
};

export default Table;
