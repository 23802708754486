import React from 'react';
import PropTypes from 'prop-types';
import {
  Popover, List, ListItem, ListItemAvatar, ListItemText,
} from '@material-ui/core';
import Icon from 'components/UI/Icon';
import { Text } from 'components/UI';
import useAuthentication from 'hooks/useAuthentication';
// import DevConsole from 'utils/DevConsole';

// const dev = new DevConsole('Header');

/**
 * AccountMenu
 *
 * @param {object} props
 * @param {boolean} props.open
 * @param {Function} props.setMenuOpen
 * @param {boolean} props.anchorEl
 * @param {Function} props.setAnchorEl
 * @returns {React.Component}
 */
function AccountMenu({ open, setMenuOpen, anchorEl, setAnchorEl }) {
  const authentication = useAuthentication();

  // Handlers
  const onUserMenuClose = () => {
    setMenuOpen(false);
    setAnchorEl(null);
  };
  const onHandleSignOut = async () => {
    await authentication.signOut();
    onUserMenuClose();
  };

  // Helpers
  const getNotifications = () => 0;

  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={onUserMenuClose}
      anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{ square: true }}
    >
      {/* Account Menu */}
      <List>
        <ListItem>
          <ListItemAvatar>
            <Icon position="right" badge={getNotifications()} />
          </ListItemAvatar>
          <ListItemText>{authentication.attributes.email}</ListItemText>
        </ListItem>
        <ListItem button onClick={onHandleSignOut}>
          <Text>Sign out</Text>
        </ListItem>
      </List>
    </Popover>

  );
}

AccountMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  setMenuOpen: PropTypes.func.isRequired,
  anchorEl: PropTypes.element,
  setAnchorEl: PropTypes.func.isRequired,
};

AccountMenu.defaultProps = {
  anchorEl: null,
};

export default AccountMenu;
