/**
 * Layout.Menu Component
 */
import React from 'react';
import Recoil from 'recoil';
import clsx from 'clsx';
import { menuState } from 'store/atoms';
import { useLocation } from 'react-router-dom';
import useAuthentication from 'hooks/useAuthentication';
import PropTypes from 'prop-types';
import useStyles from './styles';

/**
 * MenuPadding
 *
 * @param {object} props
 * @param {React.Component[]} props.children
 * @returns {React.Component}
 */
function MenuPadding({ children }) {
  const classes = useStyles();
  const authentication = useAuthentication();
  const [open, setOpen] = Recoil.useRecoilState(menuState);
  const location = useLocation();

  // When location changes:
  React.useEffect(() => {
    setOpen(false);
  }, [location,
    setOpen]);

  // const onClose = () => setOpen(false);
  return authentication.restrictAccess(
    <div
      className={clsx(
        { [classes.shift]: !open },
        { [classes.close]: open },
      )}
    >
      <div className={clsx(
        { [classes.backdrop]: !open },
      )}
      />
      {children}
    </div>,
  ) || children;
}

MenuPadding.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default MenuPadding;
