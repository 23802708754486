import Recoil from 'recoil';

const routingState = Recoil.atom({
  key: 'routingState',
  default: {
    routes: null,
  },
});

export default routingState;

export {
  routingState,
};
