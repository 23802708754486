import React from 'react';
import { RecoilRoot } from 'recoil';
import { SnackbarProvider } from 'notistack';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { CookiesProvider } from 'react-cookie';
import Loading from 'components/Layout/Loading';
import APIWrapper from 'components/APIWrapper';
import Router from 'components/Router';
import Button from 'components/UI/Button';
import Layout from 'components/Layout';
import DevConsole from 'utils/DevConsole';
import theme from 'assets/style/theme';
import './styles.scss';

const dev = new DevConsole('App');


/**
 * App Component
 *
 * Main component loaded from index.js entry point.
 * Sets up the theme, CSS, Cookies, Feature Flags, and other config for the app to use.
 * Note that the Router is responsible for loading routes.
 *
 * @returns {React.Component}
 */
function App() {
  const notistackRef = React.createRef();

  const onClickDismiss = key => {
    notistackRef.current.closeSnackbar(key);
  };

  dev.info('Render');

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RecoilRoot>
        <APIWrapper>
          <SnackbarProvider
            ref={notistackRef}
            action={(key) => (
              <Button variant="text" onClick={() => onClickDismiss(key)}>
                Dismiss
              </Button>
            )}
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <CookiesProvider>
              <React.Suspense fallback={<Loading box />}>
                <Router>
                  <Layout />
                </Router>
              </React.Suspense>
            </CookiesProvider>
          </SnackbarProvider>
        </APIWrapper>
      </RecoilRoot>
    </ThemeProvider>
  );
}

export default App;
