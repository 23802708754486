import { location } from 'API/services';
import _ from 'lodash';

import DevConsole from 'utils/DevConsole';

const dev = new DevConsole('getLocationByOrganization').mute();

const getLocationByOrganization = async orgId => {
  dev.log('orgId', orgId);

  // ONLY set the Locations List if there is an organization set
  if (!_.isEmpty(orgId)) {
    const result = await location.list({
      organizationId: orgId,
    });
    dev.log(`list result: ${JSON.stringify(result)}`);

    if (result.success) {
      return result.count;
    }
  }

  return 0;
};

export default getLocationByOrganization;
