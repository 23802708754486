import Recoil from 'recoil';

const translationState = Recoil.atom({
  key: 'translationState',
  default: {
    groups: [],
    items: [],
  },
});

const languageState = Recoil.atom({
  key: 'languageState',
  default: [],
});

const selectedLanguageState = Recoil.atom({
  key: 'selectedLanguageState',
  default: 'en',
});

export {
  translationState,
  languageState,
  selectedLanguageState,
};
