export default {
  get: {
    '/localization/general.loci': 'LOCI',
    '/localization': {
      general: {
        loci: 'LOCI',
        myloci: {
          en: 'My LOCI',
          es: 'Mi LOCI',
        },
      },
    },
  },
};
