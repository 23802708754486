import React from 'react';
import Recoil from 'recoil';
import {
  // Link,
  useHistory,
} from 'react-router-dom';
import { organizationState, manageOrganizationState } from 'store/atoms';
import {
  Text,
  // Button,
} from 'components/UI';
import clsx from 'clsx';

import gsiLogo from 'assets/images/organization/gsi_badge.png';
import closeIcon from 'assets/images/icons/icon-add-circle-solid-black.png';
import manageIcon from 'assets/images/icons/icon-arrow-circle-solid-orange.png';

import useStyles from './styles';


const OrganizationDashboard = () => {
  const classes = useStyles();
  const history = useHistory();

  const currentOrganization = Recoil.useRecoilValue(organizationState);
  const setManageOrganization = Recoil.useSetRecoilState(manageOrganizationState);

  const viewLocations = (e) => {
    e.preventDefault();
    history.replace('/location');
  };

  return (
    <div className={classes.dashboardContainer}>
      <div className={classes.dashboardContent}>
        <div className={classes.dashboardInfo}>
          {/* <div className={classes.dashboardLogo}>
            <img src={seaspanLogo} alt="Logo" />
          </div> */}
          <div className={classes.dashboardOrgName}>
            <Text>{currentOrganization.organizationName}</Text>
          </div>
          <div className={classes.dashboardCount}>
            <Text>Total Building Count 2</Text>
            <Text>Total Shipyard Count 1</Text>
          </div>
          <div className={classes.dashboardWarden}>
            <Text>SAFETY WARDEN</Text>
            <Text>Tiffany Holland</Text>
          </div>
          <div className={classes.dashboardReviewers}>
            <Text>REVIEWERS</Text>
            <Text>Samantha Chase</Text>
            <Text>Vanessa Dayton</Text>
            <Text>Armando Ceron</Text>
          </div>
          <div className={classes.dashboardBadge}>
            <div className={classes.badgeContainer}>
              <img src={gsiLogo} alt="GSI Logo" />
            </div>
          </div>
        </div>
        <div className={classes.dashboardStats}>
          <div className={classes.statsHeader}>
            <Text>ORGANIZATION DASHBOARD</Text>
          </div>
          <div className={classes.statsContent}>
            <div className={classes.contentLayer}>
              <div className={clsx(classes.stats, 'totalPlays')}>
                <Text>Total Plays</Text>
                <Text>7</Text>
              </div>
              <div className={clsx(classes.stats, 'totalLocations')}>
                <Text>Total Locations</Text>
                <Text>22</Text>
              </div>
              <div className={clsx(classes.stats, 'passed')}>
                <Text>Passed</Text>
                <Text>5</Text>
              </div>
            </div>
            <div className={classes.contentLayer}>
              <div className={clsx(classes.stats, 'totalCourse')}>
                <Text>Total Course Training Time</Text>
                <Text>125 hrs</Text>
              </div>
              <div className={clsx(classes.stats, 'failed')}>
                <Text>Failed</Text>
                <Text>8</Text>
              </div>
            </div>
            <div className={classes.contentLayer}>
              <div className={clsx(classes.stats, 'aveScore')}>
                <Text>Average Score</Text>
                <Text>20%</Text>
              </div>
              <div className={clsx(classes.stats, 'saved')}>
                <Text>$ Saved</Text>
                <Text>119K</Text>
              </div>
              <div className={clsx(classes.stats, 'trainingTime')}>
                <Text>Training Time Saved</Text>
                <Text>25 hrs</Text>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.dashboardButtons}>
          <div
            className={classes.closeDashboard}
            aria-hidden="true"
            onClick={() => setManageOrganization(false)}
          >
            <img src={closeIcon} alt="Close" />
          </div>
          <div className={classes.settings}>
            {/* <Button className={classes.dashboardBtn}>AUTOMATION SETTINGS</Button>
            <Button className={classes.dashboardBtn} component={Link} to="/reports">DOWNLOAD REPORT</Button> */}
          </div>
          <div
            className={classes.viewLocations}
            aria-hidden="true"
            onClick={viewLocations}
          >
            <Text>VIEW LOCATIONS</Text>
            <img src={manageIcon} alt="VIEW LOCATIONS" />
          </div>
        </div>
        <div className={classes.clearFloat} />
      </div>
    </div>
  );
};

export default OrganizationDashboard;
