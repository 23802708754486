import React from 'react';
import { Chip as MUIChip } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';

/**
 * Wrapper for Mui Chip component
 *
 * @param {any} props
 * @param {string} props.text
 * @param {React.component} props.icon
 * @param {string} props.size
 * @param {string} props.color
 * @param {string} props.bem
 * @param {object} props.class
 * @returns {React.component}
 */
function Chip({ color, text, icon, size, className }) {
  return (
    <MUIChip
      color={color}
      label={text}
      size={size}
      className={className}
      avatar={<Avatar>{icon}</Avatar>}
    />
  );
}

Chip.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.element,
  className: PropTypes.string,
  size: PropTypes.string,
};

Chip.defaultProps = {
  color: 'primary',
  text: 'Status',
  size: 'lg',
  className: '',
  icon: <SearchIcon />,
};

export default Chip;
