export default {
  get: {
    '/': [{
      uuid: '313e0d96-e678-4268-8b4f-a0ebafe5c464',
      name: 'TestFeature',
      description: 'Test feature',
      enabled: true,
      ratio: 1,
      props: {},
    },
    {
      uuid: '4e33154a-a840-4aee-8f1f-3c9a39878c2c',
      name: 'DisabledFeature',
      description: 'Disabled feature',
      enabled: false,
      ratio: 1,
      props: {},
    }],
  },
};
