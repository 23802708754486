import React from 'react';
import { getLocation } from 'utils/geolocation';
import useAuthentication from 'hooks/useAuthentication';
import useStyles from './styles';

/**
 * Home Page
 *
 * @returns {React.Component}
 */
function Home() {
  const classes = useStyles();
  const authentication = useAuthentication({
    unauthenticatedRedirect: 'SignIn',
    egressRedirect: 'Home',
    group: 'Admins',
  });

  getLocation();

  return authentication.restrictAccess(
    <div className={classes.root}>
      <h1>Home</h1>
    </div>,
  );
}

export default Home;
