/**
 * Value-sanitizing functions
 *
 */

/**
 * cleanTranslation
 *
 * Used to sanitize translation (group name, keyword) values.
 *
 * @param {string} str
 * @returns {string}
 */
function cleanTranslation(str) {
  return typeof str === 'string' ? str.trim().toLowerCase() : '';
}

export {
  cleanTranslation,
};
