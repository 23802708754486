import React from 'react';

/**
 * Hide Component
 * If the "if" prop is true, or the "unless" prop is false, hides children.
 *
 * @param {object} props
 *
 * @returns {React.Component}
 */
const Hide = (props) => {
  const condition = props.if || (props.unless !== undefined && !props.unless);
  const children = props.children || null;
  return condition ? null : children;
};

export default Hide;
