import React from 'react';
import PropTypes from 'prop-types';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircleOutlined';
import './styles.scss';

const StyledBadge = withStyles(theme => ({
  badge: {
    right: 7,
    top: 5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);

/**
 * Icon Component
 *
 * @param {object} props
 * @returns {React.Component}
 */
function Icon(props) {
  const { position, badge, ...rest } = props;
  return (
    <div className={position}>
      <IconButton
        aria-label="user"
        size="medium"
        {...rest}
      >
        <StyledBadge
          badgeContent={badge || 0}
          color="secondary"
        >
          <AccountCircle className="custom-icon" />
        </StyledBadge>
      </IconButton>
    </div>
  );
}

Icon.propTypes = {
  position: PropTypes.string,
  badge: PropTypes.number.isRequired,
};

Icon.defaultProps = {
  position: '',
};

export default Icon;
