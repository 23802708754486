import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { IconButton as MUIIconButton } from '@material-ui/core';

import AddCircle from '@material-ui/icons/AddCircle';
import ViewModule from '@material-ui/icons/ViewModule';
import List from '@material-ui/icons/List';
import Search from '@material-ui/icons/Search';
import Create from '@material-ui/icons/Create';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Forward from '@material-ui/icons/Forward';
import PermContactCalendar from '@material-ui/icons/PermContactCalendar';
import DragHandleIcon from '@material-ui/icons/DragHandle';

import './styles.scss';

const icons = {
  circle: <AddCircle />,
  grid: <ViewModule />,
  list: <List />,
  search: <Search />,
  edit: <Create />,
  delete: <DeleteForever />,
  arrow: <Forward />,
  profile: <PermContactCalendar />,
  drag: <DragHandleIcon />,
};

/**
 * IconButton Component
 *
 * @param {object} props
 * @returns {React.Component}
 */
function IconButton(props) {
  const { icon, text, iconPosition, size, color, ...rest } = props;

  const customProps = {
    className: color,
    size,
    ...rest,
  };
  let childIcon = null;
  let Component = Button;

  if (iconPosition === 'left') {
    customProps.startIcon = icons[icon];
  } else if (iconPosition === 'right') {
    customProps.endIcon = icons[icon];
  } else {
    customProps.text = null;
    childIcon = icons[icon];
    Component = MUIIconButton;
  }

  return <Component {...customProps}>{childIcon}</Component>;
}

IconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string,
  iconPosition: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
};

IconButton.defaultProps = {
  size: 'medium',
  text: null,
  color: null,
  iconPosition: null,
};

export default IconButton;
