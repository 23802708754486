import Recoil from 'recoil';

const accessKeyState = Recoil.atom({
  key: 'accessKeyState',
  default: [],
});

const akStartKey = Recoil.atom({
  key: 'akStartKey',
  default: '',
});

export {
  accessKeyState,
  akStartKey,
};
