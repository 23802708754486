/* eslint-disable react/prop-types */
import React from 'react';
import { TextField as MUITextField } from '@material-ui/core';

const TextField = React.forwardRef((props, ref) => {
  const {
    name,
    label,
    type,
    handler,
    blurHandler,
    defaultValue,
    value,
    helperText,
    error,
    outlined,
    fullWidth,
    onKeyPress,
    margin,
    ...rest
  } = props;
  const [val, setValue] = React.useState(defaultValue || '');
  const actualValue = value !== undefined ? value : val;
  const onChange = props.onChange ? props.onChange : (event) => {
    setValue(event.target.value);
    if (handler) {
      handler(event.target.value);
    }
  };

  const onBlur = (event) => {
    setValue(event.target.value);
    if (blurHandler) {
      blurHandler(event.target.value);
    }
  };

  return (
    <MUITextField
      ref={ref}
      variant={outlined ? 'outlined' : 'standard'}
      type={type || 'text'}
      fullWidth={fullWidth}
      id={name}
      name={name}
      label={label}
      helperText={helperText}
      error={error}
      value={actualValue}
      onChange={onChange}
      onBlur={onBlur}
      onKeyPress={onKeyPress}
      {...rest}
    />
  );
});

export default TextField;
