import { makeStyles } from '@material-ui/core/styles';
import bkg2x from 'assets/images/background-2x.png';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${bkg2x})`,
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    textTransform: 'uppercase',
    '& a': { // Forgot Password and Create Account links
      fontWeight: 'bold',
      color: '#004D26',
      marginRight: '1.5rem',
    },
  },
  gradient: {
    display: 'flex',
    width: 24,
    height: '100vh',
    backgroundImage: 'linear-gradient(0deg, #FF580F 0%, #D9FF31 50%, #004D26 100%)',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    margin: [[229, 0, 0, 200]],
    padding: 64 - 16, // CardContent has padding
    width: 464,
    height: 590,
    borderRadius: 25,
    boxShadow: '10px 13px 16px #00000029',
    textAlign: 'left',
    marginTop: 'calc(50vh - 295px)',

    [theme.breakpoints.down(800)]: {
      width: '100%',
      minWidth: 360,
      margin: [['5vmin', '5%']],
    },
  },
  flexLogo: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: 24,
  },
  flexContent: {
    display: 'flex',
    flexGrow: 1,
  },
  flexActions: {
    display: 'flex',
    flexGrow: 0,
  },
  logo: {
    width: 125,
    height: 56.66,
  },
  recovery: {
    fontWeight: 'bold',
  },
  remember: {
    marginLeft: -2,
    paddingLeft: 0,
  },
  agreeContainer: {
    marginTop: '-8%',
    display: 'flex',
    alignItems: 'center',
  },
  agree: {
    marginLeft: -2,
    paddingLeft: 0,
  },
  copyright: {
    position: 'absolute',
    right: 15,
    bottom: 10,
  },
  error: {
    margin: [[theme.spacing(2), 0]],
    color: '#e00',
  },
  disabled: {
    margin: [[theme.spacing(2), 0]],
    color: '#aaa',
  },

}));

export default useStyles;
