import React from 'react';
import DevConsole from 'utils/DevConsole';
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
// import { formatText } from 'utils/formatText';
import PropTypes from 'prop-types';
import Loading from 'components/Layout/Loading';

const dev = new DevConsole('Header');

/**
 * Header component for creating table Headers
 *
 * @param {object} props
 * @param {object} props.headerData - destructured data from Table
 * @param {string} props.order - asc or desc
 * @param {string} props.orderKey - column to be sorted
 * @param {Function} props.sort - state updater from useSort
 * @returns {React.Component}
 */
const Header = React.memo(({
  headerData,
  order,
  orderKey,
  sort,
}) => {
  dev.log('Rendered');
  // TODO: Let the user know whether or not their search returns any results
  if (!headerData) {
    return (
      <TableHead>
        <TableRow>
          <TableCell component="th" style={{ textAlign: 'center' }}>
            <Loading />
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const headerTags = Object.keys(headerData);
  dev.log(headerTags);
  // The order here matters, if the item isn't present or out of order undefined is returned which is what is causing the issue.
  const [
    completed,
    accessKey,
    dateCreated,
    grade,
    score,
    playThroughs,
    locationKey,
    course,
    progress,
    guestId,
    dateUpdated,
  ] = headerTags;

  return (
    // The order here doesn't really matter we have things arranged here how we want them to appear for our table
    <TableHead>
      <TableRow>
        <TableCell>{' '}</TableCell>
        <TableCell>
          <TableSortLabel
            active={orderKey === accessKey}
            direction={order}
            onClick={sort(accessKey)}
          >
            URL
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel
            active={orderKey === grade}
            direction={order}
            onClick={sort(grade)}
          >
            Grade
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel
            active={orderKey === score}
            direction={order}
            onClick={sort(score)}
          >
            Score
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel
            active={orderKey === locationKey}
            direction={order}
            onClick={sort(locationKey)}
          >
            Location
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel
            active={orderKey === course}
            direction={order}
            onClick={sort(course)}
          >
            Course
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel
            active={orderKey === dateCreated}
            direction={order}
            onClick={sort(dateCreated)}
          >
            Date
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel
            active={orderKey === progress}
            direction={order}
            onClick={sort(progress)}
          >
            Progress
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel
            active={orderKey === guestId}
            direction={order}
            onClick={sort(guestId)}
          >
            ID
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel
            active={orderKey === dateUpdated}
            direction={order}
            onClick={sort(dateUpdated)}
          >
            TTP
          </TableSortLabel>
        </TableCell>
        <TableCell>
          Profile
        </TableCell>
        <TableCell>
          <TableSortLabel
            active={orderKey === playThroughs.length}
            direction={order}
            onClick={sort(playThroughs.length)}
          >
            Plays
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel
            active={orderKey === completed}
            direction={order}
            onClick={sort(completed)}
          >
            Status
          </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  );
});

Header.propTypes = {
  headerData: PropTypes.object,
  order: PropTypes.string.isRequired,
  orderKey: PropTypes.string.isRequired,
  sort: PropTypes.func.isRequired,
};

Header.defaultProps = {
  headerData: undefined,
};

export default Header;
