/**
 * Authentication Class
 *
 * This class wraps around AWS Amplify's own Auth Class.
 *
 * Since Authentication uses AWS Cognito and not our own Serverless API, we can't use the same class
 * as our API, but this class ensures functionality is the same.
 *
 * Note: AWS Amplify is promise-based, so methods in this class asynchronous because we AWAIT
 * the results.
 *
 */

// eslint-disable-next-line no-restricted-imports
import { Auth as AmplifyAuth } from 'aws-amplify';
import sessionDefaults from 'config/sessionDefaults';
import DevConsole from 'utils/DevConsole';
import {
  EmailEmpty,
  EmailInvalid,
  PasswordEmpty,
  PasswordMismatch,
  PasswordInvalid,
  NotAuthenticated,
  ConfirmationEmpty,
} from 'utils/errors';
import { success, error } from 'utils/responses';

const dev = new DevConsole('Auth');

class Auth {
  constructor() {
    this.email = undefined;
    this.password = undefined;
    this.dev = new DevConsole('Auth');
    this.Auth = AmplifyAuth;
    this.user = {};
  }

  /**
   * setEmail
   *
   * Sets (and sanitizes) user email. Returns error if invalid.
   *
   * @param {string} value
   *
   * @returns {object}
   */
  setEmail(value) {
    const email = value.toLowerCase();
    // eslint-disable-next-line max-len
    const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

    if (typeof email !== 'string' || !email.length) {
      this.email = undefined;
      return error(EmailEmpty);
    }

    if (!emailRegExp.test(email)) {
      this.email = undefined;
      return error(EmailInvalid);
    }

    this.email = email;
    return success();
  }

  /**
   * setPassword
   *
   * Sets password. Checks for match validation.
   *
   * @param {string} value1
   * @param {string} [value2]
   * @returns {object}
   */
  setPassword(value1, value2) {
    if (typeof value1 !== 'string' || !value1.length) {
      this.password = undefined;
      return error(PasswordEmpty);
    }

    /**
     * Password Validation
     * The expression requires at least 1 lowercase letter, at least 1 uppercase letter, at least 1 number and must be 8 characters or longer
     */
    const passwordRegExp = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
    if (!passwordRegExp.test(value1)) {
      this.password = undefined;
      return error(PasswordInvalid);
    }

    if (value2 !== undefined && value1 !== value2) {
      this.password = undefined;
      return error(PasswordMismatch);
    }

    this.password = value1;
    return success();
  }

  /**
   * Get current authenticated user.
   * If it fails, returns defaults (guest session).
   *
   * @param {boolean} [bypassCache] - If true, bypass cache (always query server)
   */
  async getAuthenticatedUser(bypassCache = false) {
    try {
      this.user = await this.Auth.currentAuthenticatedUser({ bypassCache });
    } catch (err) {
      if (err === NotAuthenticated) {
        this.user = sessionDefaults;
      } else {
        dev.error(err);
        this.user = null;
      }
    }
  }

  /**
   * signIn
   *
   * Signs the user in.
   *
   * @returns {object}
   */
  async signIn() {
    this.dev.log('signIn');
    if (!this.email) {
      return error(EmailEmpty);
    }
    if (!this.password) {
      return error(PasswordEmpty);
    }
    try {
      this.user = await this.Auth.signIn({
        username: this.email,
        password: this.password,
      });
      this.dev.log('success');
      return success();
    } catch (err) {
      this.dev.log('error', err);
      return error(err.code);
    }
  }

  /**
   * signOut
   *
   * Signs the user out.
   *
   * @param {boolean} [global] - If true, signs out from ALL devices.
   *
   * @returns {object}
   */
  async signOut(global = false) {
    this.dev.log('signOut');
    try {
      await this.Auth.signOut({ global });
      this.user = sessionDefaults;
      return success();
    } catch (err) {
      return error(err.code);
    }
  }

  /**
   * signUp
   *
   * Signs the user up to user pool.
   * Optional attributes can be submitted. For custom attributes, make sure they
   * exist in Cognito first, then use the key "Custom:xxx" to assign them.
   *
   *
   * @param {object} attributes
   *
   * @returns {object}
   */
  async signUp(attributes = {}) {
    this.dev.log('signUp');
    this.dev.log(attributes);
    if (!this.email) {
      return error(EmailEmpty);
    }
    if (!this.password) {
      return error(PasswordEmpty);
    }
    try {
      this.user = await this.Auth.signUp({
        username: this.email,
        password: this.password,
        attributes,
      });
      return success();
    } catch (err) {
      return error(err.code);
    }
  }

  /**
   * confirmSignUp
   *
   * Checks validation code sent to user to confirm sign up.
   *
   * @param {string} code
   *
   * @returns {object}
   */
  async confirmSignUp(code) {
    if (!this.email || !code) {
      return error(ConfirmationEmpty);
    }
    try {
      this.dev.log('confirmSignUp', this.email);
      this.user = await this.Auth.confirmSignUp(this.email, code);
      return success();
    } catch (err) {
      return error(err.code);
    }
  }

  /**
   * resendConfirmationCode
   *
   * Resends the confirmation code email to validate sign up.
   *
   * @returns {object}
   */
  async resendConfirmationCode() {
    this.dev.log('resendConfirmationCode');
    this.dev.log('The email from resendConfirmationCode', this.email);
    if (!this.email) {
      return error(EmailEmpty);
    }
    try {
      this.user = await this.Auth.resendSignUp(this.email);
      return success();
    } catch (err) {
      return error(err.code);
    }
  }

  /**
   * isLoggedIn
   *
   * Checks if user is authenticated.
   *
   * @returns {Promise}
   */
  async isLoggedIn() {
    await this.getAuthenticatedUser();
    if (this.user.attributes.sub) {
      return true;
    }
    return false;
  }

  /**
   * isInGroup
   *
   * Checks if authenticated user is part of a given group
   *
   * @param {string|string[]} group - Group (or groups) to check against
   * @param {boolean} inclusive - If inclusive, must be in ALL groups, otherwise just one
   *
   * @returns {Promise}
   */
  async isInGroup(group, inclusive = false) {
    if (!group) {
      return false;
    }

    await this.getAuthenticatedUser();

    try {
      // Fail if not authenticated
      if (!this.user.signInUserSession) {
        return false;
      }

      const cognitoGroups = this.user.signInUserSession.idToken.payload['cognito:groups'];

      if (typeof cognitoGroups !== 'object' || !cognitoGroups.length) {
        return false;
      }

      // If we specify only one group
      if (typeof group === 'string') {
        return cognitoGroups.includes(group);
      }

      // If we specify multiple groups
      if (typeof group === 'object' && group.length) {
        const promises = [];

        group.forEach(async g => {
          promises.push(cognitoGroups.includes(g));
        });

        const result = await Promise.all(promises);

        return inclusive === true
          ? !result.includes(false)
          : result.includes(true);
      }
      return false;
    } catch (err) {
      this.dev.error(err);
      return false;
    }
  }
}
export default Auth;
