import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#C0CCFA',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#002EE2',
  },
}))(LinearProgress);

export default BorderLinearProgress;
