import React from 'react';
import Stepper from 'components/UI/Stepper';
import AdminDrawer from 'components/Layout/AdminDrawer';
import useAuthentication from 'hooks/useAuthentication';
import useStyles from './styles';

/**
 * Zones Page
 *
 * @returns {React.Component}
 */
function ZoneList() {
  const classes = useStyles();
  const authentication = useAuthentication({
    unauthenticatedRedirect: 'SignIn',
  });

  return authentication.restrictAccess(
    <div className={classes.root}>
      <h1>Zones</h1>
      <Stepper />
      <AdminDrawer />
    </div>,
  );
}

export default ZoneList;
