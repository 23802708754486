import React from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import {
  searchFilterState,
} from 'store/selectors';
import { guest } from 'API/services';
import {
  lastEvalKey,
  showUpButtonState,
  profileDataState,
} from 'store/atoms';
import { Search, Button } from 'components/UI';
import { Loading } from 'components/Layout';
import { Box } from '@mui/material';
import DevConsole from 'utils/DevConsole';

import { Table } from './ProfileTable';

import useStyles from './styles';


const dev = new DevConsole('LocationProfiles');


const LocationProfiles = () => {
  const classes = useStyles();

  // Recoil State
  const setProfileData = useSetRecoilState(profileDataState);
  const setLastEval = useSetRecoilState(lastEvalKey);
  const gdata = useRecoilValue(searchFilterState);
  const evalKey = useRecoilValue(lastEvalKey);
  const [showUp, setShowup] = useRecoilState(showUpButtonState);

  const handleScrolltop = () => {
    setShowup(false);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  React.useEffect(() => {
    (async () => {
      try {
        const result = await guest.get({
          companyId: 'LOCI',
          limit: 100,
        });
        if (result.success) {
          setProfileData(result.items);
          setLastEval(result.start);
        }
      } catch (error) {
        dev.log({ errCode: error });
      }
    })();
    return () => {
      setProfileData([]);
    };
  }, []);

  return (
    <Box className={classes.profilesContainer}>
      <Search />
      <Table
        profileData={gdata}
        actions={false}
        chips={false}
      />
      {showUp ? (
        <Button
          style={{
            position: 'fixed',
            bottom: '5%',
            right: '5%',
            backgroundColor: '#004D26',
            color: '#FFFFFF',
          }}
          onClick={handleScrolltop}
        >
          Back To Top
        </Button>
      ) : null}
      {evalKey ? (
        <section className={classes.infLoading}>
          <Loading />
        </section>
      ) : null}
    </Box>
  );
};

export default LocationProfiles;
