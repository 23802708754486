import React from 'react';
import {
  Tooltip,
} from '@mui/material';
import DevConsole from 'utils/DevConsole';
import PropTypes from 'prop-types';
// Recoil
import Recoil from 'recoil';
import {
  toggleTipState,
  translationState,
} from 'store/atoms';
import TipTitle from './TipTitle';

const dev = new DevConsole('Tooltip').mute();

/**
 * MUI tool tip component can be used anywhere we need extra information
 * about a UI element to be communicated to a user.
 *
 * @param {*} props
 * @param {React.Component} props.children - any children we wrap the tool tip around
 * @param {string} props.elementId - unique id to identify what has a tooltip
 * @returns {React.Component}
 */
const Tip = ({ elementId, children, text, ...props }) => {
  dev.log('Rendered');
  // Recoil
  const toggleTip = Recoil.useRecoilValue(toggleTipState);
  const translations = Recoil.useRecoilValue(translationState);

  // local state
  const [open, setOpen] = React.useState(false);

  const onHoverMouseEnter = () => {
    setOpen(true);
  };

  const onHoverMouseLeave = () => {
    setOpen(false);
  };

  dev.group('TIPSTATE');
  dev.ungroup();

  return toggleTip ? (
    <Tooltip
      onMouseEnter={() => onHoverMouseEnter()}
      onMouseLeave={() => onHoverMouseLeave()}
      open={open}
      id={elementId}
      arrow
      placement="top-start"
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: '#EFEFEF',
          },
        },
        arrow: {
          sx: {
            color: '#EFEFEF',
          },
        },
      }}
      text={text}
      title={
        (
          <TipTitle
            id={elementId}
            tips={translations.items}
            text={text}
          />
        )
      }
      {...props}
    >
      <span>
        {children}
      </span>
    </Tooltip>
  ) : (
    <>
      {children}
    </>
  );
};

Tip.propTypes = {
  children: PropTypes.node.isRequired,
  elementId: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Tip;
