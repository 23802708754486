/**
 * getEnv()
 *
 * Returns which environment we're in.
 *
 * @returns {string} localhost, prod or dev.
 *
 */
export default function getEnv() {
  // For unit tests:
  if (process.env.NODE_ENV === 'test') {
    return 'test';
  }
  if (process.env.NODE_ENV === 'development') {
    return 'localhost';
  }
  // Look anywhere in hostname for now, i.e. bucket name is "my.loci.dev.s3-website-us-east-1.amazonaws.com"
  if (window.location.hostname.search(/loci\.dev/) === -1) {
    return 'prod';
  }
  return 'dev';
}
