/**
 * Localization Provider
 *
 * This component provides Localization for the app.
 * It loads the languages, and then all translations for those languages.
 *
 * Note that while available languages are hardcoded, translations are loaded from the API.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { localization } from 'API/services';
import languages from 'config/languages';
import defaultTranslations from 'config/translations';

const LocalizationContext = React.createContext();

/**
 * Loads server-side localization config on top of local defaults:
 *
 * @returns {Promise} Localization object
 */
async function loadLocalization() {
  const APITranslations = await localization.translation.list({
    productId: 'MYLOCI',
  });

  return {
    ...APITranslations,
    data: {
      languages,
      translations: {
        ...defaultTranslations,
        ...APITranslations.data,
      },
    },
  };
}

/**
 * Localization Provider
 *
 * @param {object} props
 * @param {object} props.value
 * @param {React.Component[]} props.children
 *
 * @returns {React.Component}
 */
function LocalizationProvider({ value, children }) {
  return (
    <LocalizationContext.Provider value={value}>
      {children}
    </LocalizationContext.Provider>
  );
}

LocalizationProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  value: PropTypes.shape({
  }).isRequired,
};

export {
  loadLocalization, // Exporting for Unit Testing
  LocalizationContext,
  LocalizationProvider,
};
