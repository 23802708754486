import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { TextField, Button, Text, IconButton } from 'components/UI';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardItem from './CardItem';
import HeaderCard from './Title';

import './styles.scss';

/**
 * New Card component
 *
 * @returns {React.Component}
 */
function NewCard() {
  const [name, setName] = useState('');

  const onHandleNew = async (event) => {
    event.preventDefault();
  };

  return (
    <Grid
      item
      sm={6}
      md={3}
    >
      <Card className="card-wrapper card-wrapper-new-form">
        <CardContent className="card-content">
          <Text className="new-card-title">New Organization</Text>
          <form id="NewCardForm" onSubmit={onHandleNew}>
            <Grid container spacing={2} alignContent="flex-start">
              <Grid item xs={12}>
                <Button
                  color="default"
                  variant="contained"
                  component="label"
                >
                  Upload Logo
                  <input
                    type="file"
                    hidden
                  />
                </Button>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="filled-name"
                  label="Name"
                  value={name}
                  handler={setName}
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" onClick={onHandleNew}>SEND</Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Grid>
  );
}

/**
 * Cards component
 *
 * @param {object} props
 * @returns {React.Component}
 */
function Cards(props) {
  const { title, addText, items } = props;
  const [open, setOpen] = useState(false);

  return (
    <div style={{ width: '100%' }}>
      <HeaderCard
        title={title}
        textAlign="right"
      />
      <Box
        pb={1}
        display="flex"
        flexDirection="row"
        className="add-btn-content"
      >
        <IconButton
          icon="circle"
          iconPosition="left"
          text={addText}
          color="orange"
          size="medium"
          className="add-btn"
          onClick={() => setOpen(!open)}
        />
        <Box className="add-text">ADD ORGANIZATION</Box>
      </Box>
      <Grid
        container
        spacing={3}
      >
        { open ? <NewCard /> : null }
        {items.map((value, index) => (
          <Grid
            key={index}
            item
            sm={6}
            md={3}
          >
            <CardItem
              totalBuildingCount={value.totalBuildingCount}
              totalShipCount={value.totalShipCount}
              image={value.image}
              logo={value.logo}
              name={value.name}
              idOrganization={value.idOrganization}
              typeBuilding={value.typeBuilding}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

Cards.propTypes = {
  title: PropTypes.string.isRequired,
  addText: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

export default Cards;
