import React from 'react';
import { Box } from '@material-ui/core';
import { Text } from 'components/UI';
import PropTypes from 'prop-types';
import l2x from 'assets/images/logo-letters/L.png';
import o2x from 'assets/images/logo-letters/O.png';
import c2x from 'assets/images/logo-letters/C.png';
import i2x from 'assets/images/logo-letters/I.png';

import clsx from 'clsx';
import useStyles from './styles';

/**
 * Layout.Loading Component
 *
 * @param {any} props
 * @param {boolean} props.box - if we want to render the icon alone
 * @param {object} ref - forwarding the ref to where we need it
 *
 * @returns {React.Component}
 */
const Loading = React.forwardRef((props, ref) => {
  // props
  const {
    box,
  } = props;

  // custom css
  const classes = useStyles();

  return box ? (
    <div className={clsx(classes.root, 'poster')}>
      <div className={classes.gradient} />
      <Box
        ref={ref}
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        width="100vw"
      >
        <div className={classes.logo}>
          <div className={classes.logoL}><img src={l2x} alt="L" /></div>
          <div className={classes.logoO}><img src={o2x} alt="O" /></div>
          <div className={classes.logoC}><img src={c2x} alt="C" /></div>
          <div className={classes.logoI}><img src={i2x} alt="I" /></div>
        </div>
      </Box>
      <Text className={classes.copyright}>LOCI &copy; COPYRIGHT 2021</Text>
    </div>
  ) : (
    <div ref={ref} className={classes.logo}>
      <div className={classes.logoL}><img src={l2x} alt="L" /></div>
      <div className={classes.logoO}><img src={o2x} alt="O" /></div>
      <div className={classes.logoC}><img src={c2x} alt="C" /></div>
      <div className={classes.logoI}><img src={i2x} alt="I" /></div>
    </div>
  );
});

Loading.propTypes = {
  box: PropTypes.bool,
};

Loading.defaultProps = {
  box: false,
};

export default Loading;
