import React, { useEffect, useState } from 'react';
import Recoil from 'recoil';
import {
  translationState,
  languageState,
  featuresState,
  lastEvalKey,
  organizationListState,
  organizationState,
} from 'store/atoms';
import _ from 'lodash';
import API from 'API';
import {
  feature,
  localization,
  organization,
} from 'API/services';
import { LocalizationProvider, loadLocalization } from 'contexts/LocalizationProvider';
import Loading from 'components/Layout/Loading';
import DevConsole from 'utils/DevConsole';
import cachedLanguages from 'config/languages';
import './styles.scss';

const APP_ID = 'MYLOCI';

const api = new API();
const dev = new DevConsole('APIWrapper');

/**
 * APIWrapper Component
 *
 * Centralizes all API fetches.
 *
 * @param {object} props
 * @param {React.Component[]} props.children
 *
 * @returns {React.Component}
 */
function APIWrapper(props) {
  const { children } = props;
  const setFeatures = Recoil.useSetRecoilState(featuresState);
  const setTranslations = Recoil.useSetRecoilState(translationState);
  const setLanguages = Recoil.useSetRecoilState(languageState);
  const currentOrganization = Recoil.useRecoilValue(organizationState);
  const setOrganizationList = Recoil.useSetRecoilState(organizationListState);
  const [oldLocalization, setOldLocalization] = useState({ data: null });
  const [initalizing, setInitializing] = useState(true);

  // Asynchronously load data from server:
  // TODO: Benchmark synchronous to see if better.
  useEffect(() => {
    (async () => {
      api.configure();

      // Old Context+API code:
      setOldLocalization(await loadLocalization());

      // New Recoil+API awesomesauce:
      const APIFeatures = await feature.list({ productId: APP_ID });
      const APIGroups = await localization.group.list({ productId: APP_ID });
      const APITranslations = await localization.translation.list({ productId: APP_ID });
      const APILanguages = await localization.language.list({
        productId: APP_ID,
        active: true,
      });
      const APIOrganizations = await organization.list({
        active: 'true',
        organizationId: currentOrganization ? currentOrganization.organizationId : null,
      });

      // Local Feature defs are overwritten, not merged, so don't set if empty
      if (APIFeatures.count) {
        setFeatures(APIFeatures.items);
      }
      // Promise.all();
      setTranslations({
        groups: APIGroups.items || [],
        items: APITranslations.items || [],
      });
      setLanguages(_.orderBy(APILanguages.items, ['listOrder']) || cachedLanguages);
      setOrganizationList(APIOrganizations.items);
      setInitializing(false);
    })();
  }, [lastEvalKey]);

  if (initalizing || !oldLocalization.data) {
    dev.info('Loading...');
    return (
      <Loading box />
    );
  }
  dev.info('Render');
  return (
    <LocalizationProvider value={oldLocalization.data}>
      {children}
    </LocalizationProvider>
  );
}

export default APIWrapper;
