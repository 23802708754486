/* eslint-disable dot-notation */
import React from 'react';
import Recoil from 'recoil';
import {
  showUpButtonState,
} from 'store/atoms';
import DevConsole from 'utils/DevConsole';
import convertAPIDataToRows from 'utils/convertAPIData';

const dev = new DevConsole('useInfiniteLoading');
dev.mute();

/**
 * This hook takes advantage of the global lastEvalKey state.
 * The last evaluated key is how dynamoDB remembers where it left off
 * and enable further queries until the value returned from a query
 * is null. At this point there are no more items left to return and pagination
 * stops.
 *
 * @param {object} options - optional params for setting state and declaring params in our queries
 *
 * @returns {object}
 */
const useInfiniteLoading = (options) => {
  dev.log('useInfiniteLoading');
  dev.log(options);
  const setShowup = Recoil.useSetRecoilState(showUpButtonState);

  // local
  const [lastEl, setLastEl] = React.useState(null);
  const [pageNum, setPageNum] = React.useState(1);

  dev.log(pageNum);

  const addLoadedRows = (apiData, result) => {
    const all = new Set([...apiData, ...result.items]);
    convertAPIDataToRows({
      service: options.serviceName,
      stateUpdater: options.state.setData,
      rowsToClean: Array.from(all),
    });
  };

  const loadItems = async () => {
    if (!options.payload.start) return;
    try {
      const result = await options.service[options.method]({ ...options.payload });

      if (result.success) {
        options.state.setLastEval(result.start);
        addLoadedRows(options.state.data, result);
      }
    } catch (error) {
      dev.log({ errCode: error });
    }
  };

  const loadingRef = React.useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPageNum(prevState => prevState + 1);
      }
    }),
  );

  React.useEffect(() => {
    loadItems();
  }, [pageNum]);


  React.useEffect(() => {
    const currentEl = lastEl;
    const currentObserver = loadingRef.current;
    if (currentEl) {
      setShowup(true);
      currentObserver.observe(currentEl);
    }
    return () => {
      if (currentEl) {
        currentObserver.unobserve(currentEl);
      }
    };
  }, [lastEl]);


  return {
    // loadItems,
    pageNum,
    loadingRef,
    lastEl,
    setLastEl,
    setPageNum,
  };
};

export default useInfiniteLoading;
