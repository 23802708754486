export default {
  get: {
    '/guest/mock-id': {
      // TBD
    },
    '/guest': [
      // TBD
    ],
  },
};
