import React from 'react';
import { searchState, prevSearchState } from 'store/atoms';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { Autocomplete, InputAdornment } from '@mui/material';
import DevConsole from 'utils/DevConsole';
import SearchIcon from '@material-ui/icons/Search';
import { Text, TextField } from 'components/UI';
import './styles.scss';


const dev = new DevConsole('Search');
dev.mute();
/**
 * Search
 * A user needs this to filter entries in the table
 * Manages state and sends that request to an API that will return matched results OR
 * Does filtering on the client side to only show matched search terms.
 *
 * @returns {React.component}
 */
const Search = () => {
  dev.log('Rendered');
  // [TODO]: CUSTOM SEARCH HOOK?
  const searchTerm = useRecoilValue(searchState);
  const [prevSearch, setPrevSearch] = useRecoilState(prevSearchState);
  const setSearchTerm = useSetRecoilState(searchState);

  dev.log(prevSearch);

  const onChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const onBlur = (e) => {
    setSearchTerm(e.target.value);
    setPrevSearch(prevState => {
      return prevState.add(searchTerm);
    });
  };

  const onFocus = (e) => {
    setSearchTerm(e.target.value);
    setPrevSearch(prevState => {
      return prevState.add(searchTerm);
    });
  };


  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Autocomplete
        value={searchTerm} // Controlled component
        sx={{ width: '25%', mb: 1 }}
        freeSolo // Specific for searching with AutoComplete
        disableClearable
        options={Array.from(prevSearch)}
        renderInput={(params) => (
          <TextField
            {...params}
            outlined
            label="Search"
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            InputProps={{
              ...params.InputProps,
              type: 'search',
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        )}
      />
      <Text style={{ marginLeft: '1.25rem' }} color="primary" variant="body2">Click on column title to sort view</Text>
    </div>
  );
};

export default Search;
