import getTimeElapsed from './getTimeElapsed';

/**
 * Returns the average time in total for a given set of data.
 *
 * @param {Array} arr - game related data from the API
 * @returns {string}
 */
const getAvgTime = (arr) => {
  // [TODO]: hasOwnProperty checks for missing data fields
  const mapMinutes = arr.map(item => {
    const minutes = getTimeElapsed(item.dateCreated, item.dateUpdated, 'minutes');
    return minutes;
  }).filter(time => time !== 0);

  const mapSeconds = arr.map(item => {
    const seconds = getTimeElapsed(item.dateCreated, item.dateUpdated, 'seconds');
    return seconds;
  }).filter(time => time !== 0);

  const avgMinutes = (mapMinutes.reduce((a, b) => a + b) / mapMinutes.length).toFixed();
  const avgSeconds = (mapSeconds.reduce((a, b) => a + b) / mapSeconds.length).toFixed();

  return `${avgMinutes}m ${avgSeconds}s`;
};

export default getAvgTime;
