/* eslint-disable react/prop-types */
/* eslint-disable no-plusplus */
import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import API from 'API';
import Recoil from 'recoil';
import bucketName from 'config/bucketName';
import {
  organizationState,
  locationState,
  manageLocationState,
} from 'store/atoms';
import _ from 'lodash';
import { Text, TextField } from 'components/UI';
import { location } from 'API/services';
import useDebounce from 'hooks/useDebounce';
import useCustomSnackbar from 'hooks/useCustomSnackbar';
import DevConsole from 'utils/DevConsole';

import editIcon from 'assets/images/icons/icon-open-pencil.png';
import manageIcon from 'assets/images/icons/icon-arrow-circle-solid-orange.png';
import closeIcon from 'assets/images/icons/icon-close-circle.png';
import uploadIcon from 'assets/images/icons/icon-metro-file-upload.png';

import useStyles from './styles';

const dev = new DevConsole('LocationDetail').mute();
const api = new API();

const LocationDetail = ({ value }) => {
  const classes = useStyles();
  const [showSnackbar] = useCustomSnackbar();

  const uploadLogoRef = useRef();
  const formFieldRef = useRef([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isReUpload, setIsReUpload] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [uploadedThumb, setUploadedThumb] = useState({});
  const currentOrganization = Recoil.useRecoilValue(organizationState);
  const [currentLoc, setCurrentLoc] = useState({});
  const setLoc = Recoil.useSetRecoilState(locationState);
  const setManageLocation = Recoil.useSetRecoilState(manageLocationState);

  /**
   * updateLocation
   * Updates the location data based on the locationId provided to the function
   *
   * @param {string} name - the name to replace the DB copy
   * @param {boolean} address - the address to replace the DB copy
   * @param {boolean} lociManager - the lociManager to replace the DB copy
   * @param {string} safetyManager - the safetyManager to replace the DB copy
   * @param {string} locationId - the locationId for the location to be updated
   */
  const [updateLocation] = useDebounce(async (inputs, addrObj) => {
    try {
      const values = {
        payload: {
          organizationId: currentLoc.organizationId,
          locationId: currentLoc.locationId,
        },
        body: {
          locationName: inputs.locationName,
          locationType: inputs.locationType,
          locationSize: inputs.locationSize,
          address: addrObj,
          gpsCoordinates: inputs.gpsCoordinates,
          thumbnail: !_.isEmpty(uploadedThumb) ? uploadedThumb : null,
        },
      };
      dev.log(`values: ${JSON.stringify(values)}`);
      const result = await location.update(values);
      dev.log(`update result: ${JSON.stringify(result)}`);
      if (result.success) {
        showSnackbar('Location is updated successfully!', { preventDuplicate: true, variant: 'success' });
        setIsEdit(false);
        setCurrentLoc(result.item);
      }
    } catch (e) {
      dev.error(e.response);
    }
  }, 500);

  const handleUpdate = (e) => {
    e.preventDefault();
    dev.log('updating...');
    const inputs = [];
    const addrObj = {};
    for (let i = 0; i < formFieldRef.current.length; i++) {
      dev.log('current', formFieldRef.current[i]);
      if (formFieldRef.current[i] && formFieldRef.current[i].id) {
        if (formFieldRef.current[i].id.includes('addr_')) {
          const addrKey = formFieldRef.current[i].id.split('addr_');
          addrObj[addrKey[1]] = formFieldRef.current[i].value;
        } else {
          inputs[formFieldRef.current[i].id] = formFieldRef.current[i].value;
        }
      }
    }
    dev.log('inputs', inputs);
    dev.log('addrObj', addrObj);
    updateLocation(inputs, addrObj);
  };

  const setLocation = (isManage) => {
    setLoc(currentLoc);

    // only call the setManageOrg if its true otherwise skip
    if (isManage) {
      setManageLocation(isManage);
    }
  };

  const handleClick = () => {
    uploadLogoRef.current.click();
  };

  const handleUpload = async (e) => {
    dev.log('handleUpload e', e);
    dev.log('uploadLogoRef: ', uploadLogoRef);
    const file = uploadLogoRef.current.files[0];
    const originalFileName = file.name;
    const fileType = file.type;
    const uniqueFileName = uuidv4(); // we use this to avoid issues with same originalFileName
    const folder = 'thumbnail';
    try {
      const image = await api.storage.put(`${folder}/${uniqueFileName}`, file, {
        level: 'public',
        contentType: fileType,
      });
      dev.log('image', image);

      if (!_.isEmpty(image)) {
        setIsUploaded(true);
        setUploadedThumb({
          folder,
          originalFileName,
          fileType,
          uniqueFileName,
        });
      }
    } catch (error) {
      dev.log('Error uploading file: ', error);
    }
  };

  useEffect(() => {
    dev.log(`value: ${JSON.stringify(value)}`);
    setCurrentLoc(value);

    // cleanup
    return () => {
      setCurrentLoc({});
    };
  }, []);

  return (
    <>
      {!isEdit && (
        <div
          className={classes.locationBorder}
          aria-hidden="true"
          onClick={() => { setLocation(); }}
        >
          <div className={classes.locationContainer}>
            <div className={classes.locationThumbContainer}>
              {!_.isEmpty(currentLoc.thumbnail) && (
                <img
                  src={`https://${bucketName.bucket}/public/${currentLoc.thumbnail.folder}/${currentLoc.thumbnail.uniqueFileName}`}
                  key={`https://${bucketName.bucket}/public/${currentLoc.thumbnail.folder}/${currentLoc.thumbnail.uniqueFileName}`}
                  alt="Thumbnail"
                  className={classes.locationThumb}
                />
              )}
            </div>
            <div className={classes.locationWrapper}>
              <div className={classes.locationHeader}>
                {!_.isEmpty(currentOrganization.logo) && (
                  <div className={classes.locationOrgLogo}>
                    <img
                      src={`https://${bucketName.bucket}/public/${currentOrganization.logo.folder}/${currentOrganization.logo.uniqueFileName}`}
                      key={`https://${bucketName.bucket}/public/${currentOrganization.logo.folder}/${currentOrganization.logo.uniqueFileName}`}
                      alt="Logo"
                    />
                  </div>
                )}
                {_.isEmpty(currentOrganization.logo) && <Text>{currentOrganization.organizationName}</Text>}
              </div>
              <div className={classes.locationInfo}>
                <Text className={classes.locationTitle}>{`Location: ${currentLoc.locationName}`}</Text>
                <Text>{`Type: ${currentLoc.locationType}`}</Text>
                <Text>{`Size: ${currentLoc.locationSize}`}</Text>
                <Text>
                  {
                    currentLoc.address && !_.isEmpty(currentLoc.address.streetAddress)
                      ? `Address: ${currentLoc.address.streetAddress},
                      ${currentLoc.address.city}, ${currentLoc.address.state} ${currentLoc.address.zip}
                      ${currentLoc.address.country}` : ''
                  }
                </Text>
                <Text>{`Coordinates: ${currentLoc.gpsCoordinates}`}</Text>
              </div>
            </div>
            <div className={classes.buttonContainer}>
              <img
                src={editIcon}
                alt="Edit"
                onClick={() => {
                  setIsEdit(!isEdit);
                  setIsReUpload(false);
                  setIsUploaded(false);
                  setUploadedThumb({});
                }}
                aria-hidden="true"
              />
              <div
                className={classes.manageButton}
                aria-hidden="true"
                onClick={() => { setLocation(true); }}
              >
                <Text>MANAGE</Text>
                <img src={manageIcon} alt="Manage" />
              </div>
            </div>
          </div>
        </div>
      )}
      {isEdit && (
        <div className={classes.locationBorderForm}>
          <div className={classes.locationContainer}>
            <div className={classes.locationThumbContainerForm}>
              <div className={classes.locationHeaderForm}>
                {isReUpload && !isUploaded && (
                  <div>
                    <input
                      ref={uploadLogoRef}
                      accept="image/*"
                      type="file"
                      onChange={handleUpload}
                      className={classes.hiddenClass}
                    />
                    <div
                      className={classes.uploadButton}
                      aria-hidden="true"
                      onClick={handleClick}
                    >
                      <img src={uploadIcon} alt="Upload" />
                      <Text>UPLOAD IMAGE</Text>
                    </div>
                  </div>
                )}
                {isUploaded && (
                  <div className={classes.uploadFileName}>
                    <Text>{uploadedThumb.originalFileName}</Text>
                  </div>
                )}
                {!isReUpload && !_.isEmpty(currentLoc.thumbnail) && (
                  <div
                    className={classes.reUploadButton}
                    aria-hidden="true"
                    onClick={() => { setIsReUpload(true); }}
                  >
                    <img src={closeIcon} alt="Delete" />
                    <Text>{currentLoc.thumbnail.originalFileName}</Text>
                  </div>
                )}
                <TextField
                  inputRef={ref => formFieldRef.current.push(ref)}
                  name="locationName"
                  placeholder="Name of Location"
                  defaultValue={currentLoc.locationName}
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.locationWrapperForm}>
              <div className={classes.locationForms}>
                <Text className={classes.bold}>Location Type</Text>
                <TextField
                  inputRef={ref => formFieldRef.current.push(ref)}
                  name="locationType"
                  placeholder="Enter location type"
                  defaultValue={currentLoc.locationType}
                  variant="outlined"
                />
              </div>
              <div className={classes.locationForms}>
                <Text className={classes.bold}>Size</Text>
                <TextField
                  inputRef={ref => formFieldRef.current.push(ref)}
                  name="locationSize"
                  placeholder="Enter size"
                  defaultValue={currentLoc.locationSize}
                  variant="outlined"
                />
              </div>
              <div className={classes.locationForms}>
                <Text className={classes.bold}>Address</Text>
                <div className={classes.locationAddressForm}>
                  <TextField
                    inputRef={ref => formFieldRef.current.push(ref)}
                    name="addr_streetAddress"
                    placeholder="Enter Street Address"
                    variant="outlined"
                    className="streetAddress"
                    defaultValue={currentLoc.address.streetAddress}
                  />
                  <TextField
                    inputRef={ref => formFieldRef.current.push(ref)}
                    name="addr_city"
                    placeholder="Enter City"
                    variant="outlined"
                    className="city"
                    defaultValue={currentLoc.address.city}
                  />
                  <TextField
                    inputRef={ref => formFieldRef.current.push(ref)}
                    name="addr_state"
                    placeholder="Enter State"
                    variant="outlined"
                    className="state"
                    defaultValue={currentLoc.address.state}
                  />
                  <TextField
                    inputRef={ref => formFieldRef.current.push(ref)}
                    name="addr_zip"
                    placeholder="Enter Zip Code"
                    variant="outlined"
                    className="zip"
                    defaultValue={currentLoc.address.zip}
                  />
                  <TextField
                    inputRef={ref => formFieldRef.current.push(ref)}
                    name="addr_country"
                    placeholder="Enter Country"
                    variant="outlined"
                    className="country"
                    defaultValue={currentLoc.address.country}
                  />
                </div>
              </div>
              <div className={classes.locationForms}>
                <Text className={classes.bold}>GPS Coordinates</Text>
                <TextField
                  inputRef={ref => formFieldRef.current.push(ref)}
                  name="gpsCoordinates"
                  placeholder="Enter GPS Coordinates"
                  defaultValue={currentLoc.gpsCoordinates}
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.buttonContainerForm}>
              <div
                className={classes.formButton}
                onClick={() => setIsEdit(false)}
                aria-hidden="true"
              >
                <Text>CANCEL</Text>
              </div>
              <div
                className={classes.formButton}
                onClick={handleUpdate}
                aria-hidden="true"
              >
                <Text>SAVE</Text>
              </div>
              <div className={classes.clearFloat} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LocationDetail;
