import { makeStyles } from '@material-ui/core/styles';
import { grey, deepOrange, lightGreen, lime } from '@material-ui/core/colors';

const useStyles = makeStyles({
  root: {
    width: '90%',
    margin: '0 auto',
  },
  productSelect: {
    marginBottom: 64,
  },
  accordion: {
    margin: '16px 0',
    backgroundColor: grey[200],
  },
  details: {
    margin: 4,
    backgroundColor: grey[200],
  },
  textField: {
    margin: [[2, 0]],
    '& .MuiOutlinedInput-input': {
      padding: [[4, 8]],
      backgroundColor: 'white',
      maxHeight: 90,
    },
    '& .MuiOutlinedInput-multiline': {
      padding: 0,
    },
  },
  groupControls: {
    textAlign: 'right',

    '& > *': {
      verticalAlign: 'middle',
    },
    '& > * + *': {
      marginLeft: 16,
    },
  },
  controls: {
    padding: 8,
    textAlign: 'right',
  },
  translationItem: {
    padding: [[4, 8]],
    backgroundColor: grey[200],

    '&:nth-child(odd)': {
      backgroundColor: grey[100],
    },
  },
  icon: {
    cursor: 'pointer',
  },
  table: {
  },
  tbd: {
    cursor: 'pointer',
    backgroundColor: deepOrange[100],
  },
  left: {
    cursor: 'pointer',
    backgroundColor: lightGreen[100],
  },
  right: {
    cursor: 'pointer',
    backgroundColor: lime[100],
  },
});

export default useStyles;
