import React, { useState, useEffect } from 'react';
import Recoil from 'recoil';
import useAuthentication from 'hooks/useAuthentication';
import { LocalizationContext } from 'contexts/LocalizationProvider';
import { organizationListState, manageOrganizationState } from 'store/atoms';
import { Text } from 'components/UI';
import { Grid } from '@material-ui/core/';

// import seaspanLogo from 'assets/images/organization/seaspan_logo_black.png';
// import teslaLogo from 'assets/images/organization/tesla_logo.png';
import addIcon from 'assets/images/icons/icon-add-circle-solid-orange.png';

import OrganizationDetail from './OrganizationDetail';
import OrganizationForm from './OrganizationForm';
import OrganizationDashboard from './OrganizationDashboard';
import OrganizationProfiles from './OrganizationProfiles';

import useStyles from './styles';

/**
 * Organizations Page
 *
 * @returns {React.Component}
 */
function OrganizationList() {
  const classes = useStyles();
  const authentication = useAuthentication({
    unauthenticatedRedirect: 'SignIn',
    egressRedirect: 'Home',
  });
  const [addOrganization, setAddOrganization] = useState(false);
  const organizationList = Recoil.useRecoilValue(organizationListState);
  const [manageOrganization, setManageOrganization] = Recoil.useRecoilState(manageOrganizationState);

  const {
    languages,
    translations,
  } = React.useContext(LocalizationContext);

  // TODO: Use Cookies for selected language.
  const language = languages[0].code;

  useEffect(() => {
    setManageOrganization(false); // use to to close the dashboard at start
  }, []);

  return authentication.restrictAccess(
    <div className={classes.root}>
      <div className={classes.header}>
        <Text className={classes.greeting}>Good Morning,</Text>
        <Text className={classes.user}>Samantha</Text>
        <hr />
        {!manageOrganization && (
          <div className={classes.titleContainer}>
            <div className={classes.title}>{translations.organization.title[language]}</div>
            <div
              className={classes.addOrganization}
              aria-hidden="true"
              onClick={() => setAddOrganization(!addOrganization)}
            >
              <img src={addIcon} alt="Add" />
              <Text>{translations.organization.add[language]}</Text>
            </div>
          </div>
        )}
      </div>
      <div className={classes.clearFloat} />
      {!manageOrganization && (
        <Grid container spacing={3}>
          {addOrganization && <OrganizationForm setAddOrganization={setAddOrganization} />}
          {organizationList.map((value, index) => {
            return (
              <Grid
                key={index}
                item
                sm={3}
              >
                <OrganizationDetail value={value} />
              </Grid>
            );
          })}
        </Grid>
      )}
      {manageOrganization && (
        <>
          <OrganizationDashboard />
          <OrganizationProfiles />
        </>
      )}
    </div>,
  );
}

export default OrganizationList;
