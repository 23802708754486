/**
 * Error codes
 *
 * Use these keys in your code to make sure you don't make any typos.
 *
 */

// Generic form errors
export const MissingInformation = 'MissingInformation';

// Authentication
export const EmailEmpty = 'EmailEmpty';
export const EmailInvalid = 'EmailInvalid';
export const PasswordEmpty = 'PasswordEmpty';
export const PasswordMismatch = 'PasswordMismatch';
export const PasswordInvalid = 'PasswordInvalid';

// Returned by Cognito
export const NotAuthorized = 'NotAuthorizedException';
export const NotAuthenticated = 'The user is not authenticated';
export const NotConfirmed = 'UserNotConfirmedException';
export const ConfirmationEmpty = 'EmptyConfirmationCode';
export const NotFound = 'UserNotFoundException';
export const ExpiredCode = 'ExpiredCodeException';
export const CodeMismatchException = 'CodeMismatchException';
