import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  shift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin',
      'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  backdrop: {
    top: 0,
    zIndex: 9999,
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0, .6)',
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  close: {
    width: `calc(100% - ${drawerWidth / 6}px)`,
    marginLeft: `${drawerWidth / 6}px`,
    transition: theme.transitions.create(['margin',
      'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

export default useStyles;
