import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    margin: '2% 0',
    padding: '1%',
    border: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: 4,
  },
});

export default useStyles;
