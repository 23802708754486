/**
 * For when we need to calcuate the percentage out of a total like with any tabular data that needs a total percentage.
 *
 * @param {number} num
 * @param {number} total
 * @returns {string}
 */
const calcPercent = (num, total) => {
  // [TODO]: 18 is the total number of questions, need to change this to be dynamic from the Guest API
  const percent = parseInt((num / total) * 100, 10);

  if (Number.isNaN(percent)) {
    return 0;
  }

  return `${percent}%`;
};

export default calcPercent;
