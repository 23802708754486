/**
 * Authentication Hook
 *
 * This hook wraps around AWS Amplify's own Auth Class.
 *
 * Note: AWS Amplify is promise-based, so methods in this class asynchronous because we AWAIT
 * the results.
 *
 */
import React, { useState, useEffect } from 'react';
import Recoil from 'recoil';
import { Redirect, useLocation } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { Auth } from 'aws-amplify';
import DevConsole from 'utils/DevConsole';
import {
  MissingInformation,
  EmailEmpty,
  EmailInvalid,
  PasswordEmpty,
  PasswordMismatch,
  PasswordInvalid,
  NotAuthenticated,
  ConfirmationEmpty,
} from 'utils/errors';
import {
  authEmailState,
  authPasswordState,
  authNameState,
  authFamilyNameState,
  authUserState,
  routingState,
} from 'store/atoms';
import { success, error } from 'utils/responses';

const dev = new DevConsole('useAuthentication');

// Password Validation requires at least 1 lowercase letter, 1 uppercase, 1 number and must be 8 characters or longer
const passwordRegExp = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');


/**
 * useAuthentication hook
 *
 * @param {object} [options]
 * @param {string} [options.unauthenticatedRedirect] - Key to redirect path if not authenticated
 * @param {string} [options.egressRedirect] - Key to redirect path if not enough permission
 * @param {string|string[]} [options.group] - Cognito group(s)
 * @param {boolean} [options.inclusive] - If true, must be in all groups (otherwise just one)
 * @returns {object}
 */
function useAuthentication(options = {}) {
  const [auth, setAuth] = useState({
    ready: false,
    isLoggedIn: false,
    hasAccess: false,
    redirect: null,
  });

  const [emailInState, setEmailInState] = Recoil.useRecoilState(authEmailState);
  const [passwordInState, setPasswordInState] = Recoil.useRecoilState(authPasswordState);
  const [nameInState, setNameInState] = Recoil.useRecoilState(authNameState);
  const [familyNameInState, setFamilyNameInState] = Recoil.useRecoilState(authFamilyNameState);
  const [userInState, setUserInState] = Recoil.useRecoilState(authUserState);
  const resetUserInState = Recoil.useResetRecoilState(authUserState);
  const routing = Recoil.useRecoilValue(routingState);

  const location = useLocation();

  // Setters:

  /**
   * Sets (and sanitizes) user email. Returns error if invalid.
   *
   * @param {string} value
   *
   * @returns {object}
   */
  function setEmail(value) {
    const newEmail = value.toLowerCase();
    // eslint-disable-next-line max-len
    const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

    if (typeof newEmail !== 'string' || !newEmail.length) {
      setEmailInState(null);
      return error(EmailEmpty);
    }

    if (!emailRegExp.test(newEmail)) {
      setEmailInState(null);
      return error(EmailInvalid);
    }

    setEmailInState(newEmail);
    return success();
  }

  /**
   * Sets password. Checks for match validation.
   *
   * @param {string} value1
   * @param {string} [value2]
   * @returns {object}
   */
  function setPassword(value1, value2) {
    if (typeof value1 !== 'string' || !value1.length) {
      setPasswordInState(null);
      return error(PasswordEmpty);
    }

    if (!passwordRegExp.test(value1)) {
      setPasswordInState(null);
      return error(PasswordInvalid);
    }

    if (value2 !== undefined && value1 !== value2) {
      setPasswordInState(null);
      return error(PasswordMismatch);
    }

    setPasswordInState(value1);
    return success();
  }

  /**
   * Sets name
   *
   * @param {string} value
   * @returns {object}
   */
  function setName(value) {
    setNameInState(value);
    return success();
  }

  /**
   * Sets family_name
   *
   * @param {string} value
   * @returns {object}
   */
  function setFamilyName(value) {
    setFamilyNameInState(value);
    return success();
  }

  // AWS Amplify Helper functions:

  /**
   * Get current authenticated user. If it fails, returns defaults (guest session).
   *
   * @param {boolean} [bypassCache] - If true, bypass cache (always query server)
   */
  async function getAuthenticatedUser(bypassCache = false) {
    if (userInState.attributes?.sub && userInState.attributes?.email) return;
    try {
      const result = await Auth.currentAuthenticatedUser({ bypassCache });
      setUserInState(result);
    } catch (err) {
      if (err === NotAuthenticated) {
        resetUserInState();
      } else {
        dev.error(err);
      }
    }
  }

  /**
   * Signs the user in.
   *
   * We now grab email and password as parameters and set them in state directly in the function.
   * This way, we can return email & password errors if required. It also ensures data integrity.
   *
   * @param {string} email
   * @param {string} password
   * @returns {object}
   */
  async function signIn(email, password) {
    dev.log('signIn');
    let result;

    if (!email && !password) {
      return error(MissingInformation);
    }

    result = setEmail(email);
    if (!result.success) {
      return result;
    }
    result = setPassword(password);
    if (!result.success) {
      return result;
    }

    result = null;

    try {
      result = await Auth.signIn(email, password);
      dev.log(result);
      setUserInState(result);
      dev.log('done!');
      return success();
    } catch (err) {
      dev.error(err);
      return error(err.code);
    }
  }

  /**
   * Signs the user out.
   *
   * @param {boolean} [global] - If true, signs out from ALL devices.
   *
   * @returns {object}
   */
  async function signOut(global = false) {
    dev.log('signOut');
    try {
      await Auth.signOut({ global });
      resetUserInState();
      localStorage.clear('organizationId');
      localStorage.clear('organizationName');
      return success();
    } catch (err) {
      return error(err.code);
    }
  }

  /**
   * Signs the user up to user pool.
   * Optional attributes can be submitted. For custom attributes, make sure they
   * exist in Cognito first, then use the key "Custom:xxx" to assign them.
   *
   * @param {object} attributes
   *
   * @returns {object}
   */
  async function signUp(attributes = {}) {
    dev.log('signUp', attributes);
    if (!emailInState) {
      return error(EmailEmpty);
    }
    if (!passwordInState) {
      return error(PasswordEmpty);
    }
    try {
      const result = await Auth.signUp({
        username: emailInState,
        password: passwordInState,
        attributes,
      });
      setUserInState(result);
      return success();
    } catch (err) {
      resetUserInState();
      return error(err.code);
    }
  }

  /**
   * Checks validation code sent to user to confirm sign up.
   *
   * @param {string} code
   *
   * @returns {object}
   */
  async function confirmSignUp(code) {
    if (!emailInState || !code) {
      return error(ConfirmationEmpty);
    }
    try {
      dev.log('confirmSignUp', emailInState);
      const result = await Auth.confirmSignUp(emailInState, code);
      setUserInState(result);
      return success();
    } catch (err) {
      return error(err.code);
    }
  }

  /**
   * Resends the confirmation code email to validate sign up.
   *
   * @returns {object}
   */
  async function resendConfirmationCode() {
    dev.log('resendConfirmationCode', emailInState);
    if (!emailInState) {
      return error(EmailEmpty);
    }
    try {
      await Auth.resendSignUp(emailInState);
      return success();
    } catch (err) {
      return error(err.code);
    }
  }

  /**
   * Sends a recovery code email when user forgets password.
   *
   * @param {string} email
   * @returns {object}
   */
  async function sendRecoveryEmail(email) {
    dev.log('sendRecoveryEmail', emailInState);
    let result;

    if (!email) {
      return error(MissingInformation);
    }

    result = setEmail(email);
    if (!result.success) {
      return result;
    }
    result = null;
    try {
      result = await Auth.forgotPassword(email);
      dev.log(result);
      return success();
    } catch (err) {
      return error(err.code);
    }
  }

  /**
   * Updates user password using recovery code.
   *
   * @param {string} code
   * @param {string} password
   * @returns {object}
   */
  async function resetPassword(code, password) {
    dev.log('resetPassword', code, password);
    try {
      const result = await Auth.forgotPasswordSubmit(emailInState, code, password);
      dev.log(result);
      return success();
    } catch (err) {
      resetUserInState();
      return error(err.code);
    }
  }

  /**
   * Checks if user is authenticated.
   *
   * @returns {Promise}
   */
  async function isAuthenticated() {
    await getAuthenticatedUser();
    return userInState.attributes?.sub && userInState.attributes?.email;
  }

  /**
   * Checks if authenticated user is part of a given group
   *
   * @param {string|string[]} group - Group (or group[]) to check against
   * @param {boolean} inclusive - If inclusive, must be in ALL groups, otherwise just one
   *
   * @returns {Promise}
   */
  async function isInGroup(group, inclusive = false) {
    if (!group) return false;

    await getAuthenticatedUser();
    try {
      if (!userInState.signInUserSession) return false;

      const cognitoGroups = userInState.signInUserSession?.idToken?.payload['cognito:groups'];
      if (typeof cognitoGroups !== 'object' || !cognitoGroups.length) {
        return false;
      }

      // SuperAdmins have access to everything
      if (cognitoGroups.includes('SuperAdmins')) {
        return true;
      }

      // If we specify only one group
      if (typeof group === 'string') {
        return cognitoGroups.includes(group);
      }

      // If we specify multiple groups
      if (typeof group === 'object' && group.length) {
        const promises = [];
        group.forEach(async g => promises.push(
          new Promise((resolve) => resolve(cognitoGroups.includes(g))),
        ));
        const result = await Promise.all(promises);
        return inclusive === true
          ? !result.includes(false)
          : result.includes(true);
      }
      return false;
    } catch (err) {
      dev.error(err);
      return false;
    }
  }

  /**
   * Restricts access based on passed options.
   * Wrap your component's return value with this method.
   *
   * @param {React.Component} children
   * @returns {React.Component|null}
   */
  function restrictAccess(children) {
    if (!auth.ready) return null;
    if (auth.hasAccess) {
      return children;
    }
    if (auth.redirect) {
      dev.log('Redirecting');
      return (
        <Redirect to={{
          pathname: auth.redirect,
          state: { referrer: location.pathname },
        }}
        />
      );
    }
    return null;
  }

  /**
   * This side effect is triggered every time the user state changes.
   * That means every time the user signs in our out, this runs.
   */
  useEffect(() => {
    (async () => {
      const authenticated = await isAuthenticated();

      let access = true;
      if (options.group) {
        access = await isInGroup(options.group, options.inclusive);
      }

      await Promise.all([authenticated, access]);

      let redirect = false;
      // Redirect is set if user doesn't have access or permission
      if (!authenticated && options.unauthenticatedRedirect) {
        redirect = options.unauthenticatedRedirect;
      } else if (!access && options.egressRedirect) {
        redirect = options.egressRedirect;
      }
      // If a redirect was set, get proper route values:
      if (redirect) {
        try {
          redirect = routing.routePaths[redirect];
        } catch (e) {
          redirect = null;
        }
      }

      setAuth({
        ready: true,
        isLoggedIn: authenticated,
        hasAccess: authenticated && access,
        redirect,
      });
    })();
    // cleanup
    return () => {
      setAuth({
        ready: false,
        isLoggedIn: false,
        hasAccess: false,
        redirect: null,
      });
    };
  }, [userInState]);


  const state = {
    isLoggedIn: auth.isLoggedIn,
    hasAccess: auth.hasAccess,
    email: emailInState,
    password: passwordInState,
    name: nameInState,
    familyName: familyNameInState,
    user: userInState,
    attributes: userInState.attributes, // Shorthand
  };

  const methods = {
    setEmail,
    setPassword,
    setName,
    setFamilyName,
    getAuthenticatedUser,
    signIn,
    signOut,
    signUp,
    confirmSignUp,
    resendConfirmationCode,
    sendRecoveryEmail,
    resetPassword,
    isAuthenticated,
    isInGroup,
    restrictAccess,
  };

  return {
    ...state,
    ...methods,
  };
}


export default useAuthentication;
