/**
 * Configures Bucket Name to use for the Uploaded Files
 */

import getEnv from 'utils/getEnv';

const bucketName = {
  localhost: {
    bucket: 'assets.loci.dev',
  },
  dev: {
    bucket: 'assets.loci.dev',
  },
  prod: {
    bucket: 'assets.loci.ca',
  },
};

export default bucketName[getEnv()];
