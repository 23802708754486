/**
 * Helper function to isolate event handlers.
 * This essentially stops propagation on its own, but can also call a handler if provided.
 *
 * Useful in Components such as Material-UI Accordion Headers, where the entire body responds
 * to click events.
 *
 * @param {Function} [handler]
 *
 * @returns {Function}
 */
export const isolate = (handler) =>
  (e) => {
    e.stopPropagation();
    if (handler) {
      handler(e);
    }
  };
