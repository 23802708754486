/* eslint-disable react/prop-types */
import React from 'react';
import Button from '@material-ui/core/Button';
import { Dialog as MUIDialog } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
/**
 * Returns an array of strings
 *
 * @param {object} props
 * @param {string} props.title - the title string value
 * @param {string} props.content - the content string value
 * @param {boolean} props.open - true/false for opening the dialog box
 * @param {Function} props.handler - handler function passed as prop
 * @param {React.Component} props.children - children object
 * @param {React.Component} props.extraButtons - extra buttons to display in DialogActions
 * @returns {React.component}
 */
function Dialog(props) {
  const {
    title, content, open, handler, children, extrabuttons, ...rest
  } = props;

  const handleClose = () => {
    if (handler) {
      handler(false);
    }
  };

  return (
    <MUIDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      {...rest}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content || children}</DialogContent>
      <DialogActions>
        {extrabuttons}
        <Button onClick={handleClose} color="primary">Close</Button>
      </DialogActions>
    </MUIDialog>
  );
}

// TODO: Add PropTypes!

export default Dialog;
