import React from 'react';
import { Text } from 'components/UI';
import {
  Cell,
  BarChart,
  Bar,
  XAxis,
  LabelList,
} from 'recharts';

import useStyles from './styles';

const AverageScoreAttempt = () => {
  const classes = useStyles();

  const barColors = ['#01A72E', '#0271D6', '#5B29C7'];
  const data03 = [
    {
      label: '1st Play',
      name: 'NOVICE',
      scoreLabel: '14 / 35',
      score: 14,
    },
    {
      label: '2nd Play',
      name: 'ROOKIE',
      scoreLabel: '25 / 35',
      score: 25,
    },
    {
      label: '3+ Plays',
      name: 'SCIENTIST',
      scoreLabel: '30 / 35',
      score: 30,
    },
  ];

  return (
    <div className={classes.barChartContainer}>
      <Text className="ptitle">
        AVERAGE SCORE
        <br />
        BY ATTEMPT
      </Text>
      <Text className="pdate">Nov 1 - Nov 7</Text>
      <div className={classes.barChart}>
        <BarChart width={700} height={350} data={data03} margin={{ top: 70 }}>
          <XAxis dataKey="label" tick={{ fill: '#000', fontSize: '1vw', fontWeight: 'bold' }} stroke="none" />
          <Bar dataKey="score" isAnimationActive={false}>
            <LabelList
              dataKey="name"
              fontSize="1.25vw"
              letterSpacing="0.25vw"
              fontWeight="bold"
              fontFamily="spyitalic"
              position="top"
              offset={30}
            />
            <LabelList dataKey="scoreLabel" fontSize="1.45vw" fontWeight="bold" position="top" />
            {data03.map((entry, index) => <Cell key={`cellb-${index}`} fill={barColors[index % barColors.length]} />)}
          </Bar>
        </BarChart>
      </div>
    </div>
  );
};

export default AverageScoreAttempt;
