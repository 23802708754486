import React from 'react';
import { Box } from '@mui/material';
import DevConsole from 'utils/DevConsole';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { courseState } from 'store/atoms';
import PropTypes from 'prop-types';
import useStyles from './styles';

const dev = new DevConsole('Badge');

const Badge = React.memo(({ src, altText, course }) => {
  // [TODO]: Render these as part of a collection and update the state accordingly.
  dev.log('Rendered');
  const classes = useStyles();
  const [active, setActive] = React.useState(false);
  const courseName = useRecoilValue(courseState);
  const setCourseName = useSetRecoilState(courseState);

  const handleClick = () => {
    setActive(!active);

    return setCourseName(prevState => {
      if (prevState === '') {
        return course;
      }
      return course === '' ? course : '';
    });
  };

  dev.log(courseName);
  return (
    <Box
      className={classes.badgeContainer}
      onClick={handleClick}
    >
      <img
        className={active ? classes.badgeImg : classes.badgeOpc}
        src={src}
        alt={altText}
      />
    </Box>
  );
});

Badge.propTypes = {
  src: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  course: PropTypes.string,
};

Badge.defaultProps = {
  course: '',
};

export default Badge;
