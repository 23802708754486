/**
 * Routes
 *
 * The object below defines all the routes of the website.
 *
 */

import Auth from 'utils/Auth';
import {
  // Home, // We've set OrganizationList as Home.
  SignIn,
  SignUp,
  Recovery,
  ZoneList,
  OrganizationList, // 1
  Location, // 2
  Profiles, // 3
  NewCourse,
  Reports,
  // Super Admin routes:
  Translations, // 4
  AccessKeys, // 5
  Features, // 6
} from 'pages';

const auth = new Auth();

// TODO: Convert to FeatureFlag value.
const OVERRIDE = false;
// const External = 'External';

const routes = {
  // Website Navigation:
  Home: {
    page: OVERRIDE || OrganizationList,
    exact: true,
    sensitive: false,
    menuOrder: 0,
    path: '/',
  },

  OrganizationList: {
    page: OVERRIDE || OrganizationList,
    exact: true,
    sensitive: false,
    menuOrder: 1,
    path: '/organization-list',
    accessGroups: ['Admins'],
  },

  Location: {
    page: OVERRIDE || Location,
    exact: true,
    sensitive: false,
    menuOrder: 2,
    path: '/location',
    accessGroups: ['Admins'],
  },

  // Employees

  // Contractors

  Profiles: {
    page: OVERRIDE || Profiles,
    exact: true,
    sensitive: false,
    menuOrder: 3,
    path: '/profiles',
    accessGroups: ['Admins'],
  },

  // Reviewrs

  // Reports
  Reports: {
    page: OVERRIDE || Reports,
    exact: true,
    sensitive: false,
    menuOrder: 0,
    path: '/reports',
  },

  SignIn: {
    page: OVERRIDE || SignIn,
    exact: true,
    sensitive: false,
    menuOrder: 0,
    path: '/signin',
  },

  SignUp: {
    page: OVERRIDE || SignUp,
    exact: true,
    sensitive: true,
    menuOrder: 0,
    path: '/signup',
  },

  Recovery: {
    page: OVERRIDE || Recovery,
    exact: true,
    sensitive: false,
    menuOrder: 0,
    path: '/recovery',
  },

  ZoneList: {
    page: OVERRIDE || ZoneList,
    exact: true,
    sensitive: false,
    menuOrder: 0,
    path: '/zone-list',
  },

  NewCourse: {
    page: OVERRIDE || NewCourse,
    exact: true,
    sensitive: false,
    menuOrder: 0,
    path: '/new-course',
  },
};

const socialNetworks = {
  // Youtube: {
  //   page: External,
  //   path: 'https://www.youtube.com/channel/###',
  // },
  // Facebook: {
  //   page: External,
  //   path: 'https://www.facebook.com/###',
  // },
  // Twitter: {
  //   page: External,
  //   path: 'https://twitter.com/###',
  // },
  // Instagram: {
  //   page: External,
  //   path: 'https://www.instagram.com/###/',
  // },
  // LinkedIn: {
  //   page: External,
  //   path: 'https://www.linkedin.com/###/',
  // },
};

const superAdminRoutes = {
  Translations: {
    page: Translations,
    exact: true,
    sensitive: false,
    menuOrder: 4,
    path: '/translations',
    accessGroups: ['Translators'], // Give access to the groups in this list
  },

  AccessKeys: {
    page: AccessKeys,
    exact: true,
    sensitive: false,
    menuOrder: 5,
    path: '/accessKeys',
    accessGroups: ['Admins'], // Give access to the groups in this list
  },

  Features: {
    page: Features,
    exact: true,
    sensitive: false,
    menuOrder: 12,
    path: '/features',
    accessGroups: ['Admins', 'Developers'],
  },
};

/**
 * Compile the above routes before returning them.
 * This basically looks into the user session for special permissions and adds any restricted
 * paths.
 *
 * @returns {object} Compiled routes.
 */
async function compiledRoutes() {
  await auth.getAuthenticatedUser();
  return {
    ...routes,
    ...socialNetworks,
    ...await auth.isInGroup('SuperAdmins') ? superAdminRoutes : null,
  };
}

export default compiledRoutes;
