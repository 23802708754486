import React from 'react';
import { Paper } from '@material-ui/core';
import { Text } from 'components/UI';
import PropTypes from 'prop-types';

/** DashInfo component for displaying different calculations on the data
 *
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.calcResult
 * @returns {React.Component}
 */
const DashInfo = ({
  title,
  calcResult,
}) => {
  return (
    <>
      <Paper
        variant="outlined"
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '5rem',
          width: '25%',
          marginTop: '0.75rem',
          marginLeft: '0.75rem',
          borderColor: 'rgb(158, 187, 172)',
        }}
      >
        <div style={{ margin: '0.75rem' }}>
          <strong>{title}</strong>
          <Text color="primary" variant="h5">
            <strong>
              {calcResult}
            </strong>
          </Text>
        </div>
      </Paper>
    </>
  );
};

DashInfo.propTypes = {
  title: PropTypes.string.isRequired,
  calcResult: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

DashInfo.defaultProps = {
  calcResult: 0,
};

export default DashInfo;
