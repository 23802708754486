import React from 'react';
import DevConsole from 'utils/DevConsole';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { Grid, Box } from '@material-ui/core';
import { TextField, Text } from 'components/UI';
import calcPercent from 'utils/calcPercent';
import PropTypes from 'prop-types';
import profileAvatar from 'assets/images/profile-avatar.png';

const dev = new DevConsole('GuestProfile');
// [TODO]: Change this to come from the API
const randNames = [
  'Dave Abernathy',
  'Matilda Swenson',
  'Bob Racker',
  'RZA',
  'Cathy Bates',
  'Norman Rockwell',
  'Finegood Timeworthy',
  'Ralph Timothy',
];
/**
 * Guest Profile Card for our Collapsible Table
 *
 * @param {object} props
 * @param {number} props.score
 * @param {number} props.progress
 * @returns {React.Component}
 */
const GuestProfile = ({
  score,
  progress,
}) => {
  dev.log('Rendered');

  const mb = {
    marginBottom: '1.25rem',
  };

  return (
    <Grid container>
      <Grid item xs={2}>
        <Box>
          <img src={profileAvatar} alt="Profile robot" />
        </Box>
        <Text variant="h6">Activity Log</Text>
      </Grid>
      <Grid item xs={10} style={{ marginBottom: '1.25rem' }}>
        {calcPercent(score, progress) === 0 ? <Text variant="h4" color="primary" style={mb}>No gameplay found</Text> : (
          <Text variant="h4" color="primary" style={mb}>
            OVERALL SCORE
            {' '}
            {calcPercent(score, progress)}
            {' '}
            AS OF
            {' '}
            {moment().format('MM/DD/YYYY')}
          </Text>
        )}
        <Grid spacing={4} container>
          <Grid item>
            <TextField
              placeholder="Unique ID"
              label="Unique ID"
              value={randNames[Math.floor(Math.random() * randNames.length)]}
              InputProps={{
                endAdornment: <EditIcon color="primary" />,
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              placeholder="Access Allowed"
              label="Access Allowed"
              value="50 P Shipyard"
              InputProps={{
                endAdornment: <EditIcon color="primary" />,
              }}
            />
          </Grid>
        </Grid>
        <Grid spacing={4} container>
          <Grid item>
            <TextField
              placeholder="Primary Location"
              label="Primary Location"
              value="50 P"
              InputProps={{
                endAdornment: <EditIcon color="primary" />,
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              placeholder="Role"
              label="Role"
              value="Supervisor"
              InputProps={{
                endAdornment: <EditIcon color="primary" />,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

GuestProfile.propTypes = {
  score: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  progress: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};


export default GuestProfile;
