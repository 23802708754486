import Recoil from 'recoil';
import features from 'config/features';

const featuresState = Recoil.atom({
  key: 'featuresState',
  default: features,
});

export {
  featuresState,
};
