import React from 'react';
import DevConsole from 'utils/DevConsole';
import PropTypes from 'prop-types';
import {
  Text,
  Button,
  SelectField,
  TextField,
  Tip,
} from 'components/UI';
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
} from '@material-ui/core';
import { accessKey } from 'API/services';
import useCustomSnackbar from 'hooks/useCustomSnackbar';
import useStyles from './styles';

const dev = new DevConsole('AKForm');

const AKForm = ({ languageItems }) => {
  // styles
  const classes = useStyles();

  // local state
  const [state, setState] = React.useState({
    currentLanguageOverride: 'N/A',
    currentActive: false,
  });
  const [currentAk, setCurrentAk] = React.useState('');

  // hooks
  const [showSnackbar] = useCustomSnackbar();

  // API
  const createAccessKey = async () => {
    try {
      const result = await accessKey.create({
        body: {
          productId: 'GSI',
          accessKey: currentAk,
          languageOverride: state.currentLanguageOverride,
          active: state.currentActive,
        },
      });
      dev.log(`create result: ${JSON.stringify(result)}`);
      if (result.success) {
        showSnackbar('Create successful!', { preventDuplicate: true, variant: 'success' });
        setState({
          currentLanguageOverride: 'N/A',
          currentActive: false,
        });
      }
    } catch (e) {
      dev.error(e.response);
    }
  };

  // form handlers
  const handleAccessKey = (e) => {
    setCurrentAk(e.target.value);
  };

  const onHandleChange = (attrib) => ({ target }) => {
    setState({ ...state, [target.name]: target[attrib] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createAccessKey();
    setCurrentAk('');
  };

  return (
    <>
      <Text v="h3" g>Custom URL Editor</Text>
      <div className={classes.root}>
        <form onSubmit={handleSubmit} autoComplete="off">
          <FormGroup row>
            <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
              <Grid item xs={3}>
                <Tip elementId="accesskey" text="Create a custom link with this field">
                  <TextField id="accessKey" label="Custom Link" value={currentAk} onChange={handleAccessKey} fullWidth />
                </Tip>
              </Grid>
              <Grid item xs={3}>
                <Tip elementId="languageO" text="Choose which language you'd like the content to appear in">
                  <SelectField
                    id="currentLanguageOverride"
                    label="Language override"
                    value={state.currentLanguageOverride}
                    onChange={onHandleChange('value')}
                    name="currentLanguageOverride"
                    fullWidth
                  >
                    {languageItems}
                  </SelectField>
                </Tip>
              </Grid>
              <Grid item xs={2}>
                <Tip elementId="activeLang" text="Make this the default language">
                  <FormControlLabel
                    control={<Checkbox checked={state.currentActive} onChange={onHandleChange('checked')} name="currentActive" />}
                    label="Active"
                    labelPlacement="end"
                  />
                </Tip>
              </Grid>
              <Grid item xs={2}>
                <Button
                  type="submit"
                  tabIndex="0"
                  variant="outlined"
                  className={classes.formBtn}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </FormGroup>
        </form>
      </div>
    </>
  );
};

AKForm.propTypes = {
  languageItems: PropTypes.array.isRequired,
};

export default AKForm;
