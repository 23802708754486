/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox as MUICheckbox,
} from '@material-ui/core';

/**
 * Checkbox component wrapper
 *
 * @param {object} props
 * @param {boolean} props.checked
 * @param {Function} props.handler
 * @param {string} props.variant
 * @param {string} props.color
 * @returns {React.component}
 */
const Checkbox = ({ checked, handler, variant = 'outlined', color = 'primary', ...props }) => {
  const [val, setValue] = React.useState(checked);
  const onChange = (event) => {
    setValue(event.target.checked);
    if (handler) {
      handler(event.target.checked);
    }
  };

  return (
    <MUICheckbox
      checked={val}
      variant={variant}
      color={color}
      onChange={onChange}
      {...props}
    />
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  handler: PropTypes.func,
  variant: PropTypes.string,
  color: PropTypes.string,
};

Checkbox.defaultProps = {
  checked: false,
  handler: () => { },
  variant: 'outlined',
  color: 'primary',
};

export default Checkbox;
