import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '0 2rem',
  },
  infLoading: {
    width: 150,
    height: 150,
    margin: '5% auto',
  },
}));

export default useStyles;
