import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { Card, CardActions, CardContent, Grid } from '@material-ui/core';
import { TextField, Button, Text, Link } from 'components/UI';
import Confirmation from 'components/UI/Confirmation';
import Checkbox from 'components/UI/Checkbox';
import logo2x from 'assets/images/logo-notag-black-2x.png';
import DevConsole from 'utils/DevConsole';
import { MissingInformation, EmailEmpty, PasswordEmpty, NotConfirmed, EmailInvalid } from 'utils/errors';
import { LocalizationContext } from 'contexts/LocalizationProvider';
import useCustomSnackbar from 'hooks/useCustomSnackbar';
import useAuthentication from 'hooks/useAuthentication';
import SessionStorage from 'utils/SessionStorage';

import useStyles from './styles';

const dev = new DevConsole('SignIn');

/**
 * SignIn Page
 *
 * @param {object} props
 * @param {React.Component[]} props.location
 *
 * @returns {React.Component}
 */
function SignIn(props) {
  dev.log('render');
  const classes = useStyles();
  const authentication = useAuthentication();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [rememberMe, setRememberMe] = React.useState(false);
  const [agree, hasAgreed] = React.useState(false);
  const [needConfirmation, setNeedConfirmation] = React.useState(false);
  const [showSnackbar, closeSnackbar] = useCustomSnackbar({
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
  });
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(['cookie-name']);
  const history = useHistory();


  const {
    languages,
    translations,
  } = React.useContext(LocalizationContext);

  // TODO: Use Cookies for selected language.
  const language = languages[0].code;

  const onHandleLogin = async (event) => {
    event.preventDefault();
    SessionStorage.setExpiration(rememberMe ? 'long' : 'short');
    const signIn = await authentication.signIn(email, password);
    dev.log(signIn);

    if (signIn.challengeName === 'NEW_PASSWORD_REQUIRED') {
      showSnackbar(translations.signin.newpass[language], { variant: 'error' });
      return;
    }

    if (signIn.error === MissingInformation) {
      showSnackbar(translations.errors.missinginformation[language], { variant: 'warning' });
      return;
    }
    if (signIn.error === EmailEmpty || signIn.error === EmailInvalid) {
      showSnackbar(translations.signin.emailempty[language], { variant: 'warning' });
      return;
    }
    if (signIn.error === PasswordEmpty) {
      showSnackbar(translations.signin.passwordempty[language], { variant: 'warning' });
      return;
    }
    if (signIn.error === NotConfirmed) {
      showSnackbar(translations.signin.notconf[language], { preventDuplicate: false, variant: 'warning' });
      setNeedConfirmation(true);
      return;
    }
    if (signIn.error) {
      showSnackbar(translations.signin.notauth[language], { preventDuplicate: false, variant: 'error' });
      return;
    }

    if (signIn.success) {
      dev.log(authentication.user);
      let confirmation = '';

      if (authentication.attributes.name && authentication.attributes.email) {
        confirmation = `${translations.signin.conf[language]} ${authentication.attributes.name} (${authentication.attributes.email}).`;
      } else {
        confirmation = `${translations.signin.conf[language]} ${authentication.attributes.email}.`;
      }

      closeSnackbar();
      showSnackbar(confirmation, { preventDuplicate: true, variant: 'success' });

      if (await authentication.isInGroup('SuperAdmins')) {
        showSnackbar(translations.signin.super[language], { preventDuplicate: true, variant: 'success' });
      }
    }
  };

  const onHandleEnter = (e) => {
    if (e.key === 'Enter') onHandleLogin(e);
  };


  // Optional redirect:
  // TODO: Create a <Redirect> wrapper to accept routes, and use that instead of history.replace
  useEffect(() => {
    (async () => {
      // If authenticated, redirect to home page
      if (await authentication.isAuthenticated()) {
        history.replace('/');
        return;
      }
      // Otherwise check if we have a referrer in state
      if (authentication.attributes.sub && props.location.state && props.location.state.referrer) {
        dev.log(`Redirecting to ${authentication.referer}`);
        history.replace(props.location.state.referrer);
      }
    })();
  });

  if (needConfirmation) {
    return (
      <Confirmation
        email={email}
        setNeedConfirmation={setNeedConfirmation}
        snackbar={[showSnackbar, closeSnackbar]}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>{translations.signin.title[language]}</title>
      </Helmet>

      <div className={clsx(classes.root, 'poster')}>
        <div className={classes.gradient} />
        <Card className={classes.card}>
          <CardContent className={classes.flexContent}>
            <form id="SignIn" onSubmit={onHandleLogin}>
              <Grid container spacing={2} alignContent="flex-start">
                <div className={classes.flexLogo}>
                  <Grid item xs={12} md={3}>
                    <img className={classes.logo} src={logo2x} alt="LOCI" />
                  </Grid>
                  <Grid item xs={false} md={2} />
                  <Grid item xs={12} md={7}>
                    <Text>{translations.signin.tagline[language]}</Text>
                  </Grid>
                </div>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    helperText={translations.signin.email[language]}
                    type="email"
                    fullWidth
                    handler={setEmail}
                    onKeyPress={onHandleEnter}
                    value={email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="password"
                    fullWidth
                    helperText={(
                      // We must use span components or else we'll have DOM nesting errors
                      <Grid component="span" container justifyContent="space-between" alignContent="center" alignItems="center">
                        <Grid component="span" item>{translations.signin.password[language]}</Grid>
                        <Grid component="span" item>
                          <Link keyword="Recovery" title={translations.signin.recovery[language]} />
                        </Grid>
                      </Grid>
                    )}
                    type="password"
                    handler={setPassword}
                    onKeyPress={onHandleEnter}
                    value={password}
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    className={classes.remember}
                    checked={rememberMe}
                    name="remember"
                    handler={setRememberMe}
                  />
                  <span>{translations.signin.remember[language]}</span>
                </Grid>
                <Grid item xs={12}>
                  <section className={classes.agreeContainer}>
                    <Checkbox
                      className={classes.agree}
                      checked={agree}
                      name="remember"
                      handler={hasAgreed}
                    />
                    <span className={classes.agreeSpan}>I have read the Privacy Policy</span>
                  </section>
                </Grid>
              </Grid>
            </form>
          </CardContent>
          <CardActions className={classes.flexActions}>
            <Link keyword="SignUp" title={translations.signin.signup[language]} />
            <Button
              disabled={!agree}
              type="submit"
              variant="contained"
              onClick={onHandleLogin}
            >
              {translations.signin.signin[language]}
            </Button>
          </CardActions>
        </Card>
        <Text className={classes.copyright}>LOCI &copy; COPYRIGHT 2021</Text>
      </div>
    </>
  );
}

SignIn.propTypes = {
  location: PropTypes.object,
};

SignIn.defaultProps = {
  location: { state: null },
};
export default SignIn;
