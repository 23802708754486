import { makeStyles } from '@material-ui/core/styles';

const green = '#004D26';
const orange = '#FF580F';
const lightGreen = '#7FA592';
const grey = '#C6C6C6';
const formBg = '#FBF9F5';
const uploadFormBg = '#F5F3EF';
const boxShadowColor = '#00000029';

const useStyles = makeStyles(() => ({
  /** START: Location LIST styles */
  root: {
    padding: '0 2rem',
    fontFamily: 'Open Sans',
  },

  header: {
    color: green,

    '& hr': {
      margin: '0.78125vw 0',
    },
  },

  breadCrumb: {
    fontSize: '0.83vw',
    fontWeight: 500,
  },

  title: {
    fontSize: '0.83vw',
    fontWeight: 'bold',
  },

  addLocation: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    float: 'right',
    color: orange,
    marginTop: '-1.3vw',

    '& p': {
      marginLeft: '0.26vw',
      fontSize: '0.625vw',
      fontWeight: 'bold',
    },
  },
  /** END: Location LIST styles */

  /** START: Location DETAIL styles */
  locationBorder: {
    marginTop: '1vw',
    border: `0.052vw ${lightGreen} solid`,
    borderRadius: '1.3vw',
    width: '19vw',
    height: '59vh',
  },

  locationContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  locationWrapper: {
    margin: '1vw 0.5vw',
    height: '27vh',
  },

  locationThumbContainer: {
    width: '100%',
    height: '22vh',
    padding: '0.5vw',
  },

  locationThumb: {
    width: '-webkit-fill-available',
    borderRadius: '1vw',
  },

  locationHeader: {
    height: '3.52vh',

    '& p': {
      fontSize: '0.885vw',
      fontWeight: 'bold',
      color: green,
    },

    '& input': {
      width: '13vw',
      height: '0.1vh',
    },
  },

  locationOrgLogo: {
    maxWidth: '13vw',
    height: '4.5vh',
    paddingBottom: '0.5vw',

    '& img': {
      height: '100%',
    },
  },

  locationTitle: {
    fontSize: '0.885vw !important',
    fontWeight: 'bold',
  },

  locationInfo: {
    marginTop: '0.5vw',
    width: '15vw',

    '& p': {
      fontSize: '0.729vw',
      color: '#000',
    },
    '& p:nth-child(4)': {
      whiteSpace: 'pre-line',
    },
  },

  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '15vw',
    height: '3.73vh',
  },

  manageButton: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',

    '& p': {
      marginRight: '0.52vw',
      fontSize: '0.78125vw',
      fontWeight: 'bold',
      color: orange,
    },
  },
  /** END: Location DETAIL styles */

  /** START: Location FORM styles */
  locationBorderForm: {
    marginTop: '1vw',
    border: `0.052vw ${lightGreen} solid`,
    borderRadius: '1.3vw',
    width: '19vw',
    height: '59vh',
    backgroundColor: formBg,
    boxShadow: `0.52vw 0.67vw 0.83vw ${boxShadowColor}`,
  },

  locationThumbContainerForm: {
    width: '100%',
    height: '11vh',
    padding: '0.5vw',
  },

  locationHeaderForm: {
    height: '11vh',
    width: '100%',
    border: `0.052vw ${grey} solid`,
    borderRadius: '1vw',
    backgroundColor: uploadFormBg,
    padding: '1vw',

    '& input': {
      width: '13.25vw',
      height: '0.1vh',
    },
  },

  locationWrapperForm: {
    margin: '1vw',
    height: '40vh',
  },

  locationsForm: {
    marginTop: '1vw',
  },

  locationAddressForm: {
    display: 'flex',
    flexDirection: 'row',
    flex: '0 min-content',
    flexWrap: 'wrap',
    height: '14vh',

    '& div': {
      margin: '0.1vw 0.1vw 0.1vw 0',
    },

    '& .streetAddress input': {
      width: '14.5vw',
      height: '0.1vh',
    },

    '& .city input': {
      width: '6.25vw',
      height: '0.1vh',
    },

    '& .state input': {
      width: '6.25vw',
      height: '0.1vh',
    },

    '& .zip input': {
      width: '6.25vw',
      height: '0.1vh',
    },

    '& .country input': {
      width: '6.25vw',
      height: '0.1vh',
    },
  },

  locationForms: {
    marginTop: '0.5vw',

    '& p': {
      marginBottom: '0.25vw',
      fontSize: '0.729vw',
      color: '#000',
    },

    '& input': {
      width: '14.5vw',
      height: '0.1vh',
    },

    '& textarea': {
      width: '14.5vw',
      height: '3vh',
    },
  },

  uploadButton: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: '0.52vw',

    '& p': {
      marginLeft: '0.52vw',
      fontSize: '0.78125vw',
      fontWeight: 'bold',
      color: green,
    },
  },

  reUploadButton: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: '0.52vw',

    '& p': {
      marginLeft: '0.52vw',
      fontSize: '0.75vw',
    },
  },

  buttonContainerForm: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'right',
    width: '100%',
  },

  formButton: {
    '& p': {
      marginRight: '1vw',
      fontSize: '0.75vw',
      fontWeight: 'bold',
      color: green,
    },
  },
  /** END: Location FORM styles */

  /** START: Location Dashboard styles */
  dashboardContainer: {
    marginTop: '2vw',
    border: `0.05vw ${lightGreen} solid`,
    borderRadius: '1.3vw',
    height: '60vh',
  },
  dashboardContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    margin: '1.5vw 2.5vw 1.5vw 1.5vw',
    justifyContent: 'space-between',
  },
  dashboardInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: '12vw',
  },
  dashboardLogo: {
    height: '6vh',
  },
  dashboardOrgName: {
    height: '6vh',
    color: green,

    '& p': {
      fontSize: '1.5vw',
      fontWeight: 'bold',
    },
  },
  dashboardCount: {
    color: green,
    height: '8vh',

    '& p': {
      marginTop: '0.75vw',
      fontSize: '0.83vw',
      fontWeight: 'bold',
    },
  },
  dashboardWarden: {
    marginTop: '1.25vw',
    height: '6vh',

    '& p:nth-child(1)': {
      fontSize: '1vw',
      fontWeight: 'bold',
    },
    '& p:nth-child(2)': {
      marginTop: '0.5vw',
      fontSize: '0.78vw',
      fontWeight: 500,
      color: green,
    },
  },
  dashboardReviewers: {
    marginTop: '1vw',
    height: '17vh',

    '& p:nth-child(1)': {
      fontSize: '1vw',
      fontWeight: 'bold',
      color: '#000',
    },
    '& p': {
      marginTop: '0.5vw',
      fontSize: '0.78vw',
      fontWeight: 500,
      color: green,
    },
  },
  badgeContainer: {
    width: '4.69vw',
    height: '11.75vh',

    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  dashboardStats: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: '45vw',
    justifyContent: 'space-between',
  },
  statsHeader: {
    height: '3.2vh',

    '& p': {
      fontSize: '0.78vw',
      fontWeight: 500,
      letterSpacing: '0.15vw',
    },
  },
  statsContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: '45vw',
    height: '45vh',
    justifyContent: 'space-between',
    marginTop: '2vw',
    paddingBottom: '3.5vw',
  },
  contentLayer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'start',
    height: '11.31vh',

    '& .totalPlays': {
      width: '8.85vw',
    },
    '& .totalLocations': {
      width: '9.79vw',
    },
    '& .passed': {
      width: '8.85vw',
    },
    '& .totalCourse': {
      width: '12.5vw',
    },
    '& .failed': {
      width: '10.52vw',
    },
    '& .aveScore': {
      width: '12.5vw',
    },
    '& .saved': {
      width: '10.42vw',
    },
    '& .trainingTime': {
      width: '10.42vw',
    },
  },
  stats: {
    border: `0.05vw ${lightGreen} solid`,
    borderRadius: '0.5vw',
    height: '100%',
    padding: '1vw',
    marginRight: '1vw',

    '& p:nth-child(1)': {
      fontSize: '0.72vw',
      fontWeight: 500,
    },
    '& p:nth-child(2)': {
      marginTop: '0.25vw',
      fontSize: '2vw',
      fontWeight: 'bold',
    },
  },
  dashboardButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: '15vw',
  },
  dashboardBtn: {
    width: '14vw',
    height: '4.5vh',
    marginBottom: '1vw',
  },
  closeDashboard: {
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'row',
    justifyContent: 'end',
    width: '15vw',
    height: '6vh',

    '& img': {
      transform: 'translate(3.65vw, -0.52vw)',
    },
  },
  settings: {
    height: '42vh',
  },
  viewLocations: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'end',
    width: '15vw',

    '& p': {
      marginRight: '0.52vw',
      fontSize: '0.75vw',
      fontWeight: 'bold',
      color: orange,
    },
  },
  profilesContainer: {
    width: '100%',

    '& .MuiAutocomplete-root': {
      color: green,
    },
    '& .MuiFormLabel-root': {
      color: green,
    },
    '& .MuiFormControl-fullWidth': {
      margin: '1vw 0 0.5vw',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '3vw',
      borderColor: lightGreen,
    },
  },
  infLoading: {
    width: 150,
    height: 150,
    margin: '5% auto',
  },
  /** END: Location Dashboard styles */

  /** START: GLOBAL styles */
  uploadFileName: {
    '& p': {
      fontSize: '0.75vw',
      color: '#000',
      paddingBottom: '0.5vw',
    },
  },

  bold: {
    fontWeight: 'bold',
  },

  clearFloat: {
    clear: 'both',
  },

  hiddenClass: {
    display: 'none',
  },
  /** END: GLOBAL styles */
}));

export default useStyles;
