import { makeStyles } from '@material-ui/core/styles';
import bkg2x from 'assets/images/background-2x.png';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${bkg2x})`,
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: 'center',
    },
    '& a': { // The CANCEL link
      fontWeight: 'bold',
      color: '#004D26',
      marginRight: '4rem',
    },
  },
  gradient: {
    display: 'flex',
    width: 24,
    height: '100vh',
    backgroundImage: 'linear-gradient(0deg, #FF580F 0%, #D9FF31 50%, #004D26 100%)',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    margin: [[229, 0, 0, 200]],
    padding: 64 - 16, // CardContent has padding
    width: '35%',
    height: '55%',
    borderRadius: 25,
    boxShadow: '10px 13px 16px #00000029',
    textAlign: 'left',
    [theme.breakpoints.down(800)]: {
      width: '100%',
      minWidth: 360,
      margin: [['5vmin', '5%']],
    },
  },
  titleText: {
    letterSpacing: 0.75,
    fontWeight: 400,
    fontSize: 16,
    marginBottom: '.75rem',
  },
  signUp: {
    width: '30%',
    marginRight: '1.5rem',
  },
  resend: {
    outline: 'none',
  },
  formLabel: {
    fontWeight: 400,
    color: '#000000',
  },
  flexContent: {
    display: 'flex',
    flexGrow: 1,
  },
  flexActions: {
    marginTop: '10vh',
    justifyContent: 'flex-end',
  },
  flexLogo: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: 24,
  },
  logo: {
    width: 125,
    height: 56.66,
  },
}));

export default useStyles;
