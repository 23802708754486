/**
 * guest API
 *
 * Wraps around the Feature Flags API endpoint.
 */
import API from 'API';
import mockData from './mockData';

const api = new API();
const service = 'guest';
const path = '/guest';

const guest = {
  mock: () => api.setMock(mockData),
  live: () => api.setMock(null),
  get: payload => api.exec({
    action: 'get',
    service,
    path,
    payload,
  }),
  create: payload => api.exec({
    action: 'post',
    service,
    path,
    payload,
  }),
  update: payload => api.exec({
    action: 'put',
    service,
    path,
    payload,
  }),
  delete: payload => api.exec({
    action: 'del',
    service,
    path,
    payload,
  }),
};

export default guest;
