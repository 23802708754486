import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Recoil from 'recoil';
import { routingState, menuState } from 'store/atoms';
import { BrowserRouter } from 'react-router-dom';
// import PropTypes from 'prop-types';
// import useRoute from 'hooks/useRoute';
import compiledRoutes from 'config/routes';
import { convertRoutesToLinks, convertRoutesToMenuLinks } from 'utils/routing';
import './styles.scss';


/**
 * Router Component
 *
 * Loads routes from config & defines BrowserRouter.
 *
 * @param {any} props
 * @param {React.component} props.children - any children rendered by this component
 * @returns {React.component}
 */
function Router({ children }) {
  const [routing, setRouting] = Recoil.useRecoilState(routingState);
  const [open] = Recoil.useRecoilState(menuState);

  useEffect(() => {
    (async () => {
      const routes = await compiledRoutes();
      setRouting({
        ...routing,
        routes,
        routePaths: convertRoutesToLinks(routes),
        menuLinks: await convertRoutesToMenuLinks(routes),
      });
    })();
  }, [open]);

  // dev.log(routing.routePaths ? routing.routePaths : 'loading');

  return routing.routePaths ? (
    <BrowserRouter id="Router">
      {children}
    </BrowserRouter>
  ) : null;
}

Router.propTypes = {
  children: PropTypes.node.isRequired,
};


export default Router;
