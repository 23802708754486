import Recoil from 'recoil';

// Authentication email
export const authEmailState = Recoil.atom({
  key: 'authEmailState',
  default: null,
});

// Authentication password
export const authPasswordState = Recoil.atom({
  key: 'authPasswordState',
  default: null,
});

// Authentication first name
export const authNameState = Recoil.atom({
  key: 'authNameState',
  default: null,
});

// Authentication last name
export const authFamilyNameState = Recoil.atom({
  key: 'authFamilyNameState',
  default: null,
});

// Authentication user object set by AWS Amplify/Cognito
export const authUserState = Recoil.atom({
  key: 'authUserState',
  default: {
    username: null,
    pool: {
      userPoolId: null,
      clientId: null,
      client: {
        endpoint: 'https://cognito-idp.us-east-1.amazonaws.com/',
        fetchOptions: {},
      },
      advancedSecurityDataCollectionFlag: true,
    },
    Session: null,
    client: {
      endpoint: 'https://cognito-idp.us-east-1.amazonaws.com/',
      fetchOptions: {},
    },
    signInUserSession: {
      idToken: {
        jwtToken: null,
        payload: {},
      },
      refreshToken: {
        token: null,
      },
      accessToken: {
        jwtToken: null,
        payload: {},
      },
      clockDrift: 0,
    },
    authenticationFlowType: 'USER_SRP_AUTH',
    keyPrefix: null,
    userDataKey: null,
    attributes: {
      sub: null,
      email: null,
      email_verified: false,
      name: 'Guest',
      family_name: null,
    },
    preferredMFA: 'NOMFA',
  },
});
