import React from 'react';
import { Text } from 'components/UI';

import badge from 'assets/images/reports/noun_badge_799025_stroke@3x.png';

import useStyles from './styles';

const SafetyTrainingActivity = () => {
  const classes = useStyles();

  return (
    <div className={classes.staContainer}>
      <Text className="sheader">SAFETY TRAINING ACTIVITY</Text>
      <div className={classes.badgeContainer}>
        <div>
          <img src={badge} alt="Badge" />
          <div className="label">
            <Text className="atitle">Total Plays</Text>
            <Text className="value">7882</Text>
          </div>
        </div>
        <div>
          <img src={badge} alt="Badge" />
          <div className="label">
            <Text className="atitle">Total Passes</Text>
            <Text className="value">1763</Text>
          </div>
        </div>
        <div>
          <img src={badge} alt="Badge" />
          <div className="label">
            <Text className="atitle">Total Fails</Text>
            <Text className="value">587</Text>
          </div>
        </div>
        <div>
          <img src={badge} alt="Badge" />
          <div className="label">
            <Text className="atitle">Average Score</Text>
            <Text className="value">17/35</Text>
          </div>
        </div>
        <div>
          <img src={badge} alt="Badge" />
          <div className="label">
            <Text className="atitle">Average Time</Text>
            <Text className="value">5m 15s</Text>
          </div>
        </div>
        <div>
          <img src={badge} alt="Badge" />
          <div className="label">
            <Text className="atitle">Total Shares</Text>
            <Text className="value">73</Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SafetyTrainingActivity;
