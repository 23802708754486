/* eslint-disable react/prop-types */
import React from 'react';
import { Switch as MUISwitch, FormControlLabel } from '@material-ui/core';

const Switch = (props) => {
  const { label, labelPlacement, value, ...rest } = props;

  if (label) {
    return (
      <FormControlLabel
        label={label}
        labelPlacement={labelPlacement || 'end'}
        value={value}
        control={<MUISwitch {...rest} />}
      />
    );
  }

  return (
    <MUISwitch
      {...rest}
    />
  );
};

export default Switch;
