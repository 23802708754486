import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  badgeContainer: {
    maxWidth: '50%',
  },
  badgeImg: {
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  badgeOpc: {
    width: '100%',
    opacity: '0.20',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
