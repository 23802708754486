/**
 * Layout Component
 */
import React from 'react';
import DevConsole from 'utils/DevConsole';
import Header from './Header';
import Menu from './Menu';
import MenuPadding from './MenuPadding';
import RouterSwitch from './RouterSwitch';
import Footer from './Footer';

const dev = new DevConsole('Layout');
/**
 * Layout Component
 *
 * Groups child components that form the layout.
 *
 * @returns {React.Component}
 */
function Layout() {
  dev.log('Render');
  return (
    <>
      <Menu />
      <MenuPadding>
        <Header />
        <RouterSwitch />
        <Footer />
      </MenuPadding>
    </>
  );
}

export default Layout;
