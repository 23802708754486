import React from 'react';
import DevConsole from 'utils/DevConsole';

const dev = new DevConsole('useSort').mute();

const asc = (a, b, orderKey) => {
  if (a[orderKey] < b[orderKey]) { return -1; }
  if (a[orderKey] > b[orderKey]) { return 1; }
  return 0;
};

const useSort = (rows, key) => {
  const [sortOrder, setsortOrder] = React.useState('asc');
  const [orderKey, setorderKey] = React.useState(key);

  const sort = sortOrder === 'asc' ? (a, b) => asc(a, b, orderKey) : (a, b) => -asc(a, b, orderKey);

  const sortedTable = rows.map((row, index) => {
    return [row, index];
  }).sort((a, b) => {
    dev.log({ a, b });
    const sorted = sort(a[0], b[0]);
    if (sorted !== 0) return sorted;
    return a[1] - b[1];
  })
    .map((row) => row[0]);

  const handleSort = (column) => () => {
    const isAsc = orderKey === column && sortOrder === 'asc';
    dev.log(isAsc);
    setsortOrder(isAsc ? 'desc' : 'asc');
    setorderKey(column);
  };

  dev.group('useSort');
  dev.ungroup();

  return {
    sortedTable,
    handleSort,
    sortOrder,
    orderKey,
  };
};

export default useSort;
