import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardActions,
  CardContent,
  Grid,
} from '@material-ui/core';
import logo2x from 'assets/images/logo-notag-black-2x.png';
import { Text, TextField, Button } from 'components/UI';
import { NotConfirmed, ConfirmationEmpty, ExpiredCode } from 'utils/errors';
import DevConsole from 'utils/DevConsole';
import useCustomSnackbar from 'hooks/useCustomSnackbar';
import useAuthentication from 'hooks/useAuthentication';
import useStyles from './styles';

const dev = new DevConsole('Confirmation');

/**
 * Confirmation component for sign up and sign in
 *
 * @param {object} props
 * @param {string} props.email
 * @param {Function} props.setNeedConfirmation
 * @returns {React.component}
 */
const Confirmation = ({ email, setNeedConfirmation }) => {
  const classes = useStyles();
  const history = useHistory();
  const authentication = useAuthentication();
  const [code, setCode] = React.useState('');
  const [showSnackbar, closeSnackbar] = useCustomSnackbar({
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
  });
  // eslint-

  const onHandleConfirm = async (event) => {
    event.preventDefault();
    authentication.setEmail(email);

    const result = await authentication.confirmSignUp(code);
    dev.log(result);

    if (result.error === NotConfirmed) {
      showSnackbar('Confirmation failed!', { variant: 'error' });
    } else if (result.error === ConfirmationEmpty) {
      showSnackbar('Confirmation code cannot be empty', { variant: 'warning' });
    } else if (result.error === ExpiredCode) {
      showSnackbar('Code expired', { variant: 'error' });
    }

    if (result.success) {
      closeSnackbar();
      setNeedConfirmation(false); // Change state in SignIn to show sign in form again since we're at /signin already
      history.push('/signin'); // If-check in here to see if we're still at /signin?
    }
  };

  const resendConfirmationCode = async () => {
    authentication.setEmail(email);
    const resend = await authentication.resendConfirmationCode();
    if (resend.success) {
      showSnackbar('Code resent!', { variant: 'success' });
    } else {
      showSnackbar('Resend failed!', { variant: 'error' });
    }
  };

  return (
    <div>
      <Card className={classes.card}>
        <CardContent className={classes.flexContent}>
          <form id="SignUp" onSubmit={onHandleConfirm}>
            <Grid container alignContent="flex-start">
              <div className={classes.flexLogo}>
                <Grid item xs={12} md={3}>
                  <img className={classes.logo} src={logo2x} alt="LOCI" />
                </Grid>
                <Grid item xs={false} md={1} />
                <Grid item xs={12} md={8}>
                  <Text className={classes.titleText}>The Evolution of Safety</Text>
                </Grid>
              </div>
              <Grid item xs={12} lg={6}>
                <TextField
                  name="code"
                  type="text"
                  helperText={<span className={classes.formLabel}>CODE</span>}
                  handler={setCode}
                  fullWidth={false}
                />
              </Grid>
            </Grid>
            <CardActions className={classes.flexActions}>
              <Button
                variant="text"
                onClick={resendConfirmationCode}
              >
                RESEND
              </Button>
              <Button
                className={classes.signUp}
                type="submit"
                variant="contained"
              >
                CONFIRM
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

Confirmation.propTypes = {
  email: PropTypes.string.isRequired,
  setNeedConfirmation: PropTypes.func.isRequired,
};

export default Confirmation;
