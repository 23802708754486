import React from 'react';
import Recoil from 'recoil';
import { routingState } from 'store/atoms';
import { Route, Switch } from 'react-router-dom';
import { expandAllRoutes } from 'utils/routing';
import DevConsole from 'utils/DevConsole';
import NotFound from 'pages/NotFound';
import './styles.scss';

const dev = new DevConsole('RouterSwitch');

/**
 * RouterSwitch Component
 *
 * Gets routes from
 *
 * @returns {React.Component}
 */
function RouterSwitch() {
  dev.log('Render');
  const routing = Recoil.useRecoilValue(routingState);

  return (
    <main className="main">
      <Switch>
        {expandAllRoutes(routing.routes)}
        <Route component={NotFound} />
      </Switch>
    </main>
  );
}

export default RouterSwitch;
