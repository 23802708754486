import React from 'react';
import { Text, BorderLinearProgress } from 'components/UI';

import useStyles from './styles';

const LanguageSummary = () => {
  const classes = useStyles();

  const languageData = [
    { name: 'English', total: 50 },
    { name: 'Spanish', total: 15 },
    { name: 'French Canadian', total: 15 },
    { name: 'Korean', total: 10 },
    { name: 'Japanese', total: 10 },
    { name: 'Italian', total: 10 },
    { name: 'Arabic', total: 10 },
    { name: 'Tagalog', total: 7 },
    { name: 'Cree', total: 7 },
    { name: 'Pashto', total: 7 },
    { name: 'Vietnamese', total: 7 },
    { name: 'Punjabi', total: 5 },
    { name: 'Madarin', total: 5 },
    { name: 'Cantonese', total: 5 },
    { name: 'Portuguese', total: 5 },
    { name: 'Urdu', total: 5 },
  ];

  return (
    <div className={classes.languagesContainer}>
      <div className={classes.titleContainer}>
        <Text className="title">LANGUAGE SUMMARY</Text>
        <Text className="value">17 Languages</Text>
      </div>
      {languageData.map((value, index) => {
        return (
          <div key={index} className={classes.languageBar}>
            <Text>{value.name}</Text>
            <BorderLinearProgress variant="determinate" value={value.total} />
          </div>
        );
      })}
    </div>
  );
};

export default LanguageSummary;
