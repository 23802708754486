import Recoil from 'recoil';
import { guest } from 'API/services';
import { guestUserIdState, courseState } from './atoms';

const profileState = Recoil.selector({
  key: 'profileState',
  get: async ({ get }) => {
    const COMPANY_ID = 'LOCI';
    const courseName = get(courseState);

    const gdata = await guest.get({
      companyId: COMPANY_ID,
      guestId: get(guestUserIdState),
      course: courseName,
    });

    return gdata.items;
  },
});

export {
  profileState,
};
