/* eslint-disable no-useless-computed-key */
import { makeStyles } from '@material-ui/core';
import { tooltipClasses } from '@mui/material';
import DevConsole from 'utils/DevConsole';

const dev = new DevConsole('TooltipStyles');

dev.log(tooltipClasses);

const useStyles = makeStyles(() => ({
  titleArea: {
    display: 'flex',
    alignItems: 'center',
  },
  titleText: {
    fontSize: '1rem',
    paddingRight: '0.25rem',
  },
  editField: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    border: 'none',
    borderBottom: '1px solid black',
  },
}));

export default useStyles;
