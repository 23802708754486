import Recoil from 'recoil';

const toggleTipState = Recoil.atom({
  key: 'toggleTipState',
  default: true,
});

const tipText = Recoil.atom({
  key: 'tipText',
  default: '',
});

const tipId = Recoil.atom({
  key: 'tipId',
  default: '',
});

const toolTipState = Recoil.atom({
  key: 'toolTipState',
  default: [],
});

const isEditingState = Recoil.atom({
  key: 'isEditingState',
  default: false,
});

export {
  tipText,
  tipId,
  toolTipState,
  toggleTipState,
  isEditingState,
};
