import React from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
} from '@material-ui/core';
import DevConsole from 'utils/DevConsole';
import languages from 'config/languages';
import { formatText } from 'utils/formatText';
import { v4 as uuid } from 'uuid';

const dev = new DevConsole('CollapsibleRow');
dev.mute();

const CollapsibleRow = React.memo(({ answers, playThroughs, renderMixedData }) => {
  dev.log('Rendered');
  const right = {
    backgroundColor: 'lightgreen',
  };
  const wrong = {
    backgroundColor: 'violet',
  };

  const answerMap = Object.entries(answers).map(item => (
    <TableRow key={uuid()}>
      <TableCell component="th" scope="row">{item[0]}</TableCell>
      <TableCell>
        <div>{item[1].playerAnswer}</div>
      </TableCell>
      <TableCell>
        <div>{item[1].correctAnswer}</div>
      </TableCell>
      <TableCell style={item[1].playerAnswer === item[1].correctAnswer ? right : wrong}>
        <div>{renderMixedData(item[1])}</div>
      </TableCell>
    </TableRow>
  ));

  // We have a nested array that contains objects, using entries here to de-nest
  const playMap = playThroughs !== undefined ? playThroughs.map(play => {
    const playArr = Object.entries(play);
    return playArr;
  }) : null;

  const checkLang = (entryVal) => {
    const langCodes = languages.map(lang => {
      const { code, keyword } = lang;
      return {
        code,
        keyword,
      };
    });

    const langMatch = langCodes.find(lang => lang.code === entryVal);

    return langMatch === undefined ? entryVal : langMatch.keyword;
  };

  return (
    <>
      {playThroughs !== undefined ? playMap.map((item) => {
        return item.sort().map(play => {
          return (
            <TableRow key={uuid()}>
              <TableCell component="th" scope="row">{formatText(play[0])}</TableCell>
              <TableCell>
                <div>{play[1].playerAnswer}</div>
              </TableCell>
              <TableCell>
                <div>{play[1].correctAnswer}</div>
              </TableCell>
              <TableCell style={play[1].playerAnswer === play[1].correctAnswer ? right : wrong}>
                <div>{checkLang(renderMixedData(play[1]))}</div>
              </TableCell>
            </TableRow>
          );
        });
      })
        : answerMap}
    </>
  );
});

CollapsibleRow.propTypes = {
  answers: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  playThroughs: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  renderMixedData: PropTypes.func.isRequired,
};

export default CollapsibleRow;
