/**
 * Layout.Header Component
 *
 * Notice how we move the "Sign in" component out, and use Recoil for state management,
 * while we keep the Popover for the "signed in menu" local.  This is by design,
 * to showcase both local & global/recoil state management.
 */
import React from 'react';
import Recoil from 'recoil';
import clsx from 'clsx';
import {
  Grid, AppBar,
} from '@material-ui/core';
import Icon from 'components/UI/Icon';
import { menuState } from 'store/atoms';
import useAuthentication from 'hooks/useAuthentication';
import AccountMenu from './AccountMenu';
import useStyles from './styles';

/**
 * Header Component
 *
 * @returns {React.Component}
 */
function Header() {
  const classes = useStyles();
  const authentication = useAuthentication();

  // Global state
  const open = Recoil.useRecoilValue(menuState);

  // Local State
  const [userMenuOpen, setUserMenuOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onClickUserIcon = event => {
    if (authentication.attributes.sub) {
      setUserMenuOpen(true);
      setAnchorEl(event.currentTarget);
    }
  };

  // Helpers
  const getNotifications = () => 2;

  return authentication.restrictAccess(
    <AppBar
      position="relative"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid container item xs={1} justifyContent="center" />
        <Grid container item justifyContent="flex-end">
          <span className={classes.emailBadge}>
            {
              authentication.attributes.name
                ? <span>{`${authentication.attributes.name} ${authentication.attributes.family_name} ${authentication.attributes.email}`}</span>
                : authentication.attributes.email
            }
          </span>
          <Icon
            badge={getNotifications()}
            onClick={onClickUserIcon}
          />
          <AccountMenu
            open={userMenuOpen && !!authentication.attributes.sub}
            setMenuOpen={setUserMenuOpen}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
          />
        </Grid>
      </Grid>
    </AppBar>,
  );
}

export default Header;
