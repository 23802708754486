import TransitionComponent from '@material-ui/core/Slide';
import { useSnackbar } from 'notistack';

/**
 * useCustomSnackbar
 *
 * A simple hook that returns a notistack useSnackbar hook with preset config.
 * Since this is used in many places, it keeps the code clean.
 *
 * Usage:
 * const [showSnackbar, closeSnackbar] = useCustomSnackbar();
 *
 * @param {object} [hookOpts] - Optional options passed to every call
 * @returns {Array} [showSnackbar, closeSnackbar];
 */
function useCustomSnackbar(hookOpts) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return [
    (message, opts) => enqueueSnackbar(message, {
      preventDuplicate: true,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      variant: 'success',
      action: () => null,
      TransitionComponent,
      ...hookOpts,
      ...opts,
    }),
    closeSnackbar];
}


export default useCustomSnackbar;
