/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react';
import { Text } from 'components/UI';
import { Box } from '@mui/material';
// Recoil State
import Recoil from 'recoil';
import {
  selectedLanguageState,
} from 'store/atoms';
// hooks
// utils
import DevConsole from 'utils/DevConsole';
import PropTypes from 'prop-types';
import useStyles from './styles';

const dev = new DevConsole('TooltipTitle').mute();

// TODO write unit tests for TipTitle API requests
const TipTitle = ({ id, tips, text }) => {
  dev.log('Rendered');

  // Recoil
  const language = Recoil.useRecoilValue(selectedLanguageState);

  // local state
  // Match translation item keyword to elementId
  const keyword = tips.find(item => item.keyword === id);
  const [tiptext] = React.useState(keyword);

  const classes = useStyles();

  dev.group('TIPTITLE');
  dev.log(id);
  dev.log(tips);
  dev.log(tiptext);
  dev.ungroup();

  return (
    <Box className={classes.titleArea}>
      <div className={classes.editField}>
        <Text
          className={classes.titleText}
          color="primary"
          variant="body2"
        >
          {tiptext?.translations[language] || text}
        </Text>
      </div>
    </Box>
  );
};

TipTitle.propTypes = {
  id: PropTypes.string.isRequired,
  tips: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  text: PropTypes.string.isRequired,
};

export default TipTitle;
