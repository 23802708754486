import React from 'react';
import Recoil from 'recoil';
import _ from 'lodash';
import { Text as T, Button } from 'components/UI';
import { localization } from 'API/services';
import DevConsole from 'utils/DevConsole';
import {
  productIdState,
  groupsListState,
} from 'store/atoms';
import TranslationGroup from './TranslationGroup';
import useStyles from './styles';


const dev = new DevConsole('TranslationContent').mute();


/**
 * Translation Item
 *
 * @returns {React.Component}
 */
function TranslationContent() {
  const productId = Recoil.useRecoilValue(productIdState);
  const [groupsList, setGroupsList] = Recoil.useRecoilState(groupsListState);
  const classes = useStyles();

  const onCreateGroup = async () => {
    try {
      const result = await localization.group.create({
        body: {
          productId,
        },
      });
      setGroupsList(_.concat(groupsList, result.item));
    } catch (e) {
      dev.error(e.response);
    }
  };

  dev.log('render');

  return (
    <>
      { groupsList.length
      // ? _.sortBy(groups, g => g.groupName).map((group, index) => (
        ? groupsList.map((group, index) => (
          <TranslationGroup
            index={index}
            listOrder={index}
            key={group.uuid}
          />
        ))
        : <T p>No groups yet. Please create one to add translations.</T>}
      <div className={classes.controls}>
        <Button variant="contained" onClick={onCreateGroup}>Add scene</Button>
      </div>
    </>
  );
}

export default TranslationContent;
