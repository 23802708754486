/* eslint-disable react/prop-types */
import React from 'react';
import {
  FormControl as MUIFormControl,
  InputLabel as MUIInputLabel,
  Select as MUISelect,
} from '@material-ui/core';

const SelectField = (props) => {
  const {
    name,
    label,
    handler,
    children,
    defaultValue,
    value,
    outlined,
    fullWidth,
    options,
    ...rest
  } = props;
  const labelRef = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const [val, setValue] = React.useState(defaultValue || '');
  const usedValue = value !== undefined ? value : val;

  React.useEffect(() => {
    setLabelWidth(labelRef.current.offsetWidth);
  }, []);

  const onChange = props.onChange ? props.onChange : (event) => {
    setValue(event.target.value);
    if (handler) {
      handler(event.target.value);
    }
  };

  return (
    <MUIFormControl
      variant={outlined ? 'outlined' : 'standard'}
      fullWidth={fullWidth}
    >
      <MUIInputLabel ref={labelRef} htmlFor={name}>{label}</MUIInputLabel>
      <MUISelect
        value={usedValue}
        onChange={onChange}
        labelWidth={labelWidth}
        inputProps={{
          name,
          id: name,
        }}
        {...rest}
      >
        {options || children}
      </MUISelect>
    </MUIFormControl>
  );
};

export default SelectField;
