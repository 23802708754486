import React from 'react';
import DevConsole from 'utils/DevConsole';
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Collapse,
  Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import CollapsibleRow from './CollapsibleRow';
import GuestProfile from '../../Profile';


const dev = new DevConsole('CollapsibleTable');
dev.mute();
/**
 * Collapsible Table component that renders any nested data from JSON data into a mini table.
 *
 * @param {object} props
 * @param {object} props.answers - data from GSI concerning player performance
 * @param {Array} props.playThroughs - array of multiple plays of the game
 * @param {boolean} props.open - open/close prop, controlled by Row component
 * @param {string} props.date - the date the game was played
 * @param {number} props.score - the most recent score
 * @param {number} props.progress - how the far the user got
 * @returns {React.Component}
 */
const CollapsibleTable = React.memo(({
  answers,
  playThroughs,
  open,
  date,
  score,
  progress,
}) => {
  dev.log('Rendered');
  const renderMixedData = (entry) => {
    const checkPrimitiveType = (prop) => {
      const propIsStr = typeof prop === 'string' ? prop : null;
      const propIsNum = typeof prop === 'number' ? prop : null;
      return {
        propIsStr,
        propIsNum,
      };
    };
    switch (typeof entry) {
      case 'object':
        return entry.playerAnswer === entry.correctAnswer ? 'CORRECT' : 'INCORRECT';
      case 'string':
        return checkPrimitiveType(entry).propIsStr;
      case 'number':
        return checkPrimitiveType(entry).propIsNum;
      default:
        return new Error('Entry is not a string, object, or number');
    }
  };
  return (
    <>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <GuestProfile score={score} progress={progress} />
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><div>Level</div></TableCell>
                    <TableCell><div>Answer</div></TableCell>
                    <TableCell><div>Correct</div></TableCell>
                    <TableCell><div>Status</div></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <CollapsibleRow
                    key={uuid()}
                    answers={answers}
                    playThroughs={playThroughs}
                    renderMixedData={renderMixedData}
                    date={date}
                  />
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>

  );
});

CollapsibleTable.propTypes = {
  answers: PropTypes.any.isRequired,
  playThroughs: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  date: PropTypes.string.isRequired,
  score: PropTypes.any.isRequired,
  progress: PropTypes.any.isRequired,
};

export default CollapsibleTable;
