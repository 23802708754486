import React from 'react';
import useAuthentication from 'hooks/useAuthentication';
import { Button } from 'components/UI';
import html2canvas from 'html2canvas';
import { jsPDF as JSPDF } from 'jspdf';
import DevConsole from 'utils/DevConsole';
import clsx from 'clsx';

import ReportsHeader from './ReportsHeader';
import ReportsNextPageHeader from './ReportsNextPageHeader';
import SafetyTrainingActivity from './SafetyTrainingActivity';
import RankingsTotalPlays from './RankingsTotalPlays';
import OverallResults from './OverallResults';
import LanguageSummary from './LanguageSummary';
import AverageScoreAttempt from './AverageScoreAttempt';
import Legend from './Legend';

import useStyles from './styles';

const dev = new DevConsole('Reports').mute();

/**
 * Reports Page
 *
 * @returns {React.Component}
 */
function Reports() {
  const classes = useStyles();
  const authentication = useAuthentication({
    unauthenticatedRedirect: 'SignIn',
    egressRedirect: 'Home',
    group: 'Admins',
  });

  const downloadPdf = async (e) => {
    e.preventDefault();

    // Get List of paged elements using the reports class.
    const elems = document.querySelectorAll('.reports');
    const pdf = new JSPDF('p', 'mm', 'a4');

    // Fix Graphics Output by scaling PDF and html2canvas output to 3
    pdf.scaleFactor = 3;

    // Create a new promise with the loop body
    // Loop through each elements and capture each reports as a page
    const promises = [];
    const resolvers = [];

    // Create new empty promise to make the promise all work and assign resolvers to it
    elems.forEach((elem, idx) => {
      promises.push(new Promise((res) => { resolvers[idx] = res; }));
    });

    // Grabbing all of the canvas promises from html2canvas
    elems.forEach(async (elem, idx) => {
      dev.log(`idx: ${idx}, length: ${elems.length - 1}`);

      // Scaling fix set scale to 3
      const res = await html2canvas(elem, {
        scale: 3,
      });
      dev.log('res: ', res);

      if (res) {
        resolvers[idx](res); // fix for the memory leak issue
      }

      promises[idx] = resolvers[idx](res);
    });

    dev.log('promises: ', promises);
    const result = await Promise.all(promises);
    dev.log(`result: ${result}`);

    result.forEach((canvas, idx) => {
      dev.log(`res idx: ${idx}, length: ${result.length - 1}`);

      const imgData = canvas.toDataURL('image/png', 1.0);
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      if (idx < result.length - 1) {
        dev.log('Adding pages still completing');
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.addPage();
      } else {
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        dev.log('Reached last page...');
      }
    });

    dev.log('Saving PDF');
    pdf.save(`reports_${new Date().toISOString()}.pdf`);
  };

  return authentication.restrictAccess(
    <div className={classes.root}>
      <div className={classes.settingsContainer}>
        <Button onClick={downloadPdf} id="print">DOWNLOAD</Button>
      </div>
      <div className={classes.clearFloat} />
      <div>
        {/* START: Reports Over-all Container */}
        <div className={classes.reportsContainer}>
          {/* START: Reports P1 */}
          <div className={clsx(classes.reportContainer, 'reports')}>
            <ReportsHeader />
            <SafetyTrainingActivity />
            <RankingsTotalPlays />
            <OverallResults />
          </div>
          {/* END: Reports P1 */}
          {/* START: Reports P2 */}
          <div className={clsx(classes.reportContainer, 'reports')}>
            <ReportsNextPageHeader />
            <div className={classes.nextPageContainer}>
              <div className={classes.langAveContainer}>
                <LanguageSummary />
                <div className={classes.aveLegendContainer}>
                  <AverageScoreAttempt />
                  <Legend />
                </div>
              </div>
            </div>
          </div>
          {/* END: Reports P2 */}
        </div>
        {/* END: Reports Over-all Container */}
      </div>
    </div>,
  );
}

export default Reports;
