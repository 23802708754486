import React from 'react';
import { AdminDrawer } from 'components/Layout';
import { CustomCourse } from 'components/UI';
import useAuthentication from 'hooks/useAuthentication';
import useStyles from './styles';

/**
 * New Course Page
 *
 * @returns {React.Component}
 */
function NewCourse() {
  const classes = useStyles();
  const authentication = useAuthentication({
    unauthenticatedRedirect: 'SignIn',
    egressRedirect: 'Home',
    group: 'Admins',
  });

  return authentication.restrictAccess(
    <div className={classes.root}>
      <AdminDrawer />
      <CustomCourse />
    </div>,
  );
}

export default NewCourse;
