/**
 * convertAPIDataToRows
 *
 * Convert the API result items to rows useable by the DataGrid
 * which needs an id in the array for rows and follow the field
 * name for the columns to match the data rendered
 *
 * @param {object} options - any other params we need
 *
 * @returns {object}
 */
function convertAPIDataToRows(options) {
  switch (options.service) {
    case 'accessKey':
      return options.stateUpdater(
        options.rowsToClean.map(({ uuid, languageOverride, ...rest }) => {
          return {
            id: uuid,
            languageOverride: languageOverride || 'N/A',
            ...rest,
          };
        }),
      );
    case 'guest':
      return options.stateUpdater(
        options.rowsToClean.map(({ guestId, location, ...rest }) => {
          return {
            id: guestId,
            location: location || 'N/A',
            ...rest,
          };
        }),
      );
    default:
      throw new Error('No service defined');
  }
}

export default convertAPIDataToRows;
