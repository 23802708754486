// Line below is to resolve various fixes in StrictMode.
// More info found at https://github.com/mui-org/material-ui/issues/13394
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
// import { grey } from '@material-ui/core/colors';

// [TODO]: Look into Overrides and other theme-ing options for MUI
export default createMuiTheme({
  palette: {
    primary: {
      main: '#004D26',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FF580F',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#FF580F',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FF580F',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#004D26',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#004D26',
      contrastText: '#FFFFFF',
    },
    components: {
      MuiLinearProgress: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: '#d9e650',
          },
          barColorPrimary: {
            backgroundColor: '#d9e650',
          },
          bar1Determinate: {
            backgroundColor: '#d9e650',
          },
        },
      },
    },
  },
});
