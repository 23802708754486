import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import data from './courses';
import './styles.scss';

/**
 * Course Component
 *
 * @param {object} item
 * @returns {React.Component}
 */
function courseItem(item) {
  return (
    <Grid item sm={6} md={6} lg={item.type === 'custom' ? 3 : 4} key={item.id}>
      <Card className={`course-card type-${item.type}`}>
        <div className="course-img-container">
          <img src={item.icon} alt="course-icons" className="course-img" />
        </div>
        <CardHeader className="card-title" title={item.title} />
        <CardContent>
          <Typography className="course-subtitle">
            {item.type !== 'custom' ? <span>{item.subtitle}</span> : (
              <span>
                {item.subtitle1}
                {item.subtitle2}
              </span>
            )}
          </Typography>
          <Typography className="course-description">
            <span>{item.description1}</span>
            <span>{item.description2}</span>
            <span>{item.description3}</span>
            <span>{item.description4}</span>
            <span>{item.description5}</span>
          </Typography>
          {item.type !== 'custom' && <Link href="/course" className="custom-link">LEARN MORE</Link>}
          <CardActions>
            <Button className="custom-btn">
              {item.type === 'custom' ? 'SELECT' : 'SELECT THIS COURSE'}
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </Grid>
  );
}

/**
 * Organizations Page
 *
 * @returns {React.Component}
 */
function courseItems() {
  return (
    <div className="page-container course-cards">
      <h1>Create New Course </h1>
      <h2>Choose Template Course</h2>
      <CardContent>
        <Grid className="course-container" container>
          {data.courses.map((item) => courseItem(item))}
        </Grid>
      </CardContent>
      <h2>Create Custom Course</h2>
      <CardContent>
        <Grid className="course-container" container>
          {data.customCourses.map((item) => courseItem(item))}
          <div className="course-next">
            <span>NEXT</span>
            <IconButton
              className="course-next-icon"
              size="medium"
            >
              <ArrowForwardIcon className="course-next-arrow" />
            </IconButton>
          </div>
        </Grid>
      </CardContent>
    </div>
  );
}

export default courseItems;
