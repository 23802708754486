import Recoil from 'recoil';
import { tooltip } from 'API/services';
import { tipId } from 'store/atoms';
import DevConsole from 'utils/DevConsole';

const dev = new DevConsole('tipTextState');

// TODO Stop screen flash when tooltips load individually.
const tipTextState = Recoil.selector({
  key: 'tipTextState',
  get: async ({ get }) => {
    const COMPANY_ID = 'LOCI';
    const id = get(tipId);

    const result = await tooltip.get({
      productId: COMPANY_ID,
      elementId: id,
    });

    const value = result.item?.tiptext || 'Some really useful UI here';

    dev.group('TIPTEXT API RESPONSE');
    dev.log(value);
    dev.ungroup();

    return value;
  },
});

// TODO look into mapping over what we have in the API for tooltip editor
const allTipState = Recoil.selector({
  key: 'allTipState',
  get: async ({ get }) => {
    const elementId = get(tipId);
    const all = await tooltip.get({
      productId: 'LOCI',
    });
    const allTips = all.items;

    dev.log(elementId);

    return allTips;
  },
});

export {
  tipTextState,
  allTipState,
};
