/**
 * These are the default session values that get inserted in the user session context
 * if the user is not signed in; in other words, for guests.
 *
 * If you want to check if getAuthenticatedUser finished running, check for null instead.
 */
export default {
  // Cognito values:
  attributes: {
    email: null,
    email_verified: false,
    sub: null,
    name: 'Guest',
  },
  // Custom values:
  referer: null,
};
