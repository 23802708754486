import Recoil from 'recoil';

const getGuestUserId = () => {
  return '';
};

const guestUserIdState = Recoil.atom({
  key: 'guestUserIdState',
  default: getGuestUserId(),
});

const courseState = Recoil.atom({
  key: 'courseState',
  default: '',
});

const profileDataState = Recoil.atom({
  key: 'profileDataState',
  default: [],
});

const lastEvalKey = Recoil.atom({
  key: 'lastEvalKey',
  default: '',
});

const showUpButtonState = Recoil.atom({
  key: 'showGoButtonState',
  default: false,
});

export {
  guestUserIdState,
  courseState,
  profileDataState,
  lastEvalKey,
  showUpButtonState,
};
