/**
 * Default feature flags value.
 *
 * When the app runs, we hit the API to get the latest feature flag values.
 * These are fail-safe values in case we don't have a connection, or for when we mock the API in unit tests.
 */
export default [
  {
    uuid: '6aaf4d7e-4f5c-409b-95a5-ef8c6142aa75',
    keyword: 'Maintenance Mode',
    description: 'Turning this flag ON will cause maintenance page to be displayed.',
    password: null,
    enabled: false,
    ratio: 50,
    props: {
      text: 'My LOCI is under maintenance. Please come back later.',
    },
  },
];
