/* eslint-disable react/prop-types */
import React from 'react';
import { Text } from 'components/UI';
import {
  PieChart,
  Pie,
  Cell,
  Label,
  LabelList,
} from 'recharts';

import novicebadge from 'assets/images/reports/germ_novice_badge_small@3x.png';
import rookiebadge from 'assets/images/reports/germ_rookie_badge_small@3x.png';
import scientistbadge from 'assets/images/reports/germ_scientist_badge_small@3x.png';

import useStyles from './styles';

const RankingsTotalPlays = () => {
  const classes = useStyles();

  const pieColors = ['#00BA6C', '#002EE2', '#C500FF'];
  const data01 = [{ name: 'Total Plays', value: 7882 }];
  const data02 = [
    { name: '1 Play', value: 1402 },
    { name: '2 Plays', value: 5201 },
    { name: '3+ Plays', value: 742 },
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = (props) => {
    const { value, offset, fill } = props;
    const { cx, cy, startAngle, endAngle, innerRadius, outerRadius } = props.viewBox;
    const radius = offset + innerRadius + (outerRadius - innerRadius) * 0.5;
    const midAngle = (startAngle + endAngle) / 2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = typeof value === 'number' ? cy + radius * Math.sin(-midAngle * RADIAN) : cy + radius * Math.sin(-midAngle * RADIAN) - (offset - 5);

    return (
      <g>
        <text
          x={x}
          y={y}
          className={classes.chartLabel}
          textAnchor={x > cx ? 'start' : 'end'}
          fontSize={typeof value === 'number' ? '1.75vw' : '1vw'}
          fill={fill}
          dominantBaseline="central"
          fontWeight="bold"
        >
          {value}
        </text>
      </g>
    );
  };

  return (
    <div className={classes.rankingsContainer}>
      <Text className="rheader">RANKINGS BASED ON TOTAL PLAYS</Text>
      <div className={classes.rankChartContainer}>
        <div className={classes.rankings}>
          <div className={classes.ranks}>
            <div className={classes.smallNBadgeContainer}>
              <img src={novicebadge} alt="Badge" />
            </div>
            <div className="label">
              <Text className="rvalue">475</Text>
              <Text className="rtitle">GERM NOVICE</Text>
            </div>
          </div>
          <div className={classes.ranks}>
            <div className={classes.smallRBadgeContainer}>
              <img src={rookiebadge} alt="Badge" />
            </div>
            <div className="label">
              <Text className="rvalue">765</Text>
              <Text className="rtitle">GERM ROOKIE</Text>
            </div>
          </div>
          <div className={classes.ranks}>
            <div className={classes.smallSBadgeContainer}>
              <img src={scientistbadge} alt="Badge" />
            </div>
            <div className="label">
              <Text className="rvalue">1000</Text>
              <Text className="rtitle">GERM SCIENTIST</Text>
            </div>
          </div>
        </div>
        <div className={classes.totalPieChart}>
          <Text className="ptitle">TOTAL PLAYS</Text>
          <Text className="pdate">Nov 1 - Nov 7</Text>
          <div className={classes.pieChart}>
            <PieChart width={450} height={300}>
              <Pie
                isAnimationActive={false}
                data={data01}
                dataKey="value"
                outerRadius={100}
                fill="transparent"
                stroke="none"
                className={classes.labelFormat}
              >
                {data01.map((entry, index) => (
                  <Label
                    key={index}
                    value={entry.value}
                    offset={0}
                    fontSize="2.5vw"
                    fontWeight="bold"
                    position="center"
                  />
                ))}
              </Pie>
              <Pie
                isAnimationActive={false}
                data={data02}
                dataKey="value"
                innerRadius={100}
                outerRadius={120}
                labelLine={false}
                className={classes.labelFormat}
                paddingAngle={5}
              >
                {data02.map((entry, index) => <Cell key={`cellp-${index}`} fill={pieColors[index % pieColors.length]} />)}
                <LabelList dataKey="value" offset={30} content={renderCustomizedLabel} />
                <LabelList dataKey="name" offset={30} content={renderCustomizedLabel} />
              </Pie>
            </PieChart>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RankingsTotalPlays;
