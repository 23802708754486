import moment from 'moment';

const getTimeElapsed = (start, end, time) => {
  const date1 = new Date(start);
  const date2 = new Date(end);
  const minutes = moment.duration(Math.abs(date2 - date1, time)).minutes();
  const seconds = moment.duration(Math.abs(date2 - date1, time)).seconds();
  return time === 'minutes' ? minutes : seconds;
};

export default getTimeElapsed;
