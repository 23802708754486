import React from 'react';
import DevConsole from 'utils/DevConsole';
import getPercent from 'utils/getPercent';
import getAvgTime from 'utils/getAvgTime';
import PropTypes from 'prop-types';
import { Paper, Box, Grid } from '@material-ui/core';
import germLogo from 'assets/images/logos/gsi-logo@2x.png';
import etLogo from 'assets/images/logos/et-logo-300dpi.png';
import scLogo from 'assets/images/logos/sc-logo-300dpi.png';
import { Button } from 'components/UI';
import DashInfo from './DashInfo';
import Badge from './Badge';
import ProgressBar from './ProgressBar';
import useStyles from './styles';

const dev = new DevConsole('Dashboard');

/**
 * Takes in data and creates an interactive dashboard
 *
 * @param {string} props
 * @param {Array} props.data - data from AWS API
 * @returns {React.component}
 */
function Dashboard({ data }) {
  dev.log('Rendered');
  const classes = useStyles();

  // This only works if the fields on the data collected between different games is the same
  const numGraded = data.map(item => item.grade); // All games passed. Any user that gets a grade has passed
  const passed = numGraded.filter(item => item !== undefined && item !== 'N/A').length;
  const failed = numGraded.filter(item => item === 'N/A' || item === undefined).length;

  return (
    <Paper
      className={classes.dashboard}
      elevation={8}
      style={{
        border: '1px solid rgb(158, 187, 172)',
        marginBottom: '1.75rem',
        borderRadius: '30px',
      }}
    >
      <Grid container style={{ marginBottom: '0.75rem' }}>
        <Grid xs={2} item style={{ padding: '1.25rem' }}>
          {/* Badges that return data based on the course name */}
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Badge src={germLogo} altText="GSI logo" course="GSI" />
              <Badge src={etLogo} altText="Exit Test logo" course="ET" />
              <Badge src={scLogo} altText="Safety Check logo" course="SC" />
            </div>
          </div>
        </Grid>
        <Grid item xs={8}>
          <h1>Profile Safety Activity Dashboard</h1>
          <div className={classes.dashboardInfo}>
            <Box style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
              {/* Cards: These all have to display different calculated pieces of data */}
              <DashInfo title="Passed" calcResult={passed} />
              <DashInfo title="Failed" calcResult={failed} />
              <DashInfo title="Drop Rate" calcResult={getPercent(failed, data.length, { abs: false, reverse: true, number: false })} />
              <DashInfo title="Passing Rate" calcResult={getPercent(passed, data.length, { abs: false, reverse: true, number: false })} />
              {!data.length ? <DashInfo title="AVG Completion Time" /> : <DashInfo title="AVG Completion Time" calcResult={getAvgTime(data)} />}
            </Box>
          </div>
          <ProgressBar length={data.length} passed={passed} />
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Button variant="text">Filter by date</Button>
          <Grid item xs={1}>
            {/* [TODO]: will download a CSV copy of the data to the users system */}
            <Button style={{ width: '150px' }} outline="none">DOWNLOAD</Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

Dashboard.propTypes = {
  data: PropTypes.array.isRequired,
};

export default React.memo(Dashboard);
