import { makeStyles } from '@material-ui/core/styles';
import bgMenu from '../../../assets/images/bg-menu.png';

const menuWidth = 240;
const baigeColor = '#E8DEC9';
const yellGreen = '#D9FF31';

const useStyles = makeStyles((theme) => ({
  menu: {
    width: menuWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    backgroundColor: '#004D26',
    backgroundImage: `url(${bgMenu})`,
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
    width: menuWidth,
    borderRight: 'solid .8em #F65812',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: '#004D26',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    // width: theme.spacing(7) + 1,
    width: theme.spacing(5),
    /* [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    }, */
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  hideText: {
    color: baigeColor,
  },
  rotateText: {
    color: 'rgba(255,255,255, 0.6)',
    writingMode: 'vertical-lr',
    transform: 'rotate(180deg)',
  },
  arrowStyle: {
    color: '#ffffff',
  },
  arrowSize: {
    fontSize: '30px',
  },
  directionItems: {
    height: '100%',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  list: {
    color: baigeColor,
  },
  listItems: {
    color: baigeColor,
    textTransform: 'capitalize',
  },
  listItemText: {
    fontSize: '1.1em',
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    width: menuWidth - 15,
    ...theme.mixins.toolbar,
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  menuHeaderClosed: {
    display: 'flex',
    alignItems: 'flex',
    padding: theme.spacing(0, 1),
    width: menuWidth,
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  menuHamburguer: {
    color: '#ffffff',
    padding: '6px',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  toolbarHamburguer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  menuLogo: {
    width: '12em',
    marginLeft: '1em',
  },
  menuItem: {
    padding: '0em 1.4em',
    color: 'white',
    '&:hover': { textDecoration: 'none' },
    display: 'block',
  },
  menuLink: {
    paddingLeft: '18px',
  },
  activeItem: {
    color: yellGreen,
  },
  cardRoot: {
    margin: '0 1.5em',
    maxWidth: menuWidth - 60,
    backgroundColor: yellGreen,
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.8em 1em !important',
  },
  textCard: {
    whiteSpace: 'normal',
    fontWeight: 600,
    color: '#FF580F',
  },
  largeButtonCard: {
    width: '1em',
    height: '1em',
    backgroundColor: '#FF580F',
    '&:hover, &.Mui-focusVisible': { backgroundColor: 'rgb(0 0 0 / 25%)' },
    borderRadius: '50%',
    marginTop: '0.6em',
  },
  arrowCard: {
    color: yellGreen,
  },
  menuFooter: {
    width: '4em',
    position: 'fixed',
    bottom: 0,
    textAlign: 'center',
    paddingBottom: '2em',
    marginLeft: '1.5em',
  },
  organizationName: {
    fontSize: '2em',
    color: yellGreen,
    paddingLeft: '2rem',
  },
}));

export default useStyles;
