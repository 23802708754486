import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles({
  root: {
    width: '90%',
    margin: '0 auto',
  },
  productSelect: {
    marginBottom: 32,
  },

  accordion: {
    margin: '16px 0',
    backgroundColor: '#fff',
    border: `1px solid ${grey[200]}`,

    '& .Mui-focused': {
      backgroundColor: '#fff',
    },
  },
  info: {
    color: grey[600],
    '& > *': {
      verticalAlign: 'middle',
    },
    '& > * + *': {
      marginLeft: 16,
    },
  },
  accordionControls: {
    textAlign: 'right',

    '& > *': {
      verticalAlign: 'middle',
    },
    '& > * + *': {
      marginLeft: 8,
    },
  },


  details: {
    margin: 4,
    backgroundColor: '#fff',
  },
  controls: {
    paddingTop: 12,
    textAlign: 'right',

    '& > *': {
      verticalAlign: 'middle',
    },
    '& > * + *': {
      marginLeft: 16,
    },
  },

  textField: {
    margin: [[2, 0]],
    '& .MuiOutlinedInput-input': {
      padding: [[4, 8]],
      // backgroundColor: 'white',
      maxHeight: 60, // 3 lines
    },
    '& .MuiOutlinedInput-multiline': {
      padding: 0,
    },
  },
  codeSample: {
    margin: [[2, 0]],
    '& .MuiOutlinedInput-input': {
      padding: [[4, 8]],
      backgroundColor: grey[100],
      maxHeight: 60, // 3 lines
      fontSize: '.8em',
      fontFamily: 'monospace',
    },
  },
  ratioField: {
    margin: [[2, 0]],
    '& .MuiOutlinedInput-input': {
      padding: [[4, 8]],
      maxWidth: 32,
    },
  },
  ratioSlider: {
    marginTop: 32,
  },


  icon: {
    cursor: 'pointer',
  },

});

export default useStyles;
