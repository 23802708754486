/* eslint-disable no-restricted-syntax */
/* eslint-disable dot-notation */
import Recoil from 'recoil';
import { searchState, profileDataState } from 'store/atoms';
import DevConsole from 'utils/DevConsole';

const dev = new DevConsole('SearchAtom');
dev.mute();
const searchFilterState = Recoil.selector({
  key: 'searchFilterState',
  get: async ({ get }) => {
    const searchTerm = get(searchState).toLowerCase(); // Filter string
    const pdata = get(profileDataState);

    if (!pdata) return [];

    // Filter here based on search term.
    // We return an array with the searchTerm filtered in and the results returned in a new array.
    const searchData = pdata.filter(obj => {
      // Mapping over the keys and returning the item[key], a specific item that may or may not match the search string
      const keys = Object.keys(obj);
      return keys.some(key => {
        // Everything needs to be a string for search to work
        // CompanyId, GuestId, CourseName are all strings by default
        const searchStr = typeof obj[key] === 'string' ? obj[key].toLowerCase() : obj[key].toString();
        return searchTerm === '' ? pdata : searchStr.includes(searchTerm);
      });
    });
    dev.log('SEARCHDATA: ', searchData);
    return searchData;
  },
});


export default searchFilterState;

export {
  searchFilterState,
};
