/**
 * Layout.Menu Component
 */
import React from 'react';
import Recoil from 'recoil';
import clsx from 'clsx';
import {
  // translationState,
  selectedLanguageState,
  menuState,
  routingState,
  toggleTipState,
  organizationState,
} from 'store/atoms';
import { useLocation } from 'react-router-dom';
// import { useLocation, useHistory } from 'react-router-dom';
// Toggle Edit Mode
import ToggleButton from '@mui/material/ToggleButton';
import { useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Text, NavigationLink, Tip } from 'components/UI';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { LocalizationContext } from 'contexts/LocalizationProvider';
import useAuthentication from 'hooks/useAuthentication';
import DevConsole from 'utils/DevConsole';
import _ from 'lodash';
import useStyles from './styles';
import logoLoci from '../../../assets/images/logo-sidemenu-black.png';

const dev = new DevConsole('Menu');

/**
 * Menu
 *
 * @returns {React.Component}
 */
function Menu() {
  const classes = useStyles();
  const theme = useTheme();
  const authentication = useAuthentication();
  const location = useLocation();
  const [open, setOpen] = Recoil.useRecoilState(menuState);
  const routing = Recoil.useRecoilValue(routingState);
  const [toggleTip, setToggleTip] = Recoil.useRecoilState(toggleTipState);
  const [org, setOrg] = Recoil.useRecoilState(organizationState);
  const organizationId = localStorage.getItem('organizationId');
  const organizationName = localStorage.getItem('organizationName');

  // TODO: Replace with Recoil values
  const {
    translations,
  } = React.useContext(LocalizationContext);
  // const translations = Recoil.useRecoilValue(translationState);
  const language = Recoil.useRecoilValue(selectedLanguageState);

  // When location changes:
  React.useEffect(() => {
    setOpen(false);
  }, [location,
    setOpen]);

  const onClose = () => setOpen(true);
  const onOpen = () => setOpen(false);
  const toggleMenu = () => setOpen(!open);

  if (organizationId !== org.organizationId) {
    setOrg({
      organizationId,
      organizationName,
    });
  }

  if (!org.organizationId) {
    setOpen(true);
  }

  /**
   * Hides or Closes drawer
   *
   * @returns {React.Component}
   */
  function hideOpen() {
    if (open !== true) {
      return (
        <div className={classes.menuHeader}>
          <div className={classes.toolbar}>
            <IconButton
              onClick={onClose}
              className={classes.hideText}
              size="medium"
            >
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              <Text>Hide</Text>
            </IconButton>
          </div>
          <ToggleButton
            sx={{
              border: 'none',
              color: 'white',
              '.Mui-selected': {
                color: 'white',
              },
            }}
            size="small"
            selected={toggleTip}
            value={toggleTip}
            onChange={() => setToggleTip(!toggleTip)}
          >
            {toggleTip ? 'Disable Tips' : 'Enable Tips'}
          </ToggleButton>
        </div>
      );
    }
    return (
      <div className={classes.directionItems} onClick={onOpen} onKeyDown={onOpen} role="button" tabIndex={0}>
        {org.organizationId && (
          <>
            <Text className={classes.rotateText}>MENU</Text>
            <IconButton
              className={classes.arrowStyle}
              size="medium"
            >
              <ArrowRightIcon className={classes.arrowSize} />
            </IconButton>
            <Text className={classes.rotateText}>MENU</Text>
          </>
        )}
      </div>
    );
  }

  /**
   * New course card
   *
   * @returns {React.Component}
   */
  function createNewCourseCard() {
    return (
      <Card className={classes.cardRoot}>
        <CardContent className={classes.cardContent}>
          <span className={classes.textCard}>
            {translations.menu.newcourse[language]}
          </span>
          <Tip elementId="newcourse" text="Create a customizable safety training course">
            <IconButton
              className={classes.largeButtonCard}
              size="medium"
            >
              <ArrowForwardIcon className={classes.arrowCard} />
            </IconButton>
          </Tip>

        </CardContent>
      </Card>
    );
  }

  // const history = useHistory();
  const onHandleSignOut = async () => {
    const response = await authentication.signOut();
    dev.log(`response: ${JSON.stringify(response, null, 2)}`);
    // TODO: Check if this isn't required anymore
    // if (response.success) {
    //   history.push('/signin');
    // }
  };

  /**
   * Show items menu
   *
   * @returns {React.Component}
   */
  function showItemsMenu() {
    if (open !== true && org.organizationName) {
      return (
        <div className="side-menu">
          <div className={classes.organizationName}>{org.organizationName}</div>
          <List className={classes.list}>
            {
              _.map(routing.menuLinks, (path, keyword) => {
                return (
                  <NavigationLink
                    key={path}
                    keyword={keyword}
                    className={classes.menuItem}
                    activeClassName={classes.activeItem}
                  >
                    <ListItem button key={keyword}>
                      <Tip elementId={keyword} text={`Manage your ${keyword}`}>
                        <ListItemText
                          classes={{ primary: classes.listItemText }}
                          primary={translations.menu[keyword][language]}
                        />
                      </Tip>
                    </ListItem>
                  </NavigationLink>
                );
              })
            }
            <ListItem
              button
              onClick={onHandleSignOut}
              className={`${classes.menuLink} ${classes.menuItem}`}
            >
              <ListItemText classes={{ primary: classes.menuLink }} primary={translations.menu.signout[language]} />
            </ListItem>
          </List>
          {createNewCourseCard()}
          <img
            src={logoLoci}
            alt="logo-loci"
            className={classes.menuFooter}
          />
        </div>
      );
    }
    return (
      <div />
    );
  }

  return authentication.restrictAccess(
    <Drawer
      className={clsx(classes.menu, {
        [classes.drawerOpen]: !open,
        [classes.drawerClose]: open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: !open,
          [classes.drawerClose]: open,
        }),
      }}
      variant="permanent"
      anchor="left"
      open={open}
      onClose={onClose}
    >
      <div className={classes.toolbarHamburguer}>
        <IconButton
          className={classes.menuHamburguer}
          onClick={toggleMenu}
          size="medium"
        >
          <MenuIcon />
        </IconButton>
      </div>
      {hideOpen()}
      {showItemsMenu()}
    </Drawer>,
  );
}

export default Menu;
