import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@material-ui/core';

const menuWidth = 240;

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: 36,
  },
  avatar: {
    backgroundColor: colors.blue[300],
    color: colors.blue[700],
  },
  appBar: {
    backgroundColor: 'white',
    color: '#004D26',
    padding: '1.25vmax',
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer - 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: menuWidth,
    width: `calc(100% - ${menuWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  greeting: {
    color: '#004D26',
  },
  toolbar: {
    padding: 0,
  },
  emailBadge: {
    paddingTop: '1.5em',
    fontWeight: 500,
    color: 'black',
    '&:first-child': {
      marginRight: '0.30em',
    },
    '&:nth-child(2)': {
      marginRight: '0.75em',
    },
  },
}));

export default useStyles;
