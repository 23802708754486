import React from 'react';
import useStyles from './styles';

/**
 * 404 Page
 *
 * @returns {React.Component}
 */
function NotFound() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      404 - NOT FOUND
    </div>
  );
}

export default NotFound;
