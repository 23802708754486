/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

/**
 * Stylized horizontal line used under Text
 *
 * @param {React.props} props
 * @param {boolean} props.light - specifies the background color of the line
 * @param {string} props.w - specifies the width of the line
 * @returns {React.Component}
 */
const Hr = props => {
  const {
    light,
    w,
    ...rest
  } = props;
  const classes = makeStyles(theme => ({
    root: {
      width: w,
      height: 3,
      border: 'none',
      background: light ? '#f5f5f5' : '#546e7a',
      margin: [[theme.spacing(3),
        'auto',
        theme.spacing(4)]],
    },
  }))();
  return <hr className={classes.root} {...rest} />;
};

Hr.propTypes = {
  light: PropTypes.bool,
  w: PropTypes.string,
};

Hr.defaultProps = {
  light: false,
  w: '100px',
};

export default Hr;
