import React, { useEffect } from 'react';
import Recoil from 'recoil';
import {
  productIdState,
} from 'store/atoms';
import _ from 'lodash';
import {
  Grid,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Slider,
  Tooltip as MUITooltip,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import FlipIcon from '@material-ui/icons/Flip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteForever from '@material-ui/icons/DeleteForever';
import {
  Text as T,
  SelectField,
  Button,
  TextField,
  Switch,
} from 'components/UI';
import useAuthentication from 'hooks/useAuthentication';
import useCustomSnackbar from 'hooks/useCustomSnackbar';
import { feature } from 'API/services';
import { isolate } from 'utils/isolate';
import DevConsole from 'utils/DevConsole';
import products from 'config/products';
import useStyles from './styles';


const dev = new DevConsole('Features');

// Slider stuff
const marks = [
  { value: 1, label: '1%' },
  { value: 50, label: '50%' },
  { value: 100, label: 'Always' },
];

/**
 * Tooltip shorthand
 *
 * @param {object} props
 * @returns {React.Component}
 */
function Tooltip(props) {
  return <MUITooltip arrow placement="top" {...props} />;
}


/**
 * Features Edit Page
 *
 * @returns {React.Component}
 */
function Features() {
  const classes = useStyles();
  const [showSnackbar, closeSnackbar] = useCustomSnackbar();
  const [productId, setProductId] = Recoil.useRecoilState(productIdState);

  const [features, setFeatures] = React.useReducer((currentState, action) => {
    switch (action.type) {
      case 'set':
        return action.items;
      case 'add':
        return _.concat(currentState, action.item);
      case 'delete':
        return _.filter(currentState, f => f.uuid !== action.uuid);
      case 'update':
        return currentState.map(
          feat => (feat.uuid === action.item.uuid
            ? { ...feat, [action.key]: action.value }
            : feat),
        );
      default:
        return currentState;
    }
  }, []);

  const [expanded, setExpanded] = React.useReducer((currentState, action) => {
    return {
      ...currentState,
      [action.uuid]: !currentState[action.uuid],
    };
  }, {});

  const authentication = useAuthentication({
    unauthenticatedRedirect: 'SignIn',
    egressRedirect: 'Home',
    group: ['Admins', 'Developers'],
  });


  // Render vars:
  const productItems = products.map((p, key) => {
    return <MenuItem value={key} key={key}>{p.name}</MenuItem>;
  });

  const selectProduct = (value) => {
    setProductId(products[value] ? products[value].id : null);
  };

  // Fetch data from DB when selected product changes.
  useEffect(() => {
    if (productId === null) return;

    showSnackbar('Fetching features...', { variant: 'info' });

    (async () => {
      const fetchedFeatures = await feature.list({ productId });

      if (fetchedFeatures.success) {
        setFeatures({ type: 'set', items: fetchedFeatures.items });
        closeSnackbar();
        showSnackbar(`Loaded ${fetchedFeatures.count} features.`, { key: 'loaded', variant: 'success' });
      } else {
        showSnackbar('Error loading features.', { variant: 'error' });
        setProductId(null);
      }
    })();
  }, [productId]);


  const onCreateFeature = async () => {
    try {
      const result = await feature.create({
        body: {
          productId,
          user: authentication.user.attributes.email,
        },
      });
      setFeatures({ type: 'add', item: result.item });
    } catch (e) {
      dev.error(e.response);
    }
  };

  const canSave = (feat) => !!(feat.keyword && feat.description && feat.ratio > 0 && feat.ratio <= 100);

  const onSaveFeature = (feat) => async () => {
    if (!canSave(feat)) return;
    try {
      await feature.update({
        payload: {
          productId,
          uuid: feat.uuid,
        },
        body: {
          /// We could spread feat, but let's map values instead for more granular control:
          keyword: feat.keyword,
          description: feat.description,
          // password: feat.password, // TODO: Implement password protection
          enabled: feat.enabled,
          ratio: feat.ratio,
          // props: feat.props, // TODO: Glitchy interface for props, removed for now.
          user: authentication.user.attributes.email,
        },
      });
      showSnackbar('Feature saved.');
    } catch (e) {
      dev.error(e.response);
      closeSnackbar();
      showSnackbar('Could not save feature.', { preventDuplicate: true, variant: 'error' });
    }
  };

  const onDeleteFeature = (feat) => async () => {
    if (feat.enabled === true) {
      return;
    }
    try {
      await feature.delete({ productId, uuid: feat.uuid });
      setFeatures({ type: 'delete', uuid: feat.uuid });
    } catch (e) {
      dev.error(e.response);
    }
  };

  const onUpdateFeature = ({ feat, key }) => (e) => {
    setFeatures({ type: 'update', item: feat, key, value: e.currentTarget.value });
  };

  const onUpdateFeatureSlider = ({ feat, key }) => (e, value) => {
    setFeatures({ type: 'update', item: feat, key, value });
  };

  const onUpdateFeatureBool = ({ feat, key }) => (e) => {
    setFeatures({ type: 'update', item: feat, key, value: e.currentTarget.checked });
  };

  dev.log('render');

  return authentication.restrictAccess(
    <div className={classes.root}>
      <T v="h3" g>Feature Flags</T>

      <Grid container spacing={2} alignItems="center" className={classes.productSelect}>
        <Grid item xs={3}>
          <SelectField
            outlined
            fullWidth
            name="productId"
            label="Product"
            handler={selectProduct}
          >
            {productItems}
          </SelectField>
        </Grid>
        <Grid item xs={9} />
      </Grid>

      { productId === null
        ? <T p>Pick a product to display its features.</T>
        : (
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <div>
                {features.map((feat) => (
                  <Accordion
                    square
                    className={classes.accordion}
                    expanded={expanded[feat.uuid]}
                    onChange={() => setExpanded(feat.uuid)}
                    key={feat.uuid}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-label="Expand"
                      aria-controls="accordion-content"
                      id="accordion-header"
                    >
                      <Grid spacing={1} container alignItems="center">
                        <Grid item xs={1}>
                          <T>Feature</T>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            outlined
                            value={feat.keyword}
                            className={classes.textField}
                            onChange={onUpdateFeature({ feat, key: 'keyword' })}
                            onClick={isolate()}
                            // error={!!error}
                            // helperText={error}
                          />
                        </Grid>
                        <Grid item xs={4} className={classes.info}>
                          {
                            feat.keyword ? (
                              <TextField
                                outlined
                                fullWidth
                                value={`<Feature keyword="${_.upperFirst(_.camelCase(feat.keyword))}" ${feat.ratio !== 100 ? 'fallback={}' : ''} />`}
                                className={classes.codeSample}
                                onClick={isolate(e => e.target.select())}
                              />
                            )
                              : <T>Enter a keyword to see code sample.</T>

                          }
                        </Grid>
                        <Grid item xs={2} className={classes.info}>
                          {
                            feat.enabled
                              ? <Tooltip title="Feature Flag Enabled"><CheckBoxIcon /></Tooltip>
                              : <Tooltip title="Feature Flag Disabled"><CheckBoxOutlineBlankIcon color="disabled" /></Tooltip>
                          }
                          {
                            feat.password?.length
                              ? <Tooltip title="Feature Flag is password protected"><LockIcon /></Tooltip>
                              : <Tooltip title="Feature Flag is not password protected"><LockOpenIcon color="disabled" /></Tooltip>
                          }
                          {
                            !feat.ratio || feat.ratio === 100
                              ? <Tooltip title="No A/B testing ratio set"><FlipIcon color="disabled" /></Tooltip>
                              : <Tooltip title={`A ${feat.ratio}% / B ${100 - feat.ratio}%`}><FlipIcon color="primary" /></Tooltip>
                          }
                        </Grid>
                        <Grid item xs={2} className={classes.accordionControls}>
                          <DeleteForever
                            onClick={isolate(onDeleteFeature(feat))}
                            className={classes.icon}
                            color={feat.enabled === false ? 'primary' : 'disabled'}
                            // TODO: Add tooltip here that says "needs to be disabled to delete"
                          />
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                          <T>Description</T>
                          <TextField
                            outlined
                            fullWidth
                            multiline
                            value={feat.description}
                            placeholder="Description"
                            className={classes.textField}
                            onChange={onUpdateFeature({ feat, key: 'description' })}
                            onClick={isolate()}
                          />
                        </Grid>
                        <Grid item xs={2} />
                        <Grid container item xs={6} spacing={1} justifyContent="center" alignItems="center">
                          <Grid item xs={2}>Enabled</Grid>
                          <Grid item xs={10}>
                            <Switch
                              checked={feat.enabled}
                              onChange={onUpdateFeatureBool({ feat, key: 'enabled' })}
                            />

                          </Grid>
                          <Grid item xs={2}>Ratio</Grid>
                          <Grid item xs={2}>
                            <TextField
                              outlined
                              value={feat.ratio}
                              className={classes.ratioField}
                              onChange={onUpdateFeature({ feat, key: 'ratio' })}
                              onClick={isolate()}
                            />
                          </Grid>
                          <Grid item xs={5}>
                            <Slider
                              min={1}
                              max={100}
                              defaultValue={100}
                              value={parseInt(feat.ratio, 10)}
                              marks={marks}
                              // valueLabelDisplay="on"
                              onChange={onUpdateFeatureSlider({ feat, key: 'ratio' })}
                              className={classes.ratioSlider}
                            />

                          </Grid>
                          <Grid item xs={3} />
                          <Grid item xs={12}>
                            <T>
                              {
                                feat.ratio !== 100
                                  ? `Testing ratio: A ${feat.ratio}% / B ${100 - feat.ratio}%`
                                  : 'Component will always be displayed.'
                              }
                            </T>
                          </Grid>
                        </Grid>
                        {/* <Grid item xs={12}>
                          <T>Props</T>
                        </Grid> */}
                        <Grid item xs={12} className={classes.controls}>
                          {/* <Button variant="contained" onClick={() => null}>Cancel</Button> */}
                          <Button variant="contained" onClick={onSaveFeature(feat)} disabled={!canSave(feat)}>Save</Button>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
              <div className={classes.controls}>
                <Button variant="contained" onClick={onCreateFeature}>Add Feature</Button>
              </div>
            </Grid>
          </Grid>
        )}
    </div>,
  );
}

export default Features;
