import Recoil from 'recoil';

// Used to store the current globally active productId.
export const productIdState = Recoil.atom({
  key: 'productIdState',
  default: null,
});

// Reusable global that can help hide/display dialogs.
export const dialogVisibilityState = Recoil.atom({
  key: 'dialogVisibilityState',
  default: false,
});

// Reusable global that can help define dialog content.
export const dialogContentState = Recoil.atom({
  key: 'dialogContentState',
  default: {
    empty: true,
    title: '',
    body: null,
    buttons: null,
  },
});
