/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useState, useMemo } from 'react';
import Recoil from 'recoil';
import {
  languageState,
  accessKeyState,
  showUpButtonState,
  akStartKey,
} from 'store/atoms';
import {
  Button,
  SelectField,
} from 'components/UI';
import { Loading } from 'components/Layout';
import { MenuItem } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
// services
import { accessKey as accessKeyApi } from 'API/services';
// hooks
import useAuthentication from 'hooks/useAuthentication';
import useCustomSnackbar from 'hooks/useCustomSnackbar';
import useInfiniteLoading from 'hooks/useInfiniteLoading';
// utils
import DevConsole from 'utils/DevConsole';
import { decodeJSON64 } from 'utils/encoding';
import convertAPIDataToRows from 'utils/convertAPIData';
// third parties
import QRCode from 'react-qr-code';
import { saveSvgAsPng } from 'save-svg-as-png';
// AK form component
import AKForm from './AKForm';
// styles
import useStyles from './styles';

const dev = new DevConsole('AccessKeys');
const PROD_ID = 'GSI';

/**
 * AccessKeys Page
 *
 * This page is where we add/modify the accessKeys for GSI schools.
 *
 * @returns {React.Component}
 */
function AccessKeys() {
  const classes = useStyles();

  // refs
  const imageRef = React.useRef(null);

  // local state
  const [qrValue, setQrValue] = useState('');


  // recoil
  const languages = Recoil.useRecoilValue(languageState);
  const [data, setData] = Recoil.useRecoilState(accessKeyState);
  const [showUp, setShowup] = Recoil.useRecoilState(showUpButtonState);
  const [lastEval, setLastEval] = Recoil.useRecoilState(akStartKey);

  // hooks
  const authentication = useAuthentication({
    unauthenticatedRedirect: 'SignIn',
    egressRedirect: 'Home',
    group: 'Admins',
  });
  const [enqueueSnackbar] = useCustomSnackbar();

  const { setLastEl } = useInfiniteLoading({
    service: accessKeyApi,
    serviceName: 'accessKey',
    method: 'list',
    payload: {
      productId: 'GSI',
      limit: 100,
      start: decodeJSON64(lastEval),
    },
    state: {
      data,
      setData,
      setLastEval,
    },
  });

  const languageChoices = useMemo(() => [
    { code: 'N/A', keyword: 'Not Applicable' },
    ...languages.map((l) => ({ code: l.code, keyword: l.keyword })),
  ], [languages]);

  const languageItems = useMemo(() => languageChoices.map((l) => <MenuItem value={l.code} key={l.code}>{l.keyword}</MenuItem>), [languageChoices]);

  const languageOverrideEditCell = (props) => {
    const { api, id, field, value } = props;
    dev.log({ value });
    const onEditCellChange = (event) => api.setEditCellValue({ id, field, value: event.target.value }, event);

    return (
      <SelectField
        label="Language override"
        value={value}
        onChange={onEditCellChange}
        name="languageOverride"
        fullWidth
      >
        {languageItems}
      </SelectField>
    );
  };

  const handleScrolltop = () => {
    setShowup(false);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  /**
   * This is needed as a result of the way our API works.
   * We need the values in the body of the update request to be complete.
   *
   * @param {object} params
   * @param {object} row
   * @returns {object}
   */
  const destructureHelper = (params, row) => {
    if (!row) {
      const rowToEdit = data.find(item => item.id === params.id);
      const { accessKey, ...rest } = rowToEdit;
      return {
        ...rest,
      };
    }
    // TODO: handle enter key overriding values in other columns.
    if (params.field === 'languageOverride') {
      const { languageOverride, ...rest } = row;
      return {
        ...rest,
      };
    }
    if (params.field === 'active') {
      const { active, ...rest } = row;
      return {
        ...rest,
      };
    }
  };

  /**
   * The values we want to update here have to be destructured from the rest of the body
   * of the request.
   */
  const handleCellEdit = React.useCallback(
    async (params) => {
      dev.log('params', params);
      try {
        const response = await accessKeyApi.update({
          payload: {
            productId: PROD_ID,
            uuid: params.id,
          },
          body: {
            [params.field]: params.value,
            ...destructureHelper(params, params.row),
          },
        });

        dev.log({ response });

        setData((prev) => {
          return prev.map(row => (row.id === params.id ? { ...row, ...response.item } : row));
        });

        enqueueSnackbar('Row successfully updated!', {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (error) {
        dev.error({ errMsg: error });
        enqueueSnackbar('Error updating', {
          variant: 'error',
        });
      }
    }, [data],
  );

  const columns = [
    {
      field: 'accessKey',
      headerName: 'Access Key',
      type: 'string',
      minWidth: 256,
      flex: 3,
      editable: true,
    },
    {
      field: 'languageOverride',
      headerName: 'LanguageOverride',
      type: 'singleSelect',
      valueOptions: languageChoices.map(l => l.keyword),
      valueFormatter: ({ value }) => {
        return languageChoices.find(l => l.code === value).keyword || 'Not Applicable';
      },
      renderEditCell: languageOverrideEditCell,
      valueParser: (value) => {
        if (!value) return;

        return languageChoices.find(l => l.code === value).code;
      },
      minWidth: 256,
      flex: 2,
      editable: true,
    },
    {
      field: 'active',
      headerName: 'Active',
      type: 'boolean',
      minWidth: 128,
      flex: 1,
      editable: true,
      filterable: false,
    },
    {
      field: 'QR CODE',
      headerName: 'QR Code',
      type: 'boolean',
      minWidth: 128,
      flex: 1,
      renderCell: (params) => {
        const onClick = () => {
          const svg = imageRef.current.children[0];
          setQrValue(`https://gsi.learnbyloci.com/${params.row.accessKey}`);
          saveSvgAsPng(svg, `${params.row.accessKey}.png`);
        };
        return <Button onClick={onClick}>Get QR Code</Button>;
      },
      filterable: false,
    },
  ];
  React.useEffect(() => {
    (async () => {
      try {
        const result = await accessKeyApi.list({
          productId: 'GSI',
          limit: 100,
        });
        if (result.success) {
          convertAPIDataToRows({
            service: 'accessKey',
            stateUpdater: setData,
            rowsToClean: result.items,
          });
          setLastEval(result.start);
        }
        dev.log({ result });
      } catch (error) {
        dev.log({ errCode: error });
      }
    })();
  }, []);

  return authentication.restrictAccess(
    <div className={classes.root}>
      <AKForm languageItems={languageItems} />
      <DataGrid
        rows={data}
        columns={columns}
        autoHeight
        autoPageSize
        hideFooter
        onCellEditCommit={handleCellEdit}
        className={classes.dataContainer}
      />
      {showUp ? (
        <Button
          style={{
            position: 'fixed',
            bottom: '5%',
            right: '5%',
            backgroundColor: '#004D26',
            color: '#FFFFFF',
          }}
          onClick={handleScrolltop}
        >
          Back To Top
        </Button>
      ) : null}
      {lastEval ? (
        <section className={classes.infLoading}>
          <Loading ref={setLastEl} box={false} />
        </section>
      ) : null}
      <section className="qr-zone" style={{ visibility: 'hidden' }} ref={imageRef}>
        <QRCode value={qrValue} level="H" fgColor="#004D26" />
      </section>
    </div>,
  );
}

export default AccessKeys;
