import Recoil from 'recoil';

// Selected specific organization to Manage
const organizationState = Recoil.atom({
  key: 'organizationState',
  default: {},
});

// List of organizations
const organizationListState = Recoil.atom({
  key: 'organizationListState',
  default: {},
});

// If Manage button is clicked
const manageOrganizationState = Recoil.atom({
  key: 'manageOrganizationState',
  default: false,
});

export {
  organizationState,
  organizationListState,
  manageOrganizationState,
};
