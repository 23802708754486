/* eslint-disable react/prop-types */
/* eslint-disable no-plusplus */
import React, { useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import API from 'API';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import Recoil from 'recoil';
import {
  locationListState,
  organizationState,
} from 'store/atoms';
import {
  Text,
  TextField,
} from 'components/UI';
import { Grid } from '@material-ui/core/';
import { location } from 'API/services';
import useDebounce from 'hooks/useDebounce';
import useCustomSnackbar from 'hooks/useCustomSnackbar';
import DevConsole from 'utils/DevConsole';

import uploadIcon from 'assets/images/icons/icon-metro-file-upload.png';

import useStyles from './styles';

const dev = new DevConsole('LocationForm').mute();
const api = new API();

const LocationForm = ({ setAddLocation }) => {
  const classes = useStyles();
  const [showSnackbar] = useCustomSnackbar();
  const history = useHistory();

  const uploadLogoRef = useRef();
  const formFieldRef = useRef([]);
  const [isCreated, setIsCreated] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [uploadedThumb, setUploadedThumb] = useState({});
  const currentOrganization = Recoil.useRecoilValue(organizationState);
  const setLocationList = Recoil.useSetRecoilState(locationListState);

  /**
   * createLocation
   * Creates a new location on the database
   */
  const [createLocation] = useDebounce(async (inputs, addrObj) => {
    try {
      // TODO: Once API is working, replace the test data to the ones on the inputs
      if (currentOrganization && !_.isEmpty(currentOrganization.organizationId)) {
        const result = await location.create({
          body: {
            organizationId: currentOrganization.organizationId,
            locationName: inputs.locationName,
            locationType: inputs.locationType,
            locationSize: inputs.locationSize,
            address: addrObj,
            gpsCoordinates: inputs.gpsCoordinates,
            thumbnail: uploadedThumb,
          },
        });
        dev.log(`create result: ${JSON.stringify(result)}`);
        if (result.success) {
          setIsCreated(true);
          showSnackbar('Location is created successfully!', { preventDuplicate: true, variant: 'success' });

          // append the newly created Organization to the Organization List
          setLocationList(prevState => [...prevState, result.item]);

          // redirect to the Organizations List page once created after 0.3ms
          setTimeout(() => {
            history.replace('/location');
          }, 300);
        }
      } else {
        showSnackbar('Please make sure that an organization was selected before trying to add a location!', { preventDuplicate: true, variant: 'error' });
      }
    } catch (e) {
      dev.error(e.response);
    }
  }, 500);

  const handleClick = () => {
    uploadLogoRef.current.click();
  };

  const handleUpload = async (e) => {
    dev.log('handleUpload e', e);
    dev.log('uploadLogoRef: ', uploadLogoRef);
    const file = uploadLogoRef.current.files[0];
    const originalFileName = file.name;
    const fileType = file.type;
    const uniqueFileName = uuidv4(); // we use this to avoid issues with same originalFileName
    const folder = 'thumbnail';
    try {
      const image = await api.storage.put(`${folder}/${uniqueFileName}`, file, {
        level: 'public',
        contentType: fileType,
      });
      dev.log('image', image);

      if (!_.isEmpty(image)) {
        setIsUploaded(true);
        setUploadedThumb({
          folder,
          originalFileName,
          fileType,
          uniqueFileName,
        });
      }
    } catch (error) {
      dev.log('Error uploading file: ', error);
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    dev.log('creating...');
    // dev.log('formFieldRef', formFieldRef);
    const inputs = [];
    const addrObj = {};
    for (let i = 0; i < formFieldRef.current.length; i++) {
      dev.log('current', formFieldRef.current[i]);
      if (formFieldRef.current[i] && formFieldRef.current[i].id) {
        if (formFieldRef.current[i].id.includes('addr_')) {
          const addrKey = formFieldRef.current[i].id.split('addr_');
          addrObj[addrKey[1]] = formFieldRef.current[i].value;
        } else {
          inputs[formFieldRef.current[i].id] = formFieldRef.current[i].value;
        }
      }
    }
    dev.log('inputs', inputs);
    dev.log('addrObj', addrObj);
    createLocation(inputs, addrObj);
  };

  return (
    <>
      {!isCreated && (
        <Grid
          key="addLoc"
          item
          sm={3}
        >
          <div className={classes.locationBorderForm}>
            <div className={classes.locationContainer}>
              <div className={classes.locationThumbContainerForm}>
                <div className={classes.locationHeaderForm}>
                  {!isUploaded && (
                    <div>
                      <input
                        ref={uploadLogoRef}
                        accept="image/*"
                        type="file"
                        onChange={handleUpload}
                        className={classes.hiddenClass}
                      />
                      <div
                        className={classes.uploadButton}
                        aria-hidden="true"
                        onClick={handleClick}
                      >
                        <img src={uploadIcon} alt="Upload" />
                        <Text>UPLOAD IMAGE</Text>
                      </div>
                    </div>
                  )}
                  {isUploaded && (
                    <div className={classes.uploadFileName}>
                      <Text>{uploadedThumb.originalFileName}</Text>
                    </div>
                  )}
                  <TextField
                    inputRef={ref => formFieldRef.current.push(ref)}
                    name="locationName"
                    placeholder="Name of Location"
                    variant="outlined"
                  />
                </div>
              </div>
              <div className={classes.locationWrapperForm}>
                <div className={classes.locationForms}>
                  <Text className={classes.bold}>Location Type</Text>
                  <TextField
                    inputRef={ref => formFieldRef.current.push(ref)}
                    name="locationType"
                    placeholder="Enter location type"
                    variant="outlined"
                  />
                </div>
                <div className={classes.locationForms}>
                  <Text className={classes.bold}>Size</Text>
                  <TextField
                    inputRef={ref => formFieldRef.current.push(ref)}
                    name="locationSize"
                    placeholder="Enter size"
                    variant="outlined"
                  />
                </div>
                <div className={classes.locationForms}>
                  <Text className={classes.bold}>Address</Text>
                  <div className={classes.locationAddressForm}>
                    <TextField
                      inputRef={ref => formFieldRef.current.push(ref)}
                      name="addr_streetAddress"
                      placeholder="Enter Street Address"
                      variant="outlined"
                      className="streetAddress"
                    />
                    <TextField
                      inputRef={ref => formFieldRef.current.push(ref)}
                      name="addr_city"
                      placeholder="Enter City"
                      variant="outlined"
                      className="city"
                    />
                    <TextField
                      inputRef={ref => formFieldRef.current.push(ref)}
                      name="addr_state"
                      placeholder="Enter State"
                      variant="outlined"
                      className="state"
                    />
                    <TextField
                      inputRef={ref => formFieldRef.current.push(ref)}
                      name="addr_zip"
                      placeholder="Enter Zip Code"
                      variant="outlined"
                      className="zip"
                    />
                    <TextField
                      inputRef={ref => formFieldRef.current.push(ref)}
                      name="addr_country"
                      placeholder="Enter Country"
                      variant="outlined"
                      className="country"
                    />
                  </div>
                </div>
                <div className={classes.locationForms}>
                  <Text className={classes.bold}>GPS Coordinates</Text>
                  <TextField
                    inputRef={ref => formFieldRef.current.push(ref)}
                    name="gpsCoordinates"
                    placeholder="Enter GPS Coordinates"
                    variant="outlined"
                  />
                </div>
              </div>
              <div className={classes.buttonContainerForm}>
                <div
                  className={classes.formButton}
                  onClick={() => setAddLocation(false)}
                  aria-hidden="true"
                >
                  <Text>CANCEL</Text>
                </div>
                <div
                  className={classes.formButton}
                  onClick={handleSave}
                  aria-hidden="true"
                >
                  <Text>SAVE</Text>
                </div>
                <div className={classes.clearFloat} />
              </div>
            </div>
          </div>
        </Grid>
      )}
    </>
  );
};

export default LocationForm;
