import Recoil from 'recoil';

const searchState = Recoil.atom({
  key: 'searchState',
  default: '',
});

// Handing common search terms client side for now
// [TODO]: Have these search terms come from a search microservice
const prevSearchState = Recoil.atom({
  key: 'prevSeachState',
  default: new Set(['GSI', 'GERM']),
});

export {
  searchState,
  prevSearchState,
};
